import { combineReducers } from 'redux';
import omit from 'lodash/omit';

/**
 * A few Notes:
 * This reducer factory assumes that you are sending it normalised data as
 * described by normalizr. Data coming into the system is considered the source
 * of truth i.e. even though the reducers dont have any perspective of the remote
 * data store, it is the truth. The local state drives changes via transations
 * that are sent upstream.
 */

import {
    REQUEST,
    REQUEST_ID,
    REQUEST_SUCCESS,
    REQUEST_ID_SUCCESS,
    REQUEST_FAILURE,
    REQUEST_ID_FAILURE,
    CLEAR,
    CLEAR_ALL,
    CLEAR_ERRORS,
    SET_COUNT,
    REQUEST_SLUG_SUCCESS,
    REQUEST_SLUG,
    REQUEST_SLUG_FAILURE
} from '../actionTypes';

export const loading = entity => (state = false, { type }) => {
    switch (type) {
        case `${REQUEST}/${entity}`:
            return true;
        case `${REQUEST_ID}/${entity}`:
        case `${REQUEST_SLUG}/${entity}`:
            return true;
        case `${REQUEST_SUCCESS}/${entity}`:
            return false;
        case `${REQUEST_SLUG_SUCCESS}/${entity}`:
        case `${REQUEST_ID_SUCCESS}/${entity}`:
            return false;
        default:
            return state;
    }
};

export const error = entity => (state = null, { type, payload }) => {
    switch (type) {
        case `${REQUEST_FAILURE}/${entity}`:
            return payload;
        case `${REQUEST_SLUG_FAILURE}/${entity}`:
        case `${REQUEST_ID_FAILURE}/${entity}`:
            return payload;
        case `${CLEAR_ERRORS}/${entity}`:
            return null;
        default:
            return state;
    }
};

export const count = entity => (state = null, { type, payload }) => {
    switch (type) {
        case `${SET_COUNT}/${entity}`:
            return payload;
        case `${CLEAR_ALL}/${entity}`:
        default:
            return state;
    }
};

export const byId = entity => (state = {}, { type, payload }) => {
    switch (type) {
        case `${REQUEST_SUCCESS}/${entity}`:
            return {
                ...state,
                ...Object.entries(payload).reduce(
                    // Object.entries returns [key, value]
                    (acc, [key, entity]) => ({
                        ...acc,
                        [key]: {
                            ...(state[key] || {}),
                            ...entity
                        }
                    }),
                    {}
                )
            };
        case `${REQUEST_SLUG_SUCCESS}/${entity}`:
        case `${REQUEST_ID_SUCCESS}/${entity}`: {
            return {
                ...state,
                [payload.id]: {
                    ...(state[payload.id] || {}),
                    ...payload
                }
            };
        }
        case `${CLEAR}/${entity}`:
            // https://lodash.com/docs/4.17.11#omit
            return omit(state, [payload]);
        case `${CLEAR_ALL}/${entity}`:
            return {};
        default:
            return state;
    }
};

export const allIds = entity => (state = [], { type, payload }) => {
    switch (type) {
        case `${REQUEST_SUCCESS}/${entity}`:
            // this insures that the entries are unique due to the nature of sets
            // return Array.from([new Set([...state, ...Object.keys(payload)])];
            // Do to the babel config on feersum-lasso I cant spread the set.
            return Array.from(new Set([...state, ...Object.keys(payload)]));
        case `${CLEAR}/${entity}`:
            return state.filter(id => id !== payload);
        case `${CLEAR_ALL}/${entity}`:
            return [];
        default:
            return state;
    }
};

const createResourceReducer = (
    entity,
    extraReducers = {},
    extraFactories = {}
) =>
    combineReducers({
        isLoading: loading(entity),
        error: error(entity),
        byId: byId(entity),
        allIds: allIds(entity),
        count: count(entity),
        ...extraReducers,
        ...Object.entries(extraFactories).reduce(
            (acc, [name, predicate]) => ({
                ...acc,
                [name]: predicate(entity)
            }),
            {}
        )
    });

export default createResourceReducer;

export const isLoading = ({ isLoading }) => isLoading;
export const getError = ({ error }) => error;
export const getAll = ({ byId }) => byId;
export const get = list => ({ [list]: slice }, id) =>
    typeof id === 'undefined' ? slice : slice[id];

export const getCount = ({ count }) => count;
