import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { styles as selectStyles } from '../../Model/SamplesView/selectStyles';

import { Input, SelectBox, Box } from '../../lavender';
import { createModel } from '../../../redux/actions/models';
import { DialogActions, ActionButton } from '../styles';
import { modelTypeNavItems } from '../../../config';

const styles = theme => ({
    textField: {
        margin: theme.spacing.unit / 2,
        padding: {
            top: theme.spacing.unit * 2,
            bottom: theme.spacing.unit * 2
        },
        width: '80%'
    },
    chip: {
        margin: theme.spacing.unit / 2
    },
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap'
    }
});

const getModelType = ({ group }) => {
    const modelType = modelTypeNavItems.find(({ key }) => key === group)
        .modelTypes;
    if (Array.isArray(modelType)) return modelType[0];
    return modelType;
};

const mapDispatchToProps = {
    createModel,
    redirect: push
};

class CreateModelForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chip: '',
            type: getModelType(props),
            formData: this.initialFormData(getModelType(props))
        };
        this.addChip = this.addChip.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.initialFormData = this.initialFormData.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.changeModelType = this.changeModelType.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    addChip() {
        const { formData, chip } = this.state;
        const { similar_words } = formData;

        if (chip && chip.trim().length > 0 && !similar_words.includes(chip)) {
            this.setState({
                formData: {
                    ...formData,
                    similar_words: [...similar_words, chip]
                },
                chip: ''
            });
        }
    }

    handleKeyPress(e) {
        e.key === 'Enter' && this.addChip();
    }

    submitHandler() {
        const { domainId } = this.props.match.params;
        const { type, formData } = this.state;

        this.props
            .createModel({
                domainId,
                modelType: type,
                payload: formData
            })
            .then(response => {
                const { name } = response.body;
                this.props.toggleDialog();
                this.props.redirect(
                    `/model/${domainId}/${name}/${type}/samples/`
                );
            });
    }

    initialFormData(type) {
        const { create, types } = this.props.schema;
        const { modelType } = this.props;
        const defaultType = modelType ? modelType : Object.keys(types)[0];
        const formSchema = Object.entries(create[type || defaultType]);
        return formSchema.reduce((a, b) => {
            b[1].default ? (a[b[0]] = b[1].default) : (a[b[0]] = '');
            return a;
        }, {});
    }

    changeModelType(event) {
        const { value } = event;
        this.setState({
            formData: this.initialFormData(value),
            type: value
        });
    }

    changeHandler({ target: { name, value } }) {
        if (name === 'chip') {
            this.setState({
                [name]: value
            });
        } else {
            this.setState({
                formData: {
                    ...this.state.formData,
                    [name]: value
                }
            });
        }
    }

    handleDelete(word) {
        // TODO: this should be handled in redux
        this.setState(state => {
            const similar_words = [...state.formData.similar_words];
            const chipToDelete = similar_words.indexOf(word);
            similar_words.splice(chipToDelete, 1);
            return { formData: { ...state.formData, similar_words } };
        });
    }

    render() {
        // TODO: Form validation
        const { schema, classes } = this.props;
        const types = Object.entries(schema.types);
        const type = this.state.type || types[0][0];
        const modelSchema = Object.entries(schema.create[type]);
        const creatableModels = Object.keys(schema.create);
        const { classifiers, extractors } = schema.groups;
        const creatableClassifiers = creatableModels.filter(type =>
            classifiers.includes(type)
        );
        const creatableExtractors = creatableModels.filter(type =>
            extractors.includes(type)
        );

        const classifierOptions = creatableClassifiers.map(type => ({
            value: type,
            label: schema.types[type]
        }));
        const extractorOptions = creatableExtractors.map(type => ({
            value: type,
            label: schema.types[type]
        }));

        return (
            <form>
                <SelectBox
                    mb={20}
                    options={[
                        {
                            label: 'Classifiers',
                            options: classifierOptions
                        },
                        {
                            label: 'Extractors',
                            options: extractorOptions
                        }
                    ]}
                    styles={selectStyles}
                    defaultValue={classifierOptions
                        .concat(extractorOptions)
                        .find(({ value }) => value === type)}
                    placeholder="Select model type..."
                    id="create-model-select"
                    classNamePrefix="select"
                    onChange={this.changeModelType}
                />
                {modelSchema.map(([key, value]) =>
                    key === 'similar_words' ? (
                        <div key={key}>
                            <Paper className={classes.root} elevation={0}>
                                {this.state.formData[key].map(chip => (
                                    <Chip
                                        key={chip}
                                        label={chip}
                                        onDelete={() => this.handleDelete(chip)}
                                        className={classes.chip}
                                    />
                                ))}
                            </Paper>
                            <TextField
                                name="chip"
                                className={classes.textField}
                                value={this.state.chip}
                                onChange={this.changeHandler}
                                margin="normal"
                                onKeyPress={this.handleKeyPress}
                                placeholder={
                                    'Type a similar word here, and press enter...'
                                }
                                autoComplete="off"
                            />
                        </div>
                    ) : (
                        <Box mb={2} key={key}>
                            <Input
                                type={value.type}
                                name={key}
                                placeholder={value.label}
                                onChange={this.changeHandler}
                            />
                        </Box>
                    )
                )}

                <DialogActions>
                    <ActionButton
                        label="Create"
                        type="button"
                        onClick={this.submitHandler}
                        data-cy="submit-create-model"
                    />

                    <ActionButton
                        plain
                        label="Cancel"
                        type="button"
                        onClick={this.props.toggleDialog}
                    />
                </DialogActions>
            </form>
        );
    }
}

CreateModelForm.propTypes = {
    createModel: PropTypes.func.isRequired,
    toggleDialog: PropTypes.func.isRequired,
    schema: PropTypes.shape({
        create: PropTypes.object.isRequired,
        types: PropTypes.object.isRequired
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired
        })
    }).isRequired,
    redirect: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    modelType: PropTypes.string,
    group: PropTypes.string
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(withStyles(styles)(CreateModelForm))
);
