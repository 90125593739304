import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as selectors from '../../../../redux/reducers';
import * as actions from '../../../../redux/actions/samples';
import SampleField from '../../../SampleField';
import { validateSynonym } from '../../../../utils';

const mapStateToProps = (state, prevProps) => ({
    sample: selectors.getSampleById(state, prevProps.id)
});

const mapDispatchToProps = dispatch => ({
    editSample: payload => dispatch(actions.editSample(payload)),
    addSamples: payload => dispatch(actions.addSamples(payload)),
    deleteSynonym: payload => dispatch(actions.editSample(payload))
});

class Synonym extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id
        };
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleEdit(value) {
        const {
            item,
            editSample,
            sample,
            sampleType,
            match: { params }
        } = this.props;
        const synonyms = sample.entity_list[0].syn_set;
        const index = synonyms.findIndex(syn => syn === item);
        let entityList = sample.entity_list[0];
        if (!validateSynonym(value, sample)) return;
        let editedSample = [
            {
                ...sample,
                entity_list: [
                    {
                        ...entityList,
                        syn_set: Array.from(
                            new Set([
                                ...entityList.syn_set.slice(0, index),
                                value,
                                ...entityList.syn_set.slice(index + 1)
                            ])
                        )
                    }
                ],
                type: sampleType
            }
        ];

        return editSample({
            ...params,
            sample: editedSample
        });
    }

    handleDelete() {
        const {
            item,
            deleteSynonym,
            sample,
            sampleType,
            match: { params }
        } = this.props;
        const { id } = this.state;
        const synonyms = sample.entity_list[0].syn_set;
        const index = synonyms.findIndex(syn => syn === item);
        let entityList = sample.entity_list[0];

        let editedSample = [
            {
                ...sample,
                entity_list: [
                    {
                        ...entityList,
                        syn_set: Array.from(
                            new Set([
                                ...entityList.syn_set.slice(0, index),
                                ...entityList.syn_set.slice(index + 1)
                            ])
                        )
                    }
                ],
                uuid: id,
                type: sampleType
            }
        ];

        return deleteSynonym({
            ...params,
            sample: editedSample
        });
    }

    render() {
        const {
            item,
            sampleType,
            sample: { entity_list }
        } = this.props;
        return (
            <SampleField
                text={item}
                onEdit={this.handleEdit}
                onDelete={this.handleDelete}
                styleProps={{ pl: 25 }}
                name={`text-${entity_list[0].entity}-${item}`}
                sampleType={sampleType}
                divProps={{ py: 25, pl: 30 }}
            />
        );
    }
}

Synonym.propTypes = {
    id: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
    sampleType: PropTypes.string,
    sample: PropTypes.object.isRequired,
    editSample: PropTypes.func.isRequired,
    deleteSynonym: PropTypes.func.isRequired,
    addSamples: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    })
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Synonym)
);
