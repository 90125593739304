/*  Resource actions:
    note these are used in factories so the entity name is appended at run time
 */
export const REQUEST_CREATE = 'REQUEST_CREATE';
export const REQUEST_UPDATE = 'REQUEST_UPDATE';
export const REQUEST = 'REQUEST_LIST';
export const REQUEST_ID = 'REQUEST_LIST_ID';
export const REQUEST_SLUG = 'REQUEST_LIST_SLUG';
export const REQUEST_SUCCESS = 'REQUEST_LIST_SUCCESS';
export const REQUEST_ID_SUCCESS = 'REQUEST_LIST_ID_SUCCESS';
export const REQUEST_SLUG_SUCCESS = 'REQUEST_LIST_SLUG_SUCCESS';
export const REQUEST_FAILURE = 'REQUEST_LIST_FAILURE';
export const REQUEST_ID_FAILURE = 'REQUEST_LIST_ID_FAILURE';
export const REQUEST_SLUG_FAILURE = 'REQUEST_LIST_SLUG_FAILURE';
export const CLEAR = 'CLEAR_LIST';
export const CLEAR_ALL = 'CLEAR_ALL_LIST';
export const CLEAR_ERRORS = 'CLEAR_ERRORS_LIST';
export const SET_COUNT = 'SET_COUNT';
