import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import styled from 'styled-components';
import { Flex, Card } from '../index';
import { lighten } from 'polished';

const MenuItem = styled(Flex)(props => ({
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: lighten(0.1, props.theme.colors.brand[1])
    }
}));

const Menu = ({ menu = [], ...props }) => {
    return (
        <Card
            boxShadow="0 6px 6px 0 rgba(176,180,209,.5)"
            css={{
                position: 'relative',
                left: '-30px'
            }}
            m={1}
            {...props}
        >
            {menu.map(({ action, key }, i) => (
                <MenuItem
                    onClick={e => e.stopPropagation()}
                    key={(key, i)}
                    alignItems="center"
                >
                    {action}
                </MenuItem>
            ))}
        </Card>
    );
};

Menu.propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
            icon: PropTypes.any,
            action: PropTypes.any
        })
    )
};

class Popover extends Component {
    state = {
        anchorEl: null,
        open: false
    };

    handleClick = event => {
        event.preventDefault();
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: state.anchorEl ? null : currentTarget
        }));
    };

    handleClickAway = () => {
        this.setState({
            anchorEl: null
        });
    };

    render() {
        const { anchorEl } = this.state;
        const { menu, ...props } = this.props;
        const open = Boolean(anchorEl);

        return (
            <div>
                <div onClick={this.handleClick}>{this.props.children}</div>
                <ClickAwayListener onClickAway={this.handleClickAway}>
                    <Popper open={open} anchorEl={anchorEl}>
                        <Menu p={1} menu={menu} {...props} />
                    </Popper>
                </ClickAwayListener>
            </div>
        );
    }
}

Popover.propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
            icon: PropTypes.any,
            action: PropTypes.any
        })
    ),
    children: PropTypes.node
};

export default Popover;
