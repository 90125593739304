import React from 'react';
import { Box, Text } from '../lavender';
import { version } from '../../../package.json';

export const Footer = () => (
    <Box my={3}>
        <Text ml={1} p={1} color="white" fontSize={2} weight="medium">
            &copy;&nbsp;{new Date().getFullYear()} Feersum Lasso 💖
        </Text>
        <Text textAlign="center" mr={3} color="#717e83" fontSize={2}>
            v{version}
        </Text>
    </Box>
);
