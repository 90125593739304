import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Text from '../Text';

const Badge = ({ text }) => (
    <Box
        bg="support.2"
        color="black"
        px={2}
        css={{
            position: 'absolute',
            left: '75px',
            top: '-15px',
            borderRadius: '30px'
        }}
    >
        <Text fontSize={4} weight="bold">
            {text}
        </Text>
    </Box>
);

Badge.propTypes = {
    text: PropTypes.string.isRequired
};

export default Badge;
