import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route, Redirect, Switch } from 'react-router';

import Analytics from './Analytics/';
import SamplesView from './SamplesView';
import ModelHeader from './ModelHeader';
import { LoadingIndicator } from '../Loading';
import { Box, Flex } from '../lavender';
import { getModel } from '../../redux/actions/models';
import Chatbox from './Chatbox';
import Curation from './Curation';
import * as selectors from '../../redux/reducers';
import { setActive } from '../../redux/utils/actions/lasso';
import { clear } from '../../redux/utils/actions/resource';
import { getSamples } from '../../redux/actions/samples';
import { getAllLanguageModels } from '../../redux/actions/models';
import { isClassifier } from '../../utils';
import Languages from './Languages';
import General from './General';
import Settings from './Settings';
import SimilarWords from './SimilarWords';
import Regex from './Regex';
import Duckling from './Duckling';
import Synonyms from './Synonyms';
import Training from './Training';

const ModelContainer = props => (
    <Flex justifyContent="space-between" css={{ height: '100vh' }} {...props} />
);

const getComponent = (tab, model, schema) => {
    const defaultComponents = {
        synonym_entity_extractor: <Synonyms />,
        regex_entity_extractor: <Regex model={model} />,
        duckling_entity_extractor: <Duckling model={model} />
    };

    const defaultComponent = defaultComponents[model.model_type] || (
        <SamplesView model={model} />
    );

    switch (tab) {
        case 'samples':
            return defaultComponent;
        case 'curation':
            return <Curation items={model.testing_curated_list || []} />;
        case 'analytics':
            return <Analytics model={model} />;
        case 'languages':
            return <Languages model={model} />;
        case 'general':
            return <General model={model} schema={schema} />;
        case 'settings':
            return <Settings model={model} schema={schema} />;
        case 'training':
            return <Training model={model} schema={schema} />;
        default:
            return defaultComponent;
    }
};

const mapStateToProps = state => ({
    loading: selectors.getIsLoadingActiveModel(state),
    model: selectors.getActiveModel(state),
    schema: selectors.getSchema(state)
});

const mapDispatchToProps = dispatch => ({
    getModel: payload => dispatch(getModel(payload)),
    clearModel: () => dispatch(clear('models')('active')),
    getSamples: payload => dispatch(getSamples(payload)),
    setActiveDomain: id => dispatch(setActive('domains')(id)),
    getAllLanguageModels: id => dispatch(getAllLanguageModels(id))
});

class Model extends Component {
    componentDidMount() {
        const { params } = this.props.match;
        this.props.getModel({ ...params });
        this.props.setActiveDomain(params.domainId);
        this.props.getAllLanguageModels(params.domainId);
        isClassifier(params.modelType) && this.props.getSamples({ ...params });
    }

    componentWillUnmount() {
        this.props.clearModel();
    }

    render() {
        const { model, schema, loading } = this.props;
        const isLoading = loading || !model || !schema;

        return (
            <Fragment>
                {isLoading ? (
                    <LoadingIndicator message="Loading model..." />
                ) : (
                    <ModelContainer>
                        <Box m={15} width={1} css={{ overflow: 'auto' }}>
                            <ModelHeader schema={schema} model={model} />
                            <Box mx={4} my={2}>
                                <Switch>
                                    <Route
                                        exact
                                        path={
                                            '/model/:domainId/:modelName/:modelType/'
                                        }
                                        render={({
                                            location,
                                            match: {
                                                params: {
                                                    domainId,
                                                    modelName,
                                                    modelType
                                                }
                                            }
                                        }) => (
                                            <Redirect
                                                to={{
                                                    pathname: `/model/${domainId}/${modelName}/${modelType}/samples/`,
                                                    state: { from: location }
                                                }}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`/model/:domainId/:modelName/:modelType/:tab/`}
                                        render={({ match: { params } }) =>
                                            getComponent(
                                                params.tab,
                                                model,
                                                schema
                                            )
                                        }
                                    />
                                </Switch>
                            </Box>
                        </Box>
                        <Chatbox model={model} schema={schema} />
                    </ModelContainer>
                )}
            </Fragment>
        );
    }
}

Model.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string,
            modelType: PropTypes.string,
            modelName: PropTypes.string
        })
    }),
    setActiveDomain: PropTypes.func,
    getModel: PropTypes.func.isRequired,
    clearModel: PropTypes.func.isRequired,
    getSamples: PropTypes.func.isRequired,
    model: PropTypes.shape({
        threshold: PropTypes.number,
        long_name: PropTypes.string,
        name: PropTypes.string,
        training_stamp: PropTypes.string,
        model_group: PropTypes.string
    }),
    loading: PropTypes.bool,
    schema: PropTypes.object,
    getAllLanguageModels: PropTypes.func
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Model)
);
