import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Text, Box, Badge } from '../../lavender';
import { lighten } from 'polished';
import { hover } from '../../Models/utils';

const NavItem = ({
    item,
    params: { domainId, modelName, modelType, tab },
    badge,
    ...props
}) => {
    return (
        <NavLink to={`/model/${domainId}/${modelName}/${modelType}${item.to}/`}>
            <Box css={{ position: 'relative' }}>
                {badge && <Badge text={badge} />}
                <Text
                    key={item.name}
                    color={tab === item.key ? 'white' : undefined}
                    p={2}
                    css={{
                        borderRadius: '10px',
                        cursor: 'pointer',
                        color: props.theme.colors.text[0],
                        ...hover(tab, item.key, props.theme),
                        '&:focus': {
                            color: 'white'
                        },
                        '&:hover': {
                            backgroundColor: lighten(
                                0.05,
                                props.theme.colors.brand[0]
                            ),
                            color: 'white'
                        },
                        '&:active': {
                            backgroundColor: lighten(
                                0.05,
                                props.theme.colors.brand[0]
                            ),
                            color: 'white'
                        }
                    }}
                    fontSize={3}
                    weight="bold"
                    data-cy={`${item.name}-tab`}
                >
                    {item.name}
                </Text>
            </Box>
        </NavLink>
    );
};

NavItem.propTypes = {
    item: PropTypes.object.isRequired,
    theme: PropTypes.object,
    params: PropTypes.object,
    badge: PropTypes.string
};

export default withTheme(NavItem);
