import {
    SCHEMA_GET,
    SCHEMA_GET_SUCCESS,
    CLEAR_SCHEMA
} from '../constants/actionTypes';
import Api from '../../middleware/api';

export const getSchemaSuccess = payload => {
    const serializedSchema = JSON.stringify(payload);
    localStorage.setItem('schema', serializedSchema);
    return {
        type: SCHEMA_GET_SUCCESS,
        payload
    };
};

export const clearSchema = () => {
    localStorage.removeItem('schema');
    return { type: CLEAR_SCHEMA };
};

export const loadSchema = () => dispatch => {
    dispatch(getSchema());
};

export const getSchema = () => dispatch => {
    dispatch({ type: SCHEMA_GET });
    Api(dispatch)
        .getSchema()
        .then(schema => {
            dispatch(getSchemaSuccess(schema.body));
        });
};
