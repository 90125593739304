import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../Text';

export const Title = props => <Text {...props}>{props.children}</Text>;

Title.displayName = 'Title';

Title.propTypes = {
    truncate: PropTypes.bool,
    children: PropTypes.any
};

Title.defaultProps = {
    fontSize: 6
};

export default Title;
