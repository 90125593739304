import { SHOW_TOAST, HIDE_TOAST } from '../constants/actionTypes';

export const toast = message => ({
    type: SHOW_TOAST,
    message
});

export const hideToast = () => ({
    type: HIDE_TOAST
});
