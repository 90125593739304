import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Text, Box, Input, Flex, Button } from '../../lavender';
import { train, test, updateModel } from '../../../redux/actions/models';
import TrainTimeAgo from './TimeAgo';
import * as selectors from '../../../redux/reducers';
import { classify } from '../../../redux/actions/classify';
import { clear } from '../../../redux/utils/actions/resource';

const mapStateToProps = state => ({
    result: selectors.getActiveModelClassifyResult(state),
    training: selectors.getActiveModelTrainingStatus(state),
    testing: selectors.getActiveModelTestingStatus(state),
    languages: selectors.getActiveModelLanguages(state),
    words: selectors.getAllSimilarWordsIds(state),
    algorithm: selectors.getActiveModelAlgorithm(state)
});

const mapDispatchToProps = dispatch => ({
    classify: payload => dispatch(classify(payload)),
    clear: () => dispatch(clear('models')('result')),
    train: payload => dispatch(train(payload)),
    test: payload => dispatch(test(payload)),
    updateModel: payload => dispatch(updateModel(payload))
});

const sentimentColor = value =>
    value > 0 ? 'green' : value < 0 ? 'support.0' : 'support.2';

const score = item => {
    const { granularity, probability, similarity } = item;
    if (granularity >= 0) return granularity;
    if (probability >= 0) return Math.floor(probability * 100) / 100;
    if (similarity >= 0) return similarity;
    return 0;
};

const Result = ({ children, ...rest }) => (
    <Flex bg="#f4f7fb" my={2} p={2} css={{ borderRadius: '6px' }} {...rest}>
        {children}
    </Flex>
);

Result.propTypes = {
    children: PropTypes.node
};

const DucklingResult = ({ result }) =>
    result &&
    result.map((item, i) => (
        <Result flexDirection="column" key={i}>
            {Object.keys(item).map(
                i =>
                    i !== 'value' && (
                        <Flex justifyContent="space-between">
                            <Text fontWeight="bold" color="text.1">
                                {i}:
                            </Text>
                            {item[i]}
                        </Flex>
                    )
            )}
            <Text fontWeight="bold" color="support.1">
                {new Date(item.value).toString()}
            </Text>
        </Result>
    ));

DucklingResult.propTypes = {
    result: PropTypes.array
};

class Chatbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ''
        };
        this.handleClassify = this.handleClassify.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTrain = this.handleTrain.bind(this);
        this.handleTest = this.handleTest.bind(this);
    }

    componentDidMount() {
        this.props.clear();
    }

    handleClassify() {
        const { text } = this.state;
        text &&
            this.props.classify({
                ...this.props.match.params,
                payload: { text }
            });
    }

    handleChange({ target: { name, value } }) {
        value === '' && this.props.clear();

        this.setState(
            {
                [name]: value
            },
            this.handleClassify
        );
    }

    handleTrain() {
        const {
            languages,
            model,
            match: { params },
            words,
            updateModel,
            train,
            algorithm
        } = this.props;
        const { threshold, model_type } = model;

        model_type === 'sim_word_entity_extractor'
            ? updateModel({
                  ...params,
                  payload: { similar_words: words }
              })
            : model_type === 'text_classifier'
            ? train({
                  ...params,
                  threshold,
                  clsfr_algorithm: algorithm,
                  language_model_list:
                      languages.map(lang => ({
                          lang_code: lang.label,
                          lang_model: lang.languageModel
                      })) || []
              })
            : model_type === 'faq_matcher' || model_type === 'intent_classifier'
            ? train({
                  ...params,
                  threshold,
                  language_model_list:
                      languages.map(lang => ({
                          lang_code: lang.label,
                          lang_model: lang.languageModel
                      })) || []
              })
            : train({
                  ...params,
                  threshold
              });
    }

    handleTest() {
        const {
            languages,
            model,
            match: { params },
            words,
            updateModel,
            test,
            algorithm
        } = this.props;
        const { threshold, model_type } = model;

        test({
            ...params
        });
    }

    render() {
        const result = this.props.result;
        const { text } = this.state;
        const { model, languages, schema } = this.props;
        const { training_stamp, model_group, model_type } = model || {};
        const { modelType } = this.props.match.params;
        const trainable =
            schema && schema.train && schema.train.hasOwnProperty(modelType);
        const isMultilang =
            trainable &&
            schema.train[modelType].hasOwnProperty('word_manifold_list');

        const defaultComponents = {
            duckling_entity_extractor: <DucklingResult result={result} />
        };

        const resultComponent = defaultComponents[model_type];

        return (
            <Flex
                flexDirection="column"
                width="400px"
                p={3}
                pt={25}
                css={{
                    borderLeft: '2px solid #f4f7fb'
                }}
                bg="white"
            >
                <Text my={1} fontSize={5} weight="bold">
                    Live Assistant
                </Text>
                <Text my={2} color="text.1">
                    This interface will send the text to the model and display
                    the results it found below.
                </Text>
                <Input
                    type="text"
                    name="text"
                    onChange={this.handleChange}
                    placeholder="Type a query to test your model..."
                    autoComplete="off"
                    autoFocus="on"
                    my={2}
                    fontSize={1}
                />

                {resultComponent ? (
                    resultComponent
                ) : (
                    <Fragment>
                        {result && result.constructor === Array ? (
                            result.map((item, i) => (
                                <Result key={i}>
                                    <Text fontSize={2} weight="medium">
                                        {item.label ||
                                            item.entity ||
                                            item.value ||
                                            item._all_groups}
                                    </Text>
                                    {model_type !== 'regex_entity_extractor' &&
                                        model_type !==
                                            'duckling_entity_extractor' &&
                                        model_type !==
                                            'synonym_entity_extractor' && (
                                            <Text ml="auto" mr={2}>
                                                {score(item)}
                                            </Text>
                                        )}
                                </Result>
                            ))
                        ) : result === 'no_result' && (text || text === '') ? (
                            <Text>No result found</Text>
                        ) : (
                            result &&
                            result.value && (
                                <Flex>
                                    <Text fontSize={2} weight="medium">
                                        {text}
                                    </Text>
                                    <Text color={sentimentColor}>
                                        {result.value}
                                    </Text>
                                </Flex>
                            )
                        )}
                        {languages && languages.length === 0 && (
                            <Box
                                bg="#f8df4f"
                                mt="auto"
                                p={3}
                                css={{ borderRadius: '8px' }}
                            >
                                <Text color="#043f38" weight="medium">
                                    Before you can train your model, you need to
                                    select at least 1 language!
                                </Text>
                            </Box>
                        )}
                        {model_group === 'classifier' && (
                            <Flex
                                mx={3}
                                flexDirection="column"
                                mt="auto"
                                mb={2}
                            >
                                <Button
                                    mb={2}
                                    disabled={
                                        isMultilang &&
                                        languages &&
                                        languages.length === 0
                                    }
                                    label="Train & Test"
                                    onClick={this.handleTrain}
                                    data-cy="train"
                                />
                                <Button
                                    mb={2}
                                    disabled={
                                        isMultilang &&
                                        languages &&
                                        languages.length === 0
                                    }
                                    label="Test"
                                    onClick={this.handleTest}
                                    data-cy="test"
                                />
                                <Flex my={2}>
                                    <Text>Last Trained: &nbsp;</Text>
                                    <TrainTimeAgo stamp={training_stamp} />
                                </Flex>
                            </Flex>
                        )}
                        {model_type === 'sim_word_entity_extractor' && (
                            <Flex
                                mx={3}
                                flexDirection="column"
                                mt="auto"
                                mb={2}
                            >
                                <Button
                                    mb={2}
                                    label="Train"
                                    onClick={this.handleTrain}
                                    data-cy="train"
                                />
                            </Flex>
                        )}
                    </Fragment>
                )}
            </Flex>
        );
    }
}

Chatbox.propTypes = {
    clear: PropTypes.func.isRequired,
    classify: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }).isRequired,
    result: PropTypes.any,
    train: PropTypes.func,
    test: PropTypes.func,
    model: PropTypes.shape({
        threshold: PropTypes.number,
        long_name: PropTypes.string,
        name: PropTypes.string,
        training_stamp: PropTypes.string,
        model_group: PropTypes.string,
        languages: PropTypes.array
    }),
    training: PropTypes.bool,
    testing: PropTypes.bool,
    schema: PropTypes.object.isRequired,
    languages: PropTypes.array,
    words: PropTypes.array,
    updateModel: PropTypes.func,
    algorithm: PropTypes.string
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Chatbox)
);
