import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import CurationEntryHeader from '../CurationEntryHeader';
import CurationEntryDetails from '../CurationEntryDetails';
import CurationEntrySampleSet from '../CurationEntrySampleSet';
import { Card, Flex, Box, Text } from '../../../lavender';
import { Chevron } from '../../../SampleField';

class CurationEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            index: props.index,
            open: false,
            hover: false
        };
        this.toggle = this.toggle.bind(this);
        this.onHoverOver = this.onHoverOver.bind(this);
        this.onHoverOut = this.onHoverOut.bind(this);
    }

    toggle() {
        this.setState({ open: !this.state.open });
    }

    onHoverOver() {
        this.setState({ hover: true });
    }

    onHoverOut() {
        this.setState({ hover: false });
    }

    render() {
        const { item, index, open, hover } = this.state;
        return (
            item.truth &&
            item.predicted && (
                <Card p={3} my={3}>
                    <Flex
                        alignItems="center"
                        onMouseOver={this.onHoverOver}
                        onMouseOut={this.onHoverOut}
                        onClick={this.toggle}
                        css={{
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                        data-cy={`open-curation-entry-${index + 1}`}
                    >
                        <Text
                            color="white"
                            px={2}
                            py={1}
                            mr={2}
                            bg="brand.0"
                            fontSize={3}
                            weight="bold"
                            css={{ borderRadius: '4px' }}
                        >
                            {index + 1}
                        </Text>
                        <Box>
                            <CurationEntryHeader item={item} index={index} />
                            <CurationEntryDetails item={item} />
                        </Box>
                        <Box ml="auto">
                            <Chevron hover={hover} open={open} />
                        </Box>
                    </Flex>
                    {open && (
                        <Fragment>
                            <CurationEntrySampleSet
                                labelType="predicted"
                                item={item}
                            />
                        </Fragment>
                    )}
                </Card>
            )
        );
    }
}

CurationEntry.propTypes = {
    item: PropTypes.shape({
        truth: PropTypes.string,
        predicted: PropTypes.string,
        metrics: PropTypes.object,
        samples: PropTypes.array
    }),
    open: PropTypes.bool,
    index: PropTypes.number
};

export default CurationEntry;
