import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router';
import { Flex } from '../../../../lavender';
import { addSamples } from '../../../../../redux/actions/samples';
import { EditableDiv } from '../../../../EditableDiv';
import * as selectors from '../../../../../redux/reducers';
import AddSampleCard from '../AddSample/AddSampleCard';
import AddSampleButton from '../AddSample/AddSampleButton';

const mapStateToProps = state => ({
    samples: selectors.getAllSamplesByIds(state)
});

const mapDispatchToProps = dispatch => ({
    addSample: payload => dispatch(addSamples(payload))
});

export class AddCRFSample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            focused: false,
            textEl: null
        };
    }
    onHoverOver = () => {
        this.setState({ hover: true });
    };

    onHoverOut = () => {
        this.setState({ hover: false });
    };

    valid = () => {
        const { samples } = this.props;
        const { text } = this.state;
        if (text === '') return false;
        return !samples.find(sample => sample.text === text);
    };

    onInputText = event => {
        const text = event.target.innerText.trim();
        this.setState({ text, textEl: event.target });
    };

    onBlur = () => {
        this.setState({ focused: false });
    };

    onFocus = () => {
        this.setState({ focused: true });
    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // TODO: Add sample
        }
    };

    render() {
        const { theme } = this.props;
        const { focused, text } = this.state;
        return (
            <AddSampleCard focused={focused} theme={theme}>
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    onMouseOver={this.onHoverOver}
                    onMouseOut={this.onHoverOut}
                    css={{
                        '&:hover': {
                            backgroundColor: theme.colors.ui[1]
                        }
                    }}
                >
                    <EditableDiv
                        placeholder="Type your example text here..."
                        onInput={this.onInputText}
                        onKeyPress={this.handleKeyPress}
                        contentEditable="true"
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        width={1}
                        fontSize={3}
                        py={25}
                        pl={4}
                        data-cy={'type-text'}
                    />
                    {text && (
                        <AddSampleButton onClick={this.handleAdd}>
                            Add Sample
                        </AddSampleButton>
                    )}
                </Flex>
            </AddSampleCard>
        );
    }
}

AddCRFSample.propTypes = {
    sample: PropTypes.object,
    samples: PropTypes.object,
    sampleType: PropTypes.string.isRequired,
    addSample: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }),
    theme: PropTypes.object.isRequired
};

export default withRouter(
    withTheme(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddCRFSample)
    )
);
