import React from 'react';
import PropTypes from 'prop-types';

import { Button as Base } from './Button';
import { Flex, Box, Loading } from '../';

const Button = ({
    icon,
    reverse,
    label,
    children,
    loading,
    css,
    caps,
    plain,
    ...props
}) => {
    const first = reverse ? label : <Box>{icon}</Box>;
    const second = reverse ? icon : <Box>{label}</Box>;
    loading = loading && <Loading color="white" size="m" />;
    return (
        <Base caps={caps ? 1 : 0} plain={plain ? 1 : 0} {...props} {...css}>
            <Flex alignItems="center" justifyContent="center">
                {loading}
                {first}
                {second}
                {children}
            </Flex>
        </Base>
    );
};

Button.defaultProps = {
    size: 'l',
    caps: true
};

Button.propTypes = {
    children: PropTypes.node,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    tertiary: PropTypes.bool,
    plain: PropTypes.bool,
    warning: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    label: PropTypes.node,
    onClick: PropTypes.func,
    reverse: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'reset', 'submit']),
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
    caps: PropTypes.bool,
    loading: PropTypes.bool,
    css: PropTypes.object
};

export default Button;
