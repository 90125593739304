import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Card } from '../../../../../lavender';

const AddSampleCard = ({ focused = false, theme: { colors }, children }) => (
    <Card
        borderRadius={'8px 8px 0px 0px'}
        css={{
            borderLeft: !focused
                ? `4px solid ${colors.ui[0]}`
                : `4px solid ${colors.support[1]}`,
            '&:hover': {
                backgroundColor: colors.ui[1],
                borderLeft: !focused
                    ? `4px solid ${colors.brand[2]}`
                    : `4px solid ${colors.support[1]}`
            }
        }}
    >
        {children}
    </Card>
);

AddSampleCard.propTypes = {
    focused: PropTypes.bool,
    theme: PropTypes.object.isRequired,
    children: PropTypes.any
};

export default withTheme(AddSampleCard);
