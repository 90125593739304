import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import SampleField from '../../../../SampleField';
import LabelCard from '../../../SamplesView/Samples/Sample/LabelCard';
import * as actions from '../../../../../redux/actions/similarWords';
import * as selectors from '../../../../../redux/reducers';

const mapStateToProps = state => ({
    words: selectors.getAllSimilarWordsIds(state)
});

const mapDispatchToProps = dispatch => ({
    editWord: payload => dispatch(actions.editSimilarWord(payload)),
    removeWord: payload => dispatch(actions.deleteSimilarWord(payload))
});

class Word extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            word: props.id,
            original: props.id
        };
        this.toggle = this.toggle.bind(this);
        this.valid = this.valid.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    toggle() {
        this.setState({ open: !this.state.open });
    }

    valid(value) {
        const { words } = this.props;
        if (value === '' || value === this.state.original) return false;
        return !words.find(id => id === value);
    }

    handleEdit(value) {
        const { editWord } = this.props;
        const { original } = this.state;

        this.valid(value) &&
            editWord({
                word: [original, value]
            });
    }

    handleDelete() {
        const { removeWord } = this.props;
        const { word } = this.state;

        removeWord({
            word
        });
    }

    render() {
        const { id } = this.props;
        return (
            <Fragment>
                <LabelCard key={id} data-cy={`similar-word-${id}`}>
                    <SampleField
                        text={id}
                        onEdit={this.handleEdit}
                        onDelete={this.handleDelete}
                        expandable={false}
                        toggle={this.toggle}
                        divProps={{ fontWeight: 'medium', p: 1 }}
                        name="similar-word"
                        styleProps={{ pl: 25 }}
                    />
                </LabelCard>
            </Fragment>
        );
    }
}

Word.propTypes = {
    id: PropTypes.string.isRequired,
    words: PropTypes.array.isRequired,
    removeWord: PropTypes.func.isRequired,
    editWord: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    })
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Word)
);
