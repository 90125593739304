import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    editSample,
    addSamples,
    deleteSample
} from '../../../../../redux/actions/samples';
import SampleField from '../../../../SampleField';
import * as selectors from '../../../../../redux/reducers';
import { validateText } from '../../../../../utils';

const mapStateToProps = (state, prevProps) => ({
    sample: selectors.getSampleById(state, prevProps.uuid)
});

const mapDispatchToProps = dispatch => ({
    editSample: payload => dispatch(editSample(payload)),
    addSamples: payload => dispatch(addSamples(payload)),
    deleteText: payload => dispatch(deleteSample(payload))
});

export class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: props.label,
            sampleType: props.sampleType
        };
    }

    handleEdit = value => {
        const {
            item,
            editSample,
            match: { params },
            texts
        } = this.props;
        const { label, sampleType } = this.state;
        const sample = [
            {
                label,
                uuid: item.uuid,
                text: value,
                type: sampleType
            }
        ];

        if (!validateText(value, texts)) return;
        return editSample({
            ...params,
            sample
        });
    };

    handleDelete = () => {
        const { label, sampleType } = this.state;
        const {
            item,
            match: { params },
            deleteText
        } = this.props;
        const sample = [
            {
                label,
                uuid: item.uuid,
                text: item.text,
                type: sampleType
            }
        ];
        deleteText({
            ...params,
            sample
        });
    };

    render() {
        const { sampleType } = this.state;
        const { item, label } = this.props;
        return (
            item && (
                <SampleField
                    text={item.text}
                    onEdit={this.handleEdit}
                    onDelete={this.handleDelete}
                    styleProps={{ pl: 25 }}
                    name={`text-${label}-${item.text}`}
                    sampleType={sampleType}
                    divProps={{ py: 25, pl: 30 }}
                />
            )
        );
    }
}

Text.propTypes = {
    item: PropTypes.object.isRequired,
    texts: PropTypes.array,
    label: PropTypes.string.isRequired,
    sampleType: PropTypes.string.isRequired,
    editSample: PropTypes.func.isRequired,
    deleteText: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    })
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Text)
);
