import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { Icon, Flex, Text } from '../../lavender';

const mapDispatchToProps = dispatch => ({
    goBack: () => dispatch(goBack())
});

const Header = ({ title, goBack }) => {
    return (
        <Flex mx={4} my={3} justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
                <Icon
                    fontWeight="xbold"
                    size={6}
                    name="chevron"
                    left
                    onClick={goBack}
                    data-cy={`back-from-${title}`}
                />
                <Text mx={2} fontSize={7} fontWeight="bold">
                    {title}
                </Text>
            </Flex>
        </Flex>
    );
};

Header.propTypes = {
    title: PropTypes.string.isRequired,
    goBack: PropTypes.func.isRequired
};

export default connect(
    null,
    mapDispatchToProps
)(Header);
