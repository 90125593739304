import styled from 'styled-components';
import { space, color } from 'styled-system';
import { themeGet } from 'styled-system';
import { darken } from 'polished';

const hover = props => ({
    color: themeGet('colors.brand.0', 'blue')(props),
    cursor: 'pointer',
    '&:hover': {
        color: darken(0.2, themeGet('colors.brand.0', 'blue')(props))
    }
});

export const Link = styled.a`
    ${hover}
    ${space}
    ${color}
`;

Link.displayName = 'Link';

export default Link;
