import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';

import DeleteModelDialog from '../../Dialogs/DeleteModelDialog';
import EditableField from './EditableField';
import { updateModel, deleteModel } from '../../../redux/actions/models';
import { Icon, Box, Flex, Text } from '../../lavender';
import * as selectors from '../../../redux/reducers';

const Heading = props => <Text my={3} weight="bold" fontSize={4} {...props} />;

const DangerHeading = props => (
    <Text fontSize={5} color="support.0" pb={2} weight="medium" {...props} />
);

const DangerZone = props => (
    <Text my={3} color="support.0" weight="bold" fontSize={4} {...props} />
);

const OutlineTitle = props => (
    <Flex
        width={1}
        p={3}
        css={{
            border: '2px solid #dfe3e6',
            borderRadius: '0px 6px 6px 0px'
        }}
        {...props}
    />
);

const DangerBox = props => (
    <Box
        my={3}
        p={2}
        css={{
            border: '2px solid tomato',
            borderRadius: '6px'
        }}
        {...props}
    />
);

const mapStateToProps = state => ({
    activeDomain: selectors.getActiveDomain(state)
});

const mapDispatchToProps = {
    deleteModel,
    updateModel
};

class General extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            copied: false,
            snackOpen: false
        };
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ snackOpen: false });
    }

    render() {
        const { params } = this.props.match;
        const { schema, model, activeDomain } = this.props;
        const modelSchema = schema['update'][params.modelType];
        return (
            <Fragment>
                <Box my={3}>
                    <Heading>Slug</Heading>
                    <Text mx={1}>{model.name || model.long_name}</Text>
                </Box>
                <Box my={3}>
                    <Heading>Description</Heading>
                    {params.modelType &&
                        modelSchema &&
                        modelSchema.hasOwnProperty('desc') && (
                            <EditableField
                                text={model.desc}
                                onEdit={desc => {
                                    this.props.updateModel({
                                        ...params,
                                        payload: {
                                            desc
                                        }
                                    });
                                }}
                            />
                        )}
                </Box>
                <Box my={3}>
                    <Heading>API Keys</Heading>
                    <Flex py={1} alignItems="center">
                        <OutlineTitle
                            fontSize={2}
                            weight="bold"
                            width="200px"
                            css={{
                                borderRight: 'none'
                            }}
                        >
                            NLU API Token
                        </OutlineTitle>
                        <OutlineTitle>
                            {activeDomain.api_key}
                            <Box ml="auto">
                                <CopyToClipboard
                                    text={activeDomain.api_key}
                                    onCopy={() =>
                                        this.setState({
                                            snackOpen: true,
                                            copied: true
                                        })
                                    }
                                >
                                    <span>
                                        <Icon name="copy" />
                                    </span>
                                </CopyToClipboard>
                            </Box>
                        </OutlineTitle>
                    </Flex>
                </Box>
                {model.modelType !== 'sentiment_detector' && (
                    <Box my={10}>
                        <DangerZone>
                            <Icon warning name="warning" />
                            &nbsp;Danger Zone&nbsp;
                            <Icon warning name="warning" />
                        </DangerZone>
                        <DangerBox>
                            <DangerHeading>Delete model</DangerHeading>
                            <Box>
                                <Text>
                                    Are you sure you want to delete the model
                                    <Text as="span" weight="bold">
                                        &nbsp;{model.long_name || model.name}
                                        &nbsp;
                                    </Text>
                                    ? This will destroy the model with all
                                    corresponding data and cannot be undone!
                                </Text>
                                <Box mt={3}>
                                    <DeleteModelDialog
                                        model={model}
                                        domain={params.domainId}
                                    />
                                </Box>
                            </Box>
                        </DangerBox>
                    </Box>
                )}
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={this.state.snackOpen}
                    onClose={this.handleClose}
                    SnackbarContentProps={{
                        'aria-describedby': 'message-id'
                    }}
                    message={
                        <Text fontSize={2} weight="bold" color="ui.0">
                            Copied!
                        </Text>
                    }
                />
            </Fragment>
        );
    }
}

General.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }).isRequired,
    model: PropTypes.shape({
        desc: PropTypes.string,
        long_name: PropTypes.string,
        name: PropTypes.string
    }),
    schema: PropTypes.shape({
        update: PropTypes.object,
        types: PropTypes.object
    }),
    updateModel: PropTypes.func.isRequired,
    activeDomain: PropTypes.object,
    deleteModel: PropTypes.func
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(General)
);
