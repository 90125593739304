import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { Route, withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import stackedLogo from '../../images/stacked_logo_white_small.png';
import feSmall from '../../images/fe_small.png';
import { MenuList } from './MenuList';
import { logout } from '../../redux/actions/auth';
import { Image, SelectBox, Flex, Box, Icon, Text } from '../lavender';
import NavItem from './NavItem';
import { Footer } from './Footer';
import * as actions from '../../redux/actions/domains';
import * as selectors from '../../redux/reducers';
import { setActive } from '../../redux/utils/actions/lasso';
import { mainNavItems } from '../../config';

const convertItemsToSelectOptions = items =>
    Object.values(items).map(item => ({ value: item.id, label: item.name }));

const getActiveDomainSelection = item =>
    item ? { value: item.id, label: item.name } : null;

const mapStateToProps = state => ({
    domains: convertItemsToSelectOptions(selectors.getAllDomainsByIds(state)),
    activeDomain: getActiveDomainSelection(selectors.getActiveDomain(state)),
    isLoading: selectors.getIsLoadingDomains(state)
});

const mapDispatchToProps = {
    logout,
    getDomains: actions.getAllDomains,
    redirect: push,
    setActive: setActive('domains')
};

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.filterDomains = this.filterDomains.bind(this);
        this.changeDomain = this.changeDomain.bind(this);
    }

    componentDidMount() {
        if (!this.props.domains) {
            this.props.getDomains();
        }
    }

    filterDomains(domain) {
        if (domain.id !== this.props.activeDomain) {
            return domain;
        }
    }

    changeDomain(selected) {
        const { redirect } = this.props;
        redirect(`/domain/${selected.value}`);
        this.props.setActive(selected.value);
    }

    toggle = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { domains, isLoading, theme, activeDomain } = this.props;
        const { open } = this.state;

        return (
            <Flex
                flexDirection={['column', 'row', 'column']}
                bg={theme.colors.brand[0]}
                width={open ? [1, 1, '230px'] : '64px'}
                css={{
                    overflowY: 'auto',
                    transition: 'width 0.1s ease-in-out'
                }}
            >
                <Flex
                    alignItems="center"
                    justifyContent={'center'}
                    flexDirection={
                        open ? ['row', 'row', 'column', 'column'] : 'column'
                    }
                >
                    <Flex
                        alignItems="center"
                        justifyContent={!open ? 'center' : 'left'}
                        flexDirection={!open ? 'column' : 'row'}
                    >
                        <Link to="/">
                            <Image
                                px={open ? 30 : 1}
                                py={open ? 15 : 2}
                                width={open ? ['auto'] : 'auto'}
                                src={open ? stackedLogo : feSmall}
                            />
                        </Link>
                        {open ? (
                            <Icon
                                mr={2}
                                name="chevron"
                                left
                                color="white"
                                onClick={this.toggle}
                            />
                        ) : (
                            <Icon
                                my={1}
                                name="chevron"
                                right
                                color="white"
                                onClick={this.toggle}
                            />
                        )}
                    </Flex>
                    {open && (
                        <SelectBox
                            px={3}
                            width={[1 / 2, 1 / 2, 1]}
                            value={activeDomain}
                            options={[
                                {
                                    label: 'Domains',
                                    options: domains
                                }
                            ]}
                            placeholder="Select a domain..."
                            onChange={this.changeDomain}
                            defaultValue={domains.find(
                                ({ value }) => value === activeDomain
                            )}
                            isLoading={isLoading}
                            MenuList={MenuList}
                        />
                    )}
                </Flex>

                <Flex flexDirection="column" mx={10} my={3}>
                    <Route
                        path="/model/:domainId/"
                        render={({ match: { params } }) => (
                            <NavLink to={`/domain/${params.domainId}/`}>
                                <NavItem
                                    name="Models"
                                    icon="layers"
                                    open={open}
                                />
                            </NavLink>
                        )}
                    />
                    {mainNavItems.map(item => (
                        <NavLink key={item.name} to={item.to}>
                            <NavItem key={item.name} {...item} open={open} />
                        </NavLink>
                    ))}
                </Flex>
                <Box mt="auto" mx={10}>
                    <NavItem name="FAQ" icon="helpOutline" open={open} />
                    <NavItem name="Docs" icon="chromeReaderMode" open={open} />
                    <NavItem
                        name="Log out"
                        icon="exitToApp"
                        onClick={this.props.logout}
                        data-cy="logout"
                        open={open}
                    />
                    {!open && (
                        <Text
                            pl={14}
                            py={3}
                            fontSize={3}
                            css={{ '&:hover': { cursor: 'pointer' } }}
                        >
                            💖
                        </Text>
                    )}
                </Box>
                {open && <Footer />}
            </Flex>
        );
    }
}

Navbar.propTypes = {
    domains: PropTypes.array,
    getDomains: PropTypes.func,
    activeDomain: PropTypes.object,
    isLoading: PropTypes.bool,
    logout: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    setActive: PropTypes.func.isRequired
};

export default withTheme(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(Navbar)
    )
);
