import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Route, withRouter, Redirect } from 'react-router-dom';

import Dashboard from '../Dashboard';
import * as selectors from '../../redux/reducers';

const mapStateToProps = state => ({
    isAuthenticated: selectors.getAuthenticated(state)
});

const AuthenticatedRoute = ({ path, isAuthenticated }) => (
    <Route
        path={path}
        render={props =>
            isAuthenticated ? (
                <Dashboard {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);

AuthenticatedRoute.propTypes = {
    isAuthenticated: PropTypes.bool,
    path: PropTypes.string,
    location: PropTypes.string
};

export default withRouter(connect(mapStateToProps)(AuthenticatedRoute));
