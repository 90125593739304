export const AppName = 'Feersum Lasso';

export const baseFuseConfig = {
    shouldSort: true,
    threshold: 0.6,
    tokenize: true,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: []
};

export const mainNavItems = [{ name: 'Domains', to: '/', icon: 'apps' }];

export const faqModels = ['faq_matcher'];
export const intentModels = ['intent_classifier'];
export const textModels = ['text_classifier'];
export const crfModels = ['crf_entity_extractor'];
export const synonymModels = ['synonym_entity_extractor'];
export const languageModels = ['language_recogniser'];
export const entityModels = [
    'regex_entity_extractor',
    'duckling_entity_extractor'
];

export const modelTypeNavItems = [
    { name: 'All', key: 'all', modelTypes: null },
    { name: 'Text', key: 'text', modelTypes: textModels },
    { name: 'FAQs', key: 'faqs', modelTypes: faqModels },
    { name: 'Synonyms', key: 'synonyms', modelTypes: synonymModels },
    { name: 'CRF', key: 'crf', modelTypes: crfModels },
    { name: 'Intents', key: 'intents', modelTypes: intentModels },

    { name: 'Regex', key: 'regex', modelTypes: 'regex_entity_extractor' },
    {
        name: 'Date Parsers',
        key: 'date_parser',
        modelTypes: 'duckling_entity_extractor'
    },

    { name: 'Entities', key: 'entities', modelTypes: entityModels },
    { name: 'Languages', key: 'languages', modelTypes: languageModels },
    { name: 'Sentiment', key: 'sentiment', modelTypes: 'sentiment_detector' }
];

export const modelNavItems = [
    {
        name: 'Samples',
        key: 'samples',
        to: '/samples'
    },
    {
        name: 'Curation',
        key: 'curation',
        to: '/curation'
    },
    {
        name: 'Analytics',
        key: 'analytics',
        to: '/analytics'
    },
    {
        name: 'Languages',
        key: 'languages',
        to: '/languages'
    },
    {
        name: 'Training',
        key: 'training',
        to: '/training'
    },
    {
        name: 'Settings',
        key: 'settings',
        to: '/settings'
    },
    {
        name: 'General',
        key: 'general',
        to: '/general'
    }
];

export const modelNavItemsExtractors = [
    {
        name: 'Samples',
        key: 'samples',
        to: '/samples'
    },
    {
        name: 'Languages',
        key: 'languages',
        to: '/languages'
    },
    {
        name: 'Settings',
        key: 'settings',
        to: '/settings'
    },
    {
        name: 'General',
        key: 'general',
        to: '/general'
    }
];

export const samplesOptions = [
    {
        label: 'Training',
        value: 'training'
    },
    {
        label: 'Testing',
        value: 'testing'
    }
];

export const sortingOptions = [
    {
        label: 'A-Z',
        value: 'az'
    },
    {
        label: 'Latest',
        value: 'latest'
    },
    {
        label: 'Default',
        value: 'default'
    }
];

export const confusionMatrixOptions = [
    {
        label: 'Precision',
        value: 'precision'
    },
    {
        label: 'F-Score',
        value: 'fscore'
    },
    {
        label: 'Recall',
        value: 'recall'
    },
    {
        label: 'Sample Count',
        value: 'sample_count'
    }
];

export const responsiveHeatMapCanvasConfig = {
    colors: 'blues',
    margin: {
        top: 120
    },
    forceSquare: true,
    indexBy: 'label',
    axisTop: {
        orient: 'left',
        tickSize: 10,
        tickPadding: 5,
        tickRotation: -45,
        legend: 'Predicted Labels',
        legendPosition: 'middle',
        legendOffset: -80
    },
    axisLeft: {
        orient: 'left',
        tickSize: 10,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'True Labels',
        legendPosition: 'middle',
        legendOffset: -100
    },
    cellOpacity: 1.0,
    cellBorderColor: 'black',
    padding: 1,
    cellBorderWidth: 1,
    //    labelTextColor: 'white',
    //    defs: [
    //        {
    //            id: 'lines',
    //            type: 'patternLines',
    //            background: 'inherit',
    //            color: 'rgba(0, 0, 0, 0.1)',
    //            rotation: -45,
    //            lineWidth: 4,
    //            spacing: 7
    //        }
    //    ],
    //    fill: [
    //        {
    //            id: 'lines'
    //        }
    //    ],
    //animate: false,
    //motionStiffness: 80,
    //motionDamping: 9,
    //hoverTarget: 'cell',
    cellHoverOthersOpacity: 1.0,
    enableLabels: false
};
