import React from 'react';
import { lighten } from 'polished';
import { Icon, Text } from '../lavender';

export const whichModel = model => {
    switch (model) {
        case 'faqs':
            return 'an FAQ';
        case 'text':
            return 'a Text';
        case 'languages':
            return 'a Language';
        case 'intents':
            return 'an Intent';
        case 'entities':
            return 'an Entity';
        case 'preTrained':
            return 'a Pre-Trained';
        default:
            return '';
    }
};

export const getModelTypes = model => {
    switch (model) {
        case 'faqs':
            return 'faq_matcher';
        case 'text':
            return 'text_classifier';
        case 'languages':
            return 'language_recogniser';
        case 'intents':
            return 'intent_classifier';
        case 'entities':
            return 'entities';
        case 'preTrained':
            return 'pretrained';
        default:
            return '';
    }
};

export const hover = (group, key, theme) => {
    if (group === key)
        return {
            backgroundColor: lighten(
                0.05,
                theme ? theme.colors.brand[0] : 'black'
            ),
            color: 'white'
        };
};

export const whichIcon = model => {
    switch (model) {
        case 'text_classifier':
            return <Icon size={7} name="listAlt" />;
        case 'faq_matcher':
            return <Icon size={7} name="questionAnswer" />;
        case 'intent_classifier':
            return <Icon size={7} name="intent" />;
        case 'regex_entity_extractor':
            return (
                <Text color="brand.0" fontSize={7} weight="xbold">
                    (.*)
                </Text>
            );
        case 'duckling_entity_extractor':
            return (
                <Text color="brand.0" fontSize={7} weight="xbold">
                    🦆
                </Text>
            );
        case 'sentiment_detector':
            return <Icon size={7} name="sentiment" />;
        case 'date_parser':
            return <Icon size={7} name="accessTime" />;
        case 'language_recogniser':
            return <Icon size={7} name="language" />;
        case 'synonym_entity_extractor':
            return <Icon size={7} name="intent" />;
        default:
            return '';
    }
};
