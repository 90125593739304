import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sizing, fontSizes, colors } from '../theme';
import Box from '../Box';

const getSize = ({ size }) => {
    return Object.keys(sizing).includes(size) ? fontSizes[sizing[size]] : size;
};

const Loading = ({ size, color, ...props }) => {
    const brand = colors.brand[0] || 'black';
    return (
        <Box {...props}>
            <CircularProgress
                size={getSize({ size })}
                style={{ color: color || brand }}
                {...props}
            />
        </Box>
    );
};

Loading.defaultProps = {
    size: 'xl'
};

Loading.propTypes = {
    size: PropTypes.oneOfType([
        PropTypes.oneOf(Object.keys(sizing)),
        PropTypes.number
    ]),
    color: PropTypes.string
};

export default Loading;
