import Api from '../../middleware/api';

import {
    request,
    requestSuccess,
    clear,
    requestUpdate,
    requestCreate,
    requestByIdSuccess,
    clearAll
} from '../utils/actions/resource';
import { domains as schema } from '../schema';
import { normalize } from 'normalizr';

export const getAllDomains = () => dispatch => {
    dispatch(clearAll('domains')());
    dispatch(request('domains'));
    Api(dispatch)
        .getDomains()
        .then(domains => {
            const entities = normalize(domains.body, schema).entities;
            dispatch(requestSuccess('domains')(entities.domains || []));
        });
};

export const deleteDomain = payload => dispatch => {
    dispatch(clear('domains')(payload));
    Api(dispatch)
        .deleteDomain(payload)
        .then(() => {
            dispatch(getAllDomains());
        });
};

export const editDomain = payload => dispatch => {
    dispatch(requestUpdate('domains')(payload));
    return Api(dispatch)
        .editDomain(payload)
        .then(() => {
            dispatch(requestByIdSuccess('domains')(payload));
        });
};

export const create = payload => dispatch => {
    dispatch(requestCreate('domains')(payload));
    return Api(dispatch)
        .createDomain(payload)
        .then(() => {
            dispatch(requestSuccess('domains'));
            dispatch(getAllDomains());
        });
};
