import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Box } from '../../../../../lavender';

const SampleBorder = ({ theme, ...props }) => (
    <Box
        css={{
            borderLeft: `4px solid ${theme.colors.brand[0]}`
        }}
        {...props}
    />
);

SampleBorder.propTypes = {
    theme: PropTypes.object.isRequired
};

export default withTheme(SampleBorder);
