import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import { Icon, Flex, Text } from '../../lavender';
import { deleteDomain } from '../../../redux/actions/domains';
import {
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    ActionButton
} from '../styles';

const mapDispatchToProps = dispatch => ({
    deleteDomain: id => dispatch(deleteDomain(id))
});

class DeleteDomainDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };

        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        this.props.deleteDomain({ domainId: this.props.domain.id });
        this.toggleDialog();
    }

    toggleDialog() {
        this.setState({ open: !this.state.open });
    }

    render() {
        const { open } = this.state;
        const { domain } = this.props;
        return (
            <Fragment>
                <Flex
                    p={3}
                    onClick={this.toggleDialog}
                    data-cy={`delete-domain-${domain.name}`}
                    alignItems="center"
                >
                    <Icon color="support.0" name="delete" />
                    <Text ml={2} fontSize={3} weight="medium">
                        Delete
                    </Text>
                </Flex>
                <Dialog
                    open={open}
                    onClose={this.toggleDialog}
                    data-cy="delete-domain-dialog"
                >
                    <DialogContent>
                        <DialogTitle>Delete {domain.name}?</DialogTitle>
                        <DialogContentText>
                            Are you sure you want to delete the domain called
                            &nbsp;
                            <strong>{domain.name}</strong>?
                        </DialogContentText>
                        <DialogActions>
                            <ActionButton
                                warning
                                label="Yes"
                                onClick={this.handleSubmit}
                                type="submit"
                            />
                            <ActionButton
                                label="No"
                                plain
                                onClick={this.toggleDialog}
                                data-cy="close-dialog"
                            />
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

DeleteDomainDialog.propTypes = {
    deleteDomain: PropTypes.func.isRequired,
    domain: PropTypes.object.isRequired,
    button: PropTypes.any
};

export default connect(
    null,
    mapDispatchToProps
)(DeleteDomainDialog);
