import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { Text } from '../../../lavender';

const getColor = props => {
    if (props.samples) {
        return props.samples === 0 ? 'support.1' : 'support.0';
    }

    if (props.percentage) {
        return props.percentage >= 0.8
            ? 'support.1'
            : props.percentage > 0.5
            ? 'support.2'
            : 'support.0';
    }
};

const Metric = props => (
    <Text
        weight="bold"
        css={{ display: 'inline' }}
        pr={1}
        color={getColor(props)}
        {...props}
    />
);

class CurationEntryDetails extends React.Component {
    render() {
        const { metrics } = this.props.item;
        const { sample_count, precision, recall, fscore } = metrics;

        return (
            <Text m={1}>
                <Tooltip
                    enterDelay={600}
                    leaveDelay={300}
                    id="tooltip-warnings"
                    title="The number of potential issues with the model for this label."
                    placement="bottom"
                >
                    <span>
                        Warnings:&nbsp;
                        <Metric samples={sample_count}>{sample_count}</Metric>
                    </span>
                </Tooltip>
                {metrics && (
                    <span>
                        (Precision* = &nbsp;
                        <Metric percentage={precision}>
                            {(precision * 100).toFixed(0)}%
                        </Metric>
                        ,&nbsp; Recall* = &nbsp;
                        <Metric percentage={recall}>
                            {(recall * 100).toFixed(0)}%
                        </Metric>
                        )
                    </span>
                )}
            </Text>
        );
    }
}

CurationEntryDetails.propTypes = {
    item: PropTypes.shape({
        truth: PropTypes.string.isRequired,
        predicted: PropTypes.string.isRequired,
        metrics: PropTypes.object
    })
};

export default CurationEntryDetails;
