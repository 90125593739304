import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Card } from '../../../../lavender';

const LabelCard = ({ theme: { colors }, ...props }) => (
    <Card
        py={3}
        css={{
            borderRadius: '0px',
            border: `2px solid transparent`,
            borderBottom: `2px solid ${colors.ui[1]}`,
            '&:hover': {
                backgroundColor: colors.ui[1],
                border: `2px solid ${colors.support[3]}`,
                cursor: 'pointer'
            }
        }}
        {...props}
    />
);

LabelCard.propTypes = {
    theme: PropTypes.object.isRequired
};

export default withTheme(LabelCard);
