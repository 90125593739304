import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { textAlign } from 'styled-system';
import { Text as Base } from 'rebass';

import { sizing, fontWeights, variantTypes, theme } from '../theme';
import { italic, weight, caps, underline, whichVariant } from '../utils';

export const brandColor = props => {
    const brand = whichVariant(props);
    let color = theme.colors.text[0];

    switch (brand) {
        case 0:
            color = theme.colors.brand[0];
            break;
        case 1:
            color = theme.colors.brand[1];
            break;
        case 2:
            color = theme.colors.brand[2];
            break;
        case 3:
            color = theme.colors.support[0];
            break;
        case 4:
            color = theme.colors.text[0];
            break;
    }

    return !props.color && { color };
};

export const _Text = styled(Base)(
    italic,
    weight,
    caps,
    underline,
    brandColor,
    textAlign
);

export const Text = ({ truncate, children, caps, ...props }) => {
    return (
        <_Text caps={caps ? 1 : 0} {...props}>
            {truncate && children ? children.substring(0, truncate) : children}
        </_Text>
    );
};

Text.displayName = 'Text';

Text.propTypes = {
    children: PropTypes.any,
    fontSize: PropTypes.oneOfType([
        PropTypes.oneOf(Object.keys(sizing)),
        PropTypes.number
    ]),
    underline: PropTypes.bool,
    caps: PropTypes.bool,
    truncate: PropTypes.number,
    italic: PropTypes.bool,
    weight: PropTypes.oneOf(Object.keys(fontWeights)),
    as: PropTypes.oneOf([
        'div',
        'p',
        'a',
        'span',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'pre'
    ]),
    variant: PropTypes.oneOf(variantTypes)
};

export default Text;
