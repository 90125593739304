import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, withRouter, Switch } from 'react-router';

import Model from '../Model';
import Domains from '../Domains';
import Navbar from '../Navbar';
import Models from '../Models';
import { loadSchema } from '../../redux/actions/schema';
import { Flex, Box } from '../lavender';
import { getAllDomains } from '../../redux/actions/domains';
import * as selectors from '../../redux/reducers';

const mapStateToProps = state => ({
    tokenExpired: selectors.getTokenExpired(state)
});

const mapDispatchToProps = dispatch => ({
    onLoad: () => dispatch(getAllDomains()),
    loadSchema: () => dispatch(loadSchema())
});

class Dashboard extends Component {
    componentDidMount() {
        this.props.loadSchema();
        this.props.onLoad();
    }

    render() {
        return (
            <Flex
                flexDirection={['column', 'column', 'row']}
                bg="ui.1"
                css={{ height: '100vh' }}
            >
                <Navbar />
                <Box flex="1" css={{ overflowY: 'auto' }}>
                    <Switch>
                        <Route exact path="/" component={Domains} />
                        <Route
                            exact
                            path="/domain/:domainId/"
                            component={Models}
                        />
                        <Route
                            path="/model/:domainId/:modelName/:modelType/"
                            component={Model}
                        />
                    </Switch>
                </Box>
            </Flex>
        );
    }
}

Dashboard.propTypes = {
    loadSchema: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Dashboard)
);
