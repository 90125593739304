import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { space, color } from 'styled-system';
import { fontSizes } from '../theme';
import { AngleDoubleRight } from 'styled-icons/fa-solid/AngleDoubleRight';
import { AngleDoubleLeft } from 'styled-icons/fa-solid/AngleDoubleLeft';
import { TrashAlt } from 'styled-icons/fa-regular/TrashAlt';
import { Edit } from 'styled-icons/material/Edit';
import { AddCircleOutline } from 'styled-icons/material/AddCircleOutline';
import { Check } from 'styled-icons/material/Check';
import { FileUpload } from 'styled-icons/material/FileUpload';
import { FileDownload } from 'styled-icons/material/FileDownload';
import { Settings } from 'styled-icons/material/Settings';
import { Warning } from 'styled-icons/material/Warning';
import { Layers } from 'styled-icons/material/Layers';
import { LibraryBooks } from 'styled-icons/material/LibraryBooks';
import { Widgets } from 'styled-icons/material/Widgets';
import { Group } from 'styled-icons/material/Group';
import { GroupWork } from 'styled-icons/material/GroupWork';
import { HelpOutline } from 'styled-icons/material/HelpOutline';
import { ChromeReaderMode } from 'styled-icons/material/ChromeReaderMode';
import { ExitToApp } from 'styled-icons/material/ExitToApp';
import { SettingsApplications } from 'styled-icons/material/SettingsApplications';
import { ListAlt } from 'styled-icons/fa-regular/ListAlt';
import { Apps } from 'styled-icons/material/Apps';
import { InsertChart } from 'styled-icons/material/InsertChart';
import { Search } from 'styled-icons/material/Search';
import { ContentCopy } from 'styled-icons/material/ContentCopy';
import { Menu } from 'styled-icons/material/Menu';
import { ChatBubbleOutline } from 'styled-icons/material/ChatBubbleOutline';
import { ObjectGroup } from 'styled-icons/fa-regular/ObjectGroup';
import { MoreHoriz } from 'styled-icons/material/MoreHoriz';
import { MoreVert } from 'styled-icons/material/MoreVert';
import { AddCircle } from 'styled-icons/material/AddCircle';
import { QuestionAnswer } from 'styled-icons/material/QuestionAnswer';
import { TextFields } from 'styled-icons/material/TextFields';
import { SentimentSatisfied } from 'styled-icons/material/SentimentSatisfied';
import { AccessTime } from 'styled-icons/material/AccessTime';
import { Language } from 'styled-icons/fa-solid/Language';
import { SpeakerNotes } from 'styled-icons/material/SpeakerNotes';
import { Storage } from 'styled-icons/material/Storage';
import { TextFormat } from 'styled-icons/material/TextFormat';
import { Style } from 'styled-icons/material/Style';
import { ShortText } from 'styled-icons/material/ShortText';
import { Undo } from 'styled-icons/material/Undo';
import {
    X,
    Plus,
    Minus,
    Square,
    Diamond,
    Burger,
    Chevron,
    Arrow,
    Triangle
} from 'reline';

const primitives = {
    x: X,
    plus: Plus,
    minus: Minus,
    square: Square,
    diamond: Diamond,
    burger: Burger,
    chevron: Chevron,
    arrow: Arrow,
    triangle: Triangle
};

const aliases = {
    doubleLeft: AngleDoubleLeft,
    doubleRight: AngleDoubleRight,
    delete: TrashAlt,
    edit: Edit,
    addCircleOutline: AddCircleOutline,
    check: Check,
    import: FileUpload,
    export: FileDownload,
    settings: Settings,
    warning: Warning,
    layers: Layers,
    libraryBooks: LibraryBooks,
    widgets: Widgets,
    group: Group,
    groupWork: GroupWork,
    helpOutline: HelpOutline,
    chromeReaderMode: ChromeReaderMode,
    exitToApp: ExitToApp,
    settingsApplications: SettingsApplications,
    listAlt: ListAlt,
    apps: Apps,
    insertChart: InsertChart,
    search: Search,
    copy: ContentCopy,
    menu: Menu,
    chatBubbleOutline: ChatBubbleOutline,
    objectGroup: ObjectGroup,
    moreHoriz: MoreHoriz,
    moreVert: MoreVert,
    addCircle: AddCircle,
    questionAnswer: QuestionAnswer,
    intent: SpeakerNotes,
    similar: TextFields,
    sentiment: SentimentSatisfied,
    accessTime: AccessTime,
    language: Language,
    storage: Storage,
    textFormat: TextFormat,
    style: Style,
    shortText: ShortText,
    undo: Undo
};

const icons = {
    ...primitives,
    ...aliases
};

const getPath = ({ name }) => {
    return icons[name];
};

const getSize = ({ size }) => {
    return size < fontSizes.length ? fontSizes[size] : size;
};

const Base = ({ name, size, ...props }) => {
    const Icon = getPath({ name });
    if (!Icon) return false;
    return <Icon size={getSize({ size })} {...props} />;
};

Base.propTypes = {
    name: ({ name }) => {
        if (!allIconKeys.includes(name)) {
            return new Error(
                `Failed prop type: Invalid prop name of value '${name}' supplied to Icon, expected one of ${allIconKeys.toString()} is expected`
            );
        }
    },
    size: PropTypes.oneOfType([PropTypes.number])
};

const css = props => props.css;

const allIconKeys = Object.keys(icons);

const Icon = styled(Base)(
    space,
    color,
    props => ({
        color: props.color
            ? props.color
            : props.warning
            ? props.theme.colors.support[0]
            : props.theme.colors.brand[0],
        fontWeight: 900,
        cursor: 'pointer'
    }),
    css
);

Icon.defaultProps = {
    name: 'check',
    size: 3
};

Icon.propTypes = {
    color: PropTypes.string,
    warning: PropTypes.bool
};

Icon.displayName = 'Icon';

export default Icon;
