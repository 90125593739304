import Api from '../../middleware/api';
import { setResult } from '../utils/actions/lasso';

export const classify = payload => dispatch => {
    Api(dispatch)
        .classify(payload)
        .then(({ body }) => {
            dispatch(setResult('models')(body));
        });
};
