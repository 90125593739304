import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Flex, Button } from '../lavender';

const Input = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

class Upload extends Component {
    handleChange = ({ target }) => {
        const file = target.files[0];
        this.props.handleChange(file);
    };

    render() {
        const { type } = this.props;
        return (
            <Flex>
                <Input
                    type={type}
                    name={type}
                    id={type}
                    onChange={this.handleChange}
                />
                <Button htmlFor={type} as="label" secondary>
                    Choose a file
                </Button>
            </Flex>
        );
    }
}

Upload.defaultProps = {
    type: 'file'
};

Upload.propTypes = {
    type: PropTypes.string,
    handleChange: PropTypes.func.isRequired
};

export default Upload;
