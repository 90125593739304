import {
    SET_ACTIVE,
    CLEAR_ACTIVE,
    SET_RESULT,
    SET_LANGUAGES,
    TRAIN_ERROR,
    TRAIN_START,
    TRAIN_SUCCESS,
    TEST_ERROR,
    TEST_START,
    TEST_SUCCESS,
    TSNE,
    CLEAR_TSNE,
    EDIT_SIMILAR_WORD,
    ADD_SIMILAR_WORD,
    DELETE_SIMILAR_WORD,
    SET_LANGUAGE_MODELS,
    SET_CLASSIFIER_ALGORITHM
} from '../reducers/lasso';

// set the active object on a specific entity
export const setActive = entity => item => ({
    type: `${SET_ACTIVE}/${entity}`,
    payload: item
});

// clear the active object on a specific entity
export const clearActive = entity => ({
    type: `${CLEAR_ACTIVE}/${entity}`
});

export const setResult = entity => item => ({
    type: `${SET_RESULT}/${entity}`,
    payload: item
});

export const setLanguages = entity => payload => ({
    type: `${SET_LANGUAGES}/${entity}`,
    payload
});

export const setLanguageModels = entity => payload => ({
    type: `${SET_LANGUAGE_MODELS}/${entity}`,
    payload
});

export const trainStart = () => ({
    type: TRAIN_START
});

export const trainSuccess = payload => ({
    type: TRAIN_SUCCESS,
    payload
});

export const trainError = payload => ({
    type: TRAIN_ERROR,
    payload
});

export const testStart = () => ({
    type: TEST_START
});

export const testSuccess = payload => ({
    type: TEST_SUCCESS,
    payload
});

export const testError = payload => ({
    type: TEST_ERROR,
    payload
});

export const setTsne = entity => payload => ({
    type: `${TSNE}/${entity}`,
    payload
});

export const clearTsne = entity => () => ({
    type: `${CLEAR_TSNE}/${entity}`
});

export const editSimilarWord = entity => payload => ({
    type: `${EDIT_SIMILAR_WORD}/${entity}`,
    payload
});

export const deleteSimilarWord = entity => payload => ({
    type: `${DELETE_SIMILAR_WORD}/${entity}`,
    payload
});

export const addSimilarWord = entity => payload => ({
    type: `${ADD_SIMILAR_WORD}/${entity}`,
    payload
});

export const setClassifierAlgorithm = entity => payload => ({
    type: `${SET_CLASSIFIER_ALGORITHM}/${entity}`,
    payload
});
