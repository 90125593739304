import React, { Fragment } from 'react';

import RegisterForm from './RegisterForm';
import { Text, Title, Box, NavLink } from '../lavender';

const Register = () => (
    <Fragment>
        <Box css={{ borderBottom: '1px solid #f1eeea' }} p={20}>
            <Text textAlign="center" fontSize={3}>
                Already have an account?
            </Text>
            <NavLink to="/login">
                <Text
                    textAlign="center"
                    fontSize={2}
                    weight="bold"
                    variant="primary"
                >
                    Login Here.
                </Text>
            </NavLink>
        </Box>
        <Box p={35} mb={45}>
            <Title fontSize={6}>Sign up</Title>
            <RegisterForm />
        </Box>
    </Fragment>
);

export default Register;
