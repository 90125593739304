import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Loading, Text } from '../lavender';

export const LoadingIndicator = ({ message }) => (
    <Flex
        css={{ height: '80vh' }}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
    >
        <Loading color="#000" size={100} />
        <Text mt={3} fontSize={6} fontWeight="bold">
            {message}
        </Text>
    </Flex>
);

LoadingIndicator.propTypes = {
    message: PropTypes.string.isRequired
};
