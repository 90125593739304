import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { train, updateModel } from '../../../redux/actions/models';
import { Flex, Text } from '../../lavender';
import EditableModelName from './../General/EditableModelName';
import * as selectors from '../../../redux/reducers';
import ModelNav from '../ModelNav';

export const Container = props => (
    <Flex
        flexDirection="column"
        bg="white"
        css={{
            position: 'sticky',
            top: 0,
            zIndex: 99,
            boxShadow: `rgba(8, 35, 51, 0.05) 0px 3px 4px`,
            borderRadius: '12px'
        }}
        {...props}
    />
);

const mapDispatchToProps = {
    train,
    updateModel
};

const mapStateToProps = state => ({
    training: selectors.getActiveModelTrainingStatus(state),
    testing: selectors.getActiveModelTestingStatus(state)
});

class ModelHeader extends Component {
    render() {
        const { name, long_name } = this.props.model || {};
        const { domainId, modelType, modelName } = this.props.match.params;
        const { update, types } = this.props.schema || {};

        return (
            <Container>
                <Flex alignItems="top">
                    {types && update && (
                        <EditableModelName
                            value={long_name || name}
                            domainId={domainId}
                            modelName={modelName}
                            modelType={modelType}
                            schema={update && update[modelType]}
                            types={types}
                            onSave={payload => {
                                this.props.updateModel({
                                    ...this.props.match.params,
                                    payload
                                });
                            }}
                        />
                    )}
                    <Flex width={0.21} alignItems="bottom" ml="auto" mr={4}>
                        <Text mx={3} my="auto" fontSize={3} fontWeight="xbold">
                            {types && types[modelType]}
                        </Text>
                    </Flex>
                </Flex>
                <ModelNav model={this.props.model} />
            </Container>
        );
    }
}

ModelHeader.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string,
            modelType: PropTypes.string,
            modelName: PropTypes.string
        })
    }),
    model: PropTypes.shape({
        threshold: PropTypes.number,
        long_name: PropTypes.string,
        name: PropTypes.string,
        training_stamp: PropTypes.string,
        model_group: PropTypes.string
    }),
    schema: PropTypes.shape({
        update: PropTypes.object
    }),
    updateModel: PropTypes.func
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ModelHeader)
);
