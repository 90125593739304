import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Flex, Text } from '../lavender';
import { version } from '../../../package.json';

const Footer = ({ theme }) => (
    <Flex
        p={20}
        css={{
            borderTop: `3px dotted ${theme.colors.brand[0]}`,
            boxShadow: `rgba(8, 35, 51, 0.05) 3px 3px 6px`
        }}
    >
        <Text color="#717e83" fontSize={3}>
            &copy;&nbsp; {new Date().getFullYear()} Feersum Lasso — Made with
            💖in Johannesburg, South Africa.
        </Text>
        <Text ml="auto" mr={1} color="#717e83" fontSize={3}>
            v{version}
        </Text>
    </Flex>
);

Footer.propTypes = {
    theme: PropTypes.object.isRequired
};

export default withTheme(Footer);
