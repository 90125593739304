import styled from 'styled-components';
import { lighten } from 'polished';
import {
    themeGet,
    space,
    width,
    fontSize,
    color,
    borders,
    borderRadius,
    borderColor
} from 'styled-system';

const css = props => props.css;

export const error = props => {
    const errorColor = themeGet('colors.support.0', 'red')(props);
    return (
        props.error && {
            borderColor: errorColor,
            '&:hover': {
                borderColor: errorColor
            },
            '&:focus': {
                borderColor: errorColor,
                boxShadow: 0
            }
        }
    );
};

export const defaultStyles = props => ({
    autocomplete: 'off',
    lineHeight: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 500,
    display: 'inline-block',
    verticalAlign: 'middle',
    color: themeGet('colors.text.1', 'gray')(props),
    border: `2px solid ${themeGet('colors.ui.2', 'gray')(props)}`,
    appearance: 'none',
    '&:focus': {
        outline: 'none',
        border: `2px solid ${themeGet('colors.brand.0', 'black')(props)}`
    },
    '&:disabled': {
        opacity: 1 / 4
    },
    '::placeholder': {
        color: themeGet('colors.text.2', 'blue')(props)
    }
});

export const focus = props => ({
    borderWidth: '3px',
    '&:hover': {
        backgroundColor: lighten(
            0.97,
            themeGet('colors.brand.0', 'black')(props)
        )
    },
    '&:focus': {
        borderColor: themeGet('colors.support.1', 'green')(props),
        borderWidth: '3px'
    }
});

export const Input = styled('input')(
    defaultStyles,
    space,
    borders,
    borderColor,
    borderRadius,
    width,
    fontSize,
    color,
    css,
    error,
    focus
);

Input.propTypes = {
    ...space.propTypes,
    ...width.propTypes,
    ...fontSize.propTypes,
    ...color.propTypes,
    ...borders.propTypes,
    ...borderColor.propTypes,
    ...borderRadius.propTypes
};

Input.defaultProps = {
    borderRadius: 6,
    px: 3,
    py: 2,
    m: 0,
    bg: 'white',
    fontSize: 3,
    width: 1
};

// export const Input = sys(
//     {
//         is: 'input',
//         type: 'text',
//         autoComplete: 'off',
//         fontSize: 2,
//         lineHeight: 'inherit',
//         px: 3,
//         py: 2,
//         m: 0,
//         width: 1,
//         border: 0,
//         borderRadius: 6,
//         bg: 'white',
//         blacklist: ['error', 'borderRadius', 'lineHeight', 'boxShadow']
//     },
//     props => ({
//         fontFamily: 'inherit',
//         fontWeight: 500,
//         display: 'inline-block',
//         verticalAlign: 'middle',
//         color: themeGet('colors.text.1', 'gray')(props),
//         border: `2px solid ${themeGet('colors.ui.2', 'gray')(props)}`,
//         appearance: 'none',
//         '&:focus': {
//             outline: 'none',
//             border: `2px solid ${themeGet('colors.brand.0', 'black')(props)}`
//         },
//         '&:disabled': {
//             opacity: 1 / 4
//         },
//         '::placeholder': {
//             color: themeGet('colors.text.2', 'gray')(props)
//         }
//     }),
//     error,
//     props => props.css
// );

Input.displayName = 'Input';

export default Input;
