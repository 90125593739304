import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import Fuse from 'fuse.js';
import * as actions from '../../redux/actions/models';
import { Flex, Box, Text, Cluster, SelectBox, CheckBox } from '../lavender';
import { LoadingIndicator } from '../Loading';
import { SearchInput } from '../SearchInput';
import NavItem from './NavItem';
import ModelGrid from './ModelGrid';
import Header from '../Headers/LoggedIn';
import { stringCompare } from '../../utils/stringCompare';
import { CreateModelDialog } from '../Dialogs';
import AddCard from '../Domains/Card/AddCard';
import * as selectors from '../../redux/reducers';
import {
    baseFuseConfig,
    modelTypeNavItems,
    sortingOptions
} from '../../config';
import { domainFromParams } from '../../utils';
import { styles } from '../Model/SamplesView/selectStyles';

baseFuseConfig['keys'] = ['name', 'long_name', 'desc', 'model_type'];

const Container = ({ ...props }) => (
    <Box
        css={{
            position: 'sticky',
            top: 0,
            zIndex: 99,
            boxShadow: `rgba(8, 35, 51, 0.05) 0px 3px 4px`,
            borderRadius: '12px',
            backgroundColor: 'white'
        }}
        mx={4}
        my={2}
        flexDirection={['column', 'column', 'column', 'row']}
        {...props}
    />
);

const mapStateToProps = (state, prevProps) => ({
    // Returning an array of objects for Fuse.js to index on.
    models: Object.values(selectors.getAllModelsByIds(state)),
    activeDomain: selectors.getActiveDomain(state),
    domain: selectors.getDomainById(state, domainFromParams(prevProps)),
    isLoading: selectors.getIsLoadingDomains(state),
    types: selectors.getSchemaTypes(state)
});

const mapDispatchToProps = dispatch => ({
    onLoad: id => dispatch(actions.getAllModels(id))
});

class Models extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterString: '',
            sort: 'default',
            modelTypes: null,
            trashed: false,
            modelGroup: 'all'
        };
        this.changeModel = this.changeModel.bind(this);
    }

    componentDidMount() {
        const { domainId } = this.props.match.params;
        this.props.onLoad(domainId);
    }

    componentDidUpdate(prevProps) {
        const { domainId } = this.props.match.params;
        if (domainId !== prevProps.match.params.domainId) {
            this.props.onLoad(domainId);
        }
    }

    setSearchTerm = debounce(filterString => {
        this.setState({
            filterString
        });
    }, 250);

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.setState({ filterString: e.target.value });
        }
    };

    onKeyDown = e => {
        if (e.keyCode === 8 && e.target.value.length <= 1) {
            this.setState({ filterString: '' });
        }
    };

    onChange = e => {
        this.setSearchTerm(e.target.value);
    };

    handleCheckedChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    changeModel(item) {
        const { modelTypes, key } = item;
        this.setState({
            modelTypes,
            modelGroup: key
        });
    }

    changeSort = value => {
        this.setState({
            sort: value.value
        });
    };

    render() {
        const {
            filterString,
            modelTypes,
            sort,
            trashed,
            modelGroup
        } = this.state;
        const { isLoading, activeDomain } = this.props;

        const models = this.props.models.filter(model => {
            if (model.model_type === 'sentence_encoder') return false;
            if (modelTypes) {
                return (
                    modelTypes.indexOf(model.model_type) > -1 &&
                    model.trashed === trashed
                );
            } else {
                return model.trashed === trashed;
            }
        });
        const fuse = new Fuse(models, baseFuseConfig); // "list" is the item array
        const results = filterString ? fuse.search(filterString) : models;
        sort === 'az' && results.sort(stringCompare('long_name'));

        return (
            <Fragment>
                <Container>
                    <Header title="Models" />
                    <Box my={2}>
                        <Cluster gutter="s" alignment="left">
                            {modelTypeNavItems.map((item, i) => (
                                <NavItem
                                    key={item.name + i}
                                    item={item}
                                    group={modelGroup}
                                    onClick={this.changeModel}
                                />
                            ))}
                        </Cluster>
                        <Cluster my={2} gutter="s" alignment="left">
                            <CheckBox
                                checked={this.state.trashed}
                                label="Show Trashed"
                                value="trashed"
                                handleChange={this.handleCheckedChange}
                            />
                            <SelectBox
                                pl={2}
                                width={100}
                                styles={styles}
                                options={sortingOptions}
                                defaultValue={sortingOptions.find(
                                    ({ value }) => value === sort
                                )}
                                placeholder="Sort..."
                                onChange={this.changeSort}
                            />
                            <SearchInput
                                py={1}
                                fontSize={2}
                                placeholder="Search models..."
                                onKeyPress={this.handleKeyPress}
                                onKeyDown={this.onKeyDown}
                                dataCy="search-models"
                                onChange={this.onChange}
                            />
                        </Cluster>
                    </Box>
                </Container>

                {isLoading ? (
                    <LoadingIndicator message="Loading models..." />
                ) : (
                    <Box m={4}>
                        {!models.length ? (
                            <Flex
                                flexDirection="column"
                                justifyContent="center"
                            >
                                {modelGroup !== 'sentiment' && (
                                    <CreateModelDialog
                                        group={modelGroup}
                                        card={
                                            <AddCard text="Create New Model" />
                                        }
                                    />
                                )}
                                <Text
                                    textAlign="center"
                                    my={6}
                                    weight="medium"
                                    fontSize={6}
                                >
                                    {"You don't have any models."}
                                    😢
                                    {" Let's change that!"}
                                </Text>
                            </Flex>
                        ) : (
                            activeDomain && (
                                <ModelGrid
                                    items={results}
                                    group={modelGroup}
                                    domainId={activeDomain.id}
                                />
                            )
                        )}
                    </Box>
                )}
            </Fragment>
        );
    }
}

Models.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired
        })
    }).isRequired,
    types: PropTypes.object,
    deleteModel: PropTypes.func,
    onLoad: PropTypes.func.isRequired,
    activeDomain: PropTypes.object,
    isLoading: PropTypes.bool,
    models: PropTypes.array.isRequired,
    hasWord: PropTypes.func,
    classifierOptions: PropTypes.array
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Models);
