import {
    AUTH_LOGIN_USER_SUCCESS,
    REGISTER,
    USER_LOGOUT,
    TOKEN_EXPIRED,
    GET_TOKEN
} from '../constants/actionTypes';

const defaultState = {
    token: false,
    isAuthenticated: false,
    tokenExpired: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case TOKEN_EXPIRED:
            return {
                ...state,
                tokenExpired: true
            };
        case USER_LOGOUT:
            return {
                ...state,
                token: null,
                currentUser: null,
                isAuthenticated: false
            };
        case AUTH_LOGIN_USER_SUCCESS:
        case REGISTER:
            return {
                ...state,
                token: action.payload.token,
                isAuthenticated: true,
                currentUser: action.payload.user,
                tokenExpired: false
            };
        case GET_TOKEN:
            return {
                ...state,
                token: localStorage.getItem('jwt'),
                isAuthenticated: !!localStorage.getItem('jwt')
            };
        default:
            return state;
    }
};
