import { SHOW_TOAST, HIDE_TOAST } from '../constants/actionTypes';

export default (state = { open: false, message: '' }, action) => {
    switch (action.type) {
        case SHOW_TOAST:
            return {
                ...state,
                open: true,
                message: action.message
            };
        case HIDE_TOAST:
            return {
                ...state,
                open: false,
                message: ''
            };
        default:
            return state;
    }
};
