import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';

import { createResourceReducer, fromResource } from '../utils/reducers';
import auth from './auth';
import toast from './toast';
import { editableDomain } from './domains';
import schema from './schema';
import {
    active,
    result,
    byId,
    allIds,
    languages,
    train,
    tsne,
    languageModels,
    algorithm
} from '../utils/reducers/lasso';

const reducer = combineReducers({
    auth,
    toast,
    schema,
    resources: combineReducers({
        domains: createResourceReducer('domains', {
            active: active('domains'),
            editableDomain
        }),
        models: createResourceReducer('models', {
            active: active('models'),
            result: result('models'),
            languages: languages('models'),
            tsne: tsne('models'),
            train: train('models'),
            languageModels: languageModels('models'),
            clsfr_algorithm: algorithm('models')
        }),
        samples: createResourceReducer('samples', {
            byId: byId('samples'),
            allIds: allIds('samples')
        }),
        similarWords: createResourceReducer('similarWords', {
            byId: byId('similarWords'),
            allIds: allIds('similarWords')
        })
    }),
    router: routerReducer,
    // you have to pass formReducer under 'form' key,
    // for custom keys look up the docs for 'getFormState'
    form: formReducer
});

export default reducer;

// ***** Selectors ***** //
// Auth
export const getAuthToken = ({ auth }) => auth.token;
export const getCurrentUser = ({ auth }) => auth.currentUser;
export const getTokenExpired = ({ auth }) => auth.tokenExpired;
export const getAuthenticated = ({ auth }) => auth.isAuthenticated;

// Active Domain Entries
export const getAllEntriesByIds = ({ resources: { entries } }) =>
    fromResource.getAll(entries);
export const getAllEntriesIds = ({ resources: { entries } }) =>
    fromResource.get('allIds')(entries);
export const getEntryById = ({ resources: { entries } }, id) =>
    fromResource.get('byId')(entries, id);
export const getIsLoadingEntries = ({ resources: { entries } }) =>
    fromResource.isLoading(entries);

// Domains
export const getAllDomainsByIds = ({ resources: { domains } }) =>
    fromResource.getAll(domains);
export const getAllDomainsIds = ({ resources: { domains } }) =>
    fromResource.get('allIds')(domains);
export const getDomainById = ({ resources: { domains } }, id) =>
    fromResource.get('byId')(domains, id);
export const getActiveDomain = ({ resources: { domains } }) =>
    fromResource.get('byId')(domains, domains.active);
export const getIsLoadingDomains = ({ resources: { domains } }) =>
    fromResource.isLoading(domains);

// Models
export const getAllModelsByIds = ({ resources: { models } }) =>
    fromResource.getAll(models);
export const getAllModelsIds = ({ resources: { models } }) =>
    fromResource.get('allIds')(models);
export const getModelById = ({ resources: { models } }, id) =>
    fromResource.get('byId')(models, id);
export const getActiveModel = ({ resources: { models } }) => getActive(models);
export const getIsLoadingModels = ({ resources: { models } }) =>
    fromResource.isLoading(models);

const getActive = entity => entity.active;
// Active Model
export const getIsLoadingActiveModel = ({ resources: { models } }) =>
    fromResource.isLoading(models);
export const getActiveModelTrainingStatus = ({ resources: { models } }) =>
    getActive(models).training;
export const getActiveModelTestingStatus = ({ resources: { models } }) =>
    getActive(models).testing;
export const getActiveModelCuration = ({ resources: { models } }) =>
    getActive(models).curation;
export const getActiveModelLanguages = ({ resources: { models } }) =>
    models.languages;
export const getLanguageModels = ({ resources: { models } }) =>
    models.languageModels;
export const getActiveModelClassifyResult = ({ resources: { models } }) =>
    models.result;
export const getActiveModelCMLabels = ({ resources: { models } }) =>
    getActive(models).cm_labels;
export const getActiveModelCMLabelsLookup = ({ resources: { models } }) =>
    getActive(models).cm_labels_lookup;
export const getActiveModelClassifierType = ({ resources: { models } }) =>
    getActive(models).classifier_type;
export const getActiveModelTrainingCMMatrix = ({ resources: { models } }) =>
    getActive(models).training_cm_array;
export const getActiveModelTestingCMMatrix = ({ resources: { models } }) =>
    getActive(models).testing_cm_array;
export const getActiveModelModifiedTrainingCMMatrix = ({
    resources: { models }
}) => getActive(models).training_confusion_matrix;
export const getActiveModelModifiedTestingCMMatrix = ({
    resources: { models }
}) => getActive(models).testing_confusion_matrix;
export const getActiveModelTsne = ({ resources: { models } }) => models.tsne;
export const getActiveModelAlgorithm = ({ resources: { models } }) =>
    models.clsfr_algorithm;

// Active Model Samples
export const getAllSamplesByIds = ({ resources: { samples } }) =>
    fromResource.getAll(samples);
export const getAllSamplesIds = ({ resources: { samples } }) =>
    fromResource.get('allIds')(samples);
export const getSampleById = ({ resources: { samples } }, id) =>
    fromResource.get('byId')(samples, id);
export const getIsLoadingSamples = ({ resources: { samples } }) =>
    fromResource.isLoading(samples);

// Sample Texts
export const getSampleTextById = ({ resources: { texts } }, id) =>
    fromResource.get('byId')(texts, id);
export const getIsLoadingSampleTexts = ({ resources: { texts } }) =>
    fromResource.isLoading(texts);

// Similar Words
export const getAllSimilarWordsById = ({ resources: { similarWords } }) =>
    fromResource.getAll(similarWords);
export const getAllSimilarWordsIds = ({ resources: { similarWords } }) =>
    fromResource.get('allIds')(similarWords);
export const getSimilarWordById = ({ resources: { similarWords } }, id) =>
    fromResource.get('byId')(similarWords, id);

// Schema
export const getSchema = ({ schema }) => schema;
export const getSchemaTypes = ({ schema }) => schema.types;
export const getSchemaLabels = ({ schema }) => schema.labels;
export const getIsLoadingSchema = ({ schema }) => schema.loading;

// Toasts
export const getToastOpen = ({ toast }) => toast.open;
export const getToastMessage = ({ toast }) => toast.message;

// Language
export const getLanguage = ({ language }) => language;
export const getLanguages = ({ schema }) => schema.languages;
