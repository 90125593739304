import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { EditableDiv } from '../../../EditableDiv';
import { addSamples } from '../../../../redux/actions/samples';
import * as selectors from '../../../../redux/reducers';
import AddSampleButton from '../../SamplesView/Samples/AddSample/AddSampleButton';
import HoverCard from '../../SamplesView/Samples/AddSample/HoverCard';
import AddSampleCard from '../../SamplesView/Samples/AddSample/AddSampleCard';

const mapStateToProps = state => ({
    samples: selectors.getAllSamplesByIds(state)
});

const mapDispatchToProps = dispatch => ({
    addSample: payload => dispatch(addSamples(payload))
});

class AddSynonymEntity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: '',
            synonyms: [],
            focused: false,
            labelEl: null,
            synonymsEl: null
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onHoverOver = this.onHoverOver.bind(this);
        this.onHoverOut = this.onHoverOut.bind(this);
        this.valid = this.valid.bind(this);
        this.onInputLabel = this.onInputLabel.bind(this);
        this.onInputSynonyms = this.onInputSynonyms.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
    }

    onHoverOver() {
        this.setState({ hover: true });
    }

    onHoverOut() {
        this.setState({ hover: false });
    }

    valid() {
        const { samples } = this.props;
        const { label, synonyms } = this.state;
        if (label === '' || !synonyms.length) return false;
        if (!samples[label]) return true;
    }

    onInputLabel(event) {
        const label = event.target.innerText.trim();
        this.setState({ label, labelEl: event.target });
    }

    onInputSynonyms = event => {
        const synonyms = event.target.innerText.trim();
        this.setState({ synonyms, synonymsEl: event.target });
    };

    onBlur() {
        this.setState({ focused: false });
    }

    onFocus() {
        this.setState({ focused: true });
    }

    handleAdd() {
        const { label, labelEl, synonyms, synonymsEl } = this.state;
        const { sampleType } = this.props;
        if (this.valid()) {
            const sample = {
                entity_list: [
                    {
                        entity: label,
                        syn_set: synonyms.split(',').map(word => word.trim())
                    }
                ],
                type: sampleType
            };
            this.props
                .addSample({
                    ...this.props.match.params,
                    samples: [sample]
                })
                .then(() => {
                    labelEl.innerText = '';
                    synonymsEl.innerText = '';
                    labelEl.focused = false;
                    synonymsEl.focused = false;
                });
            this.onBlur();
            this.setState({ label: '', synonyms: [] });
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleAdd();
        }
    }

    render() {
        const { focused, label, synonyms } = this.state;
        return (
            <AddSampleCard focused={focused}>
                <HoverCard
                    onHoverOver={this.onHoverOver}
                    onHoverOut={this.onHoverOut}
                >
                    <EditableDiv
                        placeholder="Type your entity here..."
                        onInput={this.onInputLabel}
                        onKeyPress={this.handleKeyPress}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        fontSize={3}
                        py={25}
                        pl={4}
                        data-cy={'type-label'}
                    />
                </HoverCard>
                <HoverCard
                    onHoverOver={this.onHoverOver}
                    onHoverOut={this.onHoverOut}
                >
                    <EditableDiv
                        placeholder="Type your synonyms here. E.g blue, green, red..."
                        onInput={this.onInputSynonyms}
                        onKeyPress={this.handleKeyPress}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        fontSize={3}
                        py={25}
                        pl={4}
                        data-cy={'type-text'}
                    />
                    {label && synonyms && (
                        <AddSampleButton onClick={this.handleAdd}>
                            Add Sample
                        </AddSampleButton>
                    )}
                </HoverCard>
            </AddSampleCard>
        );
    }
}

AddSynonymEntity.propTypes = {
    label: PropTypes.string,
    samples: PropTypes.object,
    addSample: PropTypes.func.isRequired,
    sampleType: PropTypes.string.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    })
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddSynonymEntity)
);
