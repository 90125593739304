import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { Card } from '../lavender';

const BaseCard = ({ theme: { colors }, ...props }) => (
    <Card
        borderTop={`4px solid ${colors.brand[0]}`}
        css={{
            height: '200px',
            '&:hover': {
                backgroundColor: colors.ui[2],
                boxShadow: '2px 2px 2px 1px #ccc',
                transition: 'box-shadow 0.2s ease-in-out',
                cursor: 'pointer'
            }
        }}
        {...props}
    />
);

BaseCard.propTypes = {
    theme: PropTypes.object.isRequired
};

export default withTheme(BaseCard);
