/**
 * Reference: https://scotch.io/tutorials/build-custom-pagination-with-react
 */
import React, { Component, Fragment } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import range from 'lodash/range';

import { Flex, Box, Text } from '../lavender';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const border = colors => ({
    border: `2px solid ${colors.ui[2]}`,
    borderLeft: `1px solid ${colors.ui[2]}`,
    borderRight: `1px solid ${colors.ui[2]}`,
    '&:hover': {
        backgroundColor: colors.ui[2],
        cursor: 'pointer'
    }
});

const PaginationEdge = withTheme(
    ({
        theme: { colors },
        page,
        pages,
        index,
        currentPage,
        onClick,
        ...props
    }) => (
        <Box
            onClick={onClick}
            css={{
                ...border(colors),
                borderRadius:
                    index === 0
                        ? `4px 0px 0px 4px`
                        : index === pages.length - 1
                        ? `0px 4px 4px 0px`
                        : `none`,
                backgroundColor:
                    page === currentPage ? colors.ui[2] : 'inherit',
                borderLeft: `${index !== 0 ? 1 : 2}px solid ${colors.ui[2]}`,
                borderRight: `${index === pages.length - 1 ? 2 : 1}px solid ${
                    colors.ui[2]
                }`
            }}
            key={page}
            px={3}
            py={2}
            {...props}
        >
            {page}
        </Box>
    )
);

PaginationEdge.propTypes = {
    theme: PropTypes.object,
    page: PropTypes.number,
    pages: PropTypes.array,
    index: PropTypes.number,
    currentPage: PropTypes.number,
    onClick: PropTypes.func
};

const PaginationItem = withTheme(
    ({ theme: { colors }, item, currentPage, onClick, ...props }) => (
        <Box
            onClick={onClick}
            css={{
                ...border(colors),
                backgroundColor: item === currentPage ? colors.ui[2] : 'inherit'
            }}
            key={item}
            px={3}
            py={2}
            {...props}
        >
            {item}
        </Box>
    )
);

PaginationItem.propTypes = {
    item: PropTypes.number,
    currentPage: PropTypes.number,
    onClick: PropTypes.func,
    left: PropTypes.bool,
    right: PropTypes.bool
};

const PaginationArrow = withTheme(
    ({ theme: { colors }, left = false, right = false, onClick, ...props }) => (
        <Box
            css={{
                ...border(colors)
            }}
            onClick={onClick}
            {...props}
        >
            <Text px={3} py={2}>
                {left ? (
                    <span>&laquo;</span>
                ) : right ? (
                    <span>&raquo;</span>
                ) : (
                    <span>&laquo</span>
                )}
            </Text>
        </Box>
    )
);

PaginationArrow.propTypes = {
    onClick: PropTypes.func,
    left: PropTypes.bool,
    right: PropTypes.bool
};

const PaginationBox = withTheme(({ theme: { colors }, children, ...props }) => (
    <Flex
        ml="auto"
        css={{
            backgroundColor: colors.ui[0]
        }}
        {...props}
    >
        {children}
    </Flex>
));

PaginationBox.propTypes = {
    theme: PropTypes.object,
    children: PropTypes.any
};

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
// const range = (from, to, step = 1) => {
//     let i = from;
//     const range = [];

//     while (i <= to) {
//         range.push(i);
//         i += step;
//     }

//     return range;
// };

class Pagination extends Component {
    constructor(props) {
        super(props);
        const { totalRecords = null, pageLimit = 100, neighbours = 0 } = props;

        this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 100;
        this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;

        // neighbours can be: 0, 1 or 2
        this.neighbours =
            typeof neighbours === 'number'
                ? Math.max(0, Math.min(neighbours, 2))
                : 0;

        this.state = {
            currentPage: 1,
            totalPages: Math.ceil(this.totalRecords / this.pageLimit),
            pages: []
        };
    }

    componentDidMount() {
        this.gotoPage(1);
    }

    static getDerivedStateFromProps(props, state) {
        const { totalRecords, pageLimit } = props;

        if (totalRecords && totalRecords !== state.totalRecords) {
            return {
                ...state,
                totalRecords,
                totalPages: Math.ceil(totalRecords / pageLimit)
            };
        }

        return null;
    }

    /**
     * Let's say we have 10 pages and we set neighbours to 2
     * Given that the current page is 6
     * The pagination control will look like the following:
     *
     * (1) < {4 5} [6] {7 8} > (10)
     *
     * (x) => terminal pages: first and last page(always visible)
     * [x] => represents current page
     * {...x} => represents neighbours
     */

    fetchPageNumbers = () => {
        const totalPages = this.state.totalPages;
        const currentPage = this.state.currentPage;
        const neighbours = this.neighbours;

        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = this.neighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - neighbours);
            const endPage = Math.min(totalPages - 1, currentPage + neighbours);

            let pages = range(startPage, endPage + 1);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = totalPages - endPage > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case hasLeftSpill && !hasRightSpill: {
                    const extraPages = range(
                        startPage - spillOffset,
                        startPage
                    );
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6 } > (10)
                case !hasLeftSpill && hasRightSpill: {
                    const extraPages = range(
                        endPage + 1,
                        endPage + spillOffset + 1
                    );
                    pages = [...pages, extraPages, RIGHT_PAGE];
                    break;
                }

                // handle: (1) < { 4 5} [6] {7 8} > (10)
                case hasLeftSpill && hasRightSpill:
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }

            return [1, ...pages, totalPages];
        }
        return range(1, totalPages + 1);
    };

    gotoPage = page => {
        const { onPageChanged = f => f } = this.props;

        const currentPage = Math.max(1, Math.min(page, this.state.totalPages));
        const paginationData = {
            currentPage,
            totalPages: this.state.totalPages,
            pageLimit: this.pageLimit,
            totalRecords: this.totalRecords
        };
        this.setState({ currentPage, ...onPageChanged(paginationData) });
    };

    handleClick = page => evt => {
        evt.preventDefault();
        this.gotoPage(page);
    };

    handleMoveLeft = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - this.neighbours * 2 - 1);
    };

    handleMoveRight = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + this.neighbours * 2 + 1);
    };

    render() {
        if (!this.totalRecords || this.state.totalPages === 1) return null;

        const { currentPage, totalPages } = this.state;
        const pages = this.fetchPageNumbers();

        return (
            <Fragment>
                <Flex>
                    Page&nbsp;
                    <Text weight="bold">{currentPage}</Text>
                    &nbsp;/&nbsp;
                    <Text weight="bold">{totalPages}</Text>
                </Flex>

                <PaginationBox>
                    {pages.map((page, index) => {
                        if (page === LEFT_PAGE)
                            return (
                                <PaginationArrow
                                    left
                                    key={`${index}-left`}
                                    onClick={this.handleMoveLeft}
                                />
                            );

                        if (page === RIGHT_PAGE)
                            return (
                                <PaginationArrow
                                    right
                                    key={`${index}-right`}
                                    onClick={this.handleMoveRight}
                                />
                            );

                        return (
                            <Box key={index}>
                                {page.length ? (
                                    <Flex>
                                        {page.map(item => (
                                            <PaginationItem
                                                key={item}
                                                item={item}
                                                onClick={this.handleClick(item)}
                                            />
                                        ))}
                                    </Flex>
                                ) : (
                                    <PaginationEdge
                                        onClick={this.handleClick(page)}
                                        index={index}
                                        page={page}
                                        pages={pages}
                                        currentPage={currentPage}
                                    />
                                )}
                            </Box>
                        );
                    })}
                </PaginationBox>
            </Fragment>
        );
    }
}

Pagination.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number,
    neighbours: PropTypes.number,
    onPageChanged: PropTypes.func
};

export default Pagination;
