import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTheme } from 'styled-components';

import HoverCard from '../../SamplesView/Samples/AddSample/HoverCard';
import { EditableDiv } from '../../../EditableDiv';
import { ConfirmAdd } from '../../SamplesView/Samples/AddUtterance';
import * as selectors from '../../../../redux/reducers';
import { editSample } from '../../../../redux/actions/samples';
import { validateSynonym } from '../../../../utils';

const mapStateToProps = (state, prevProps) => ({
    sample: selectors.getSampleById(state, prevProps.id)
});

const mapDispatchToProps = dispatch => ({
    addSynonym: payload => dispatch(editSample(payload))
});

class AddSynonym extends Component {
    constructor(props) {
        super(props);
        this.state = {
            synonym: '',
            element: null
        };
        this.onHoverOver = this.onHoverOver.bind(this);
        this.onHoverOut = this.onHoverOut.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onInput = this.onInput.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
    }

    onHoverOver() {
        this.setState({ hover: true });
    }

    onHoverOut() {
        this.setState({ hover: false });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleAdd();
        }
    }

    onInput(event) {
        const synonym = event.target.innerText;
        this.setState({ synonym, element: event.target });
    }

    handleAdd() {
        const { synonym, element } = this.state;
        const {
            sampleType,
            sample,
            match: { params }
        } = this.props;

        if (validateSynonym(synonym, sample)) {
            const entityList = sample.entity_list[0];
            const newSample = [
                {
                    ...sample,
                    entity_list: [
                        {
                            ...entityList,
                            syn_set: Array.from(
                                new Set([synonym, ...entityList.syn_set])
                            )
                        }
                    ],
                    type: sampleType
                }
            ];
            this.props
                .addSynonym({
                    ...params,
                    sample: newSample
                })
                .then(() => (element.innerText = ''));
            this.setState({ utterance: '' });
            element.blur();
        }
    }

    render() {
        const { theme, label } = this.props;
        const { synonym } = this.state;
        return (
            <HoverCard
                onHoverOver={this.onHoverOver}
                onHoverOut={this.onHoverOut}
                theme={theme}
                pl={3}
            >
                <EditableDiv
                    placeholder="Type your synonym here..."
                    onInput={this.onInput}
                    onKeyPress={this.handleKeyPress}
                    fontSize={3}
                    py={25}
                    pl={40}
                    data-cy={`type-${label}-text`}
                />
                {synonym && (
                    <ConfirmAdd onClick={this.handleAdd}>
                        Add Synonym
                    </ConfirmAdd>
                )}
            </HoverCard>
        );
    }
}

AddSynonym.propTypes = {
    sample: PropTypes.object,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    sampleType: PropTypes.string.isRequired,
    addSynonym: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    })
};

export default withRouter(
    withTheme(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddSynonym)
    )
);
