import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';

import { Button, Input, Icon, Box, Text, Flex, Error } from '../../../lavender';

const mapDispatchToProps = {
    goBack
};

class EditableModelName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: { name: this.props.value },
            editing: false,
            saved: false
        };
        this.toggleEditing = this.toggleEditing.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.validate = this.validate.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        const actions = Object.keys(this.props.schema || []);

        actions.length &&
            this.setState({
                formData: {
                    ...actions.reduce((a, b) => {
                        a[b] = '';
                        return a;
                    }, {})
                }
            });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    toggleEditing() {
        this.setState({
            editing: !this.state.editing,
            formData: { name: this.props.value }
        });
    }

    changeHandler({ target: { value } }) {
        this.setState({
            formData: { name: value }
        });
    }

    validate() {
        if (this.isValid()) {
            this.setState({ saved: false });
            this.props.onSave && this.props.onSave(this.state.formData);
            this.toggleEditing();
        } else {
            this.setState({ saved: true });
        }
    }

    isValid() {
        return !!this.state.formData.name;
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            this.isValid()
        ) {
            this.toggleEditing();
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.validate();
        }
    }

    render() {
        const { name } = this.state.formData;
        const { saved, editing } = this.state;
        const { value, modelType, types, goBack } = this.props;
        return (
            <Box>
                {editing ? (
                    <Flex ref={this.setWrapperRef}>
                        <Input
                            error={!name && saved}
                            name="name"
                            value={name}
                            onChange={this.changeHandler}
                            placeholder="Enter a name..."
                            onKeyPress={this.handleKeyPress}
                        />
                        {!name && saved && (
                            <Error message="This field is required." />
                        )}
                        <Flex mx={1}>
                            <Button
                                mx={1}
                                label="Save"
                                size="s"
                                secondary
                                onClick={this.validate}
                            />
                            <Button
                                mx={1}
                                label="Cancel"
                                size="s"
                                secondary
                                warning
                                onClick={this.toggleEditing}
                            />
                        </Flex>
                    </Flex>
                ) : (
                    <Fragment>
                        <Flex alignItems="center">
                            <Icon
                                fontWeight="xbold"
                                size={6}
                                name="chevron"
                                left
                                onClick={goBack}
                                data-cy="back-from-model-detail"
                            />
                            <Text
                                mx={2}
                                fontSize={7}
                                fontWeight="bold"
                                onClick={this.toggleEditing}
                                data-cy="model-name"
                            >
                                {value || '[no name]'}
                            </Text>
                        </Flex>
                        <Text ml={40}>
                            {types && types.length > 0 && types[modelType]}
                        </Text>
                    </Fragment>
                )}
            </Box>
        );
    }
}

EditableModelName.propTypes = {
    value: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    domainId: PropTypes.string.isRequired,
    modelType: PropTypes.string.isRequired,
    goBack: PropTypes.func.isRequired,
    modelName: PropTypes.string.isRequired,
    types: PropTypes.object.isRequired,
    schema: PropTypes.object
};

export default connect(
    null,
    mapDispatchToProps
)(EditableModelName);
