import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { Button as Base } from 'rebass';

import { caps } from '../utils';

const size = props => {
    switch (props.size) {
        case 'xxs':
            return {
                fontSize: `${props.theme.fontSizes[0]}px`,
                padding: '2px 6px'
            };
        case 'xs':
            return {
                fontSize: `${props.theme.fontSizes[0]}px`,
                padding: '4px 8px'
            };
        case 's':
            return {
                fontSize: `${props.theme.fontSizes[0]}px`,
                padding: '8px 15px'
            };
        case 'm':
            return {
                fontSize: `${props.theme.fontSizes[1]}px`,
                padding: '10px 18px'
            };
        case 'l':
            return {
                fontSize: `${props.theme.fontSizes[2]}px`,
                padding: '12px 22px'
            };
        case 'xl':
            return {
                fontSize: `${props.theme.fontSizes[3]}px`,
                padding: '12px 26px'
            };
        default:
            return {
                fontSize: `${props.theme.fontSizes[1]}px`,
                padding: '8px 15px'
            };
    }
};

const whichBrand = props => {
    if (props.primary) return 0;
    if (props.secondary) return 1;
    if (props.tertiary) return 2;
    return 0;
};

const plain = props =>
    props.plain && {
        borderRadius: '20px',
        textTransform: 'none',
        color: props.theme.colors.text[1],
        '&:hover': {
            backgroundColor: props.theme.colors.ui[2],
            color: props.theme.colors.text[1],
            borderColor: props.theme.colors.ui[2]
        }
    };

const hover = (baseColor, accent, hover, brand) => {
    switch (hover) {
        case 'darken':
            baseColor = darken(0.15, baseColor);
            break;
        case 'lighten':
            baseColor = lighten(0.15, baseColor);
            break;
        case 'invert':
            baseColor = [accent, (accent = baseColor)][0];
            break;
    }
    const colors = {
        color: accent,
        backgroundColor: baseColor,
        borderColor: brand === 0 ? accent : baseColor
    };

    return {
        '&:hover': colors,
        '&:active': colors,
        '&:is-active': colors
    };
};

const color = props => {
    const brand = whichBrand(props);
    let baseColor = props.theme.colors.brand[0];
    let accent = props.theme.colors.ui[0];
    const hoverFunc = props.theme.buttons.hover;

    if (props.warning) {
        baseColor = props.theme.colors.support[0];
    }

    switch (brand) {
        case 0: // Primary
            if (props.plain) {
                accent = baseColor;
                baseColor = 'white';
            }
            break;
        case 1: // Secondary
            baseColor = [accent, (accent = baseColor)][0];
            break;
        case 2: // Tertiary
            baseColor = accent;
            accent = props.theme.colors.brand[2];
            break;
    }

    if (props.plain) {
        baseColor = lighten(0.1, baseColor);
    }

    return {
        color: accent,
        backgroundColor: baseColor,
        borderColor: (props.plain
          ? props.theme.colors.ui[0]
          : brand === 0)
            ? baseColor
            : accent,
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
            boxShadow: `0 0 0 2px ${baseColor}`
        },
        ...hover(baseColor, accent, hoverFunc, brand)
    };
};

const defaultStyles = props => ({
    fontFamily: 'inherit',
    WebkitFontSmoothing: 'subpixel-antialiased',
    display: 'block',
    verticalAlign: 'middle',
    overflow: 'hidden',
    appearance: 'none',
    border: '2px',
    minWidth: 'fit-content',
    borderStyle: 'solid',
    borderColor: 'transparent',
    transition: `all ${props.theme.transitionDelays.medium} ${props.theme.timingFunctions.easeInOut}`,
    '&:disabled': {
        opacity: 1 / 4,
        pointerEvents: 'none',
        ':hover': {
            cursor: 'not-allowed'
        }
    }
});

export const Button = styled(Base)(defaultStyles, color, size, caps, plain);

export default Button;
