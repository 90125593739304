import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SubmissionError, Field as ReduxField, reduxForm } from 'redux-form';
import Dialog from '@material-ui/core/Dialog';

import * as domainActions from '../../../redux/actions/domains';
import renderField from '../../Login/renderField';
import { validateDomain } from '../../../utils/validate';

import { Button, Icon, Text, Flex, Box, Error } from '../../lavender';
import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    ActionButton
} from '../styles';
import * as selectors from '../../../redux/reducers';

const mapStateToProps = state => ({
    isLoading: selectors.getIsLoadingDomains(state)
});

const mapDispatchToProps = dispatch => ({
    createDomain: domain => dispatch(domainActions.create(domain))
});

class NewDomainDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.resetDialog = this.resetDialog.bind(this);
    }

    onSubmit(values) {
        return this.props
            .createDomain({ ...values })
            .then(this.resetDialog)
            .catch(err => {
                if (err.status === 400) {
                    const errors = JSON.parse(err.response.text);
                    throw new SubmissionError(errors);
                }
            });
    }

    toggleDialog() {
        this.setState({ open: !this.state.open });
    }

    resetDialog() {
        const { reset } = this.props;
        this.toggleDialog();
        reset();
    }

    render() {
        const { error, handleSubmit, submitting, card, navbar } = this.props;
        return (
            <Fragment>
                {navbar ? (
                    <Flex onClick={this.toggleDialog} alignItems="center">
                        <Icon name="addCircleOutline" />
                        <Text pl={2} fontSize={2} weight="bold">
                            New domain
                        </Text>
                    </Flex>
                ) : card ? (
                    <Box onClick={this.toggleDialog} data-cy="new-domain">
                        {card}
                    </Box>
                ) : (
                    <Box>
                        <Button
                            label="New domain"
                            icon={<Icon name="plus" />}
                            onClick={this.toggleDialog}
                            data-cy="new-domain"
                        />
                    </Box>
                )}
                <Dialog
                    open={this.state.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    data-cy="new-domain-dialog"
                >
                    <DialogContent>
                        <DialogTitle>Create New Domain</DialogTitle>
                        <DialogContentText>
                            All you need is a client api key, and the service
                            url to create a new domain. Once your domain is
                            created, you can then begin creating machine
                            learning models.
                        </DialogContentText>
                        <form onSubmit={handleSubmit(this.onSubmit)}>
                            <Box>
                                <ReduxField
                                    name="name"
                                    type="text"
                                    component={renderField}
                                    label="Domain name..."
                                />
                                <ReduxField
                                    name="description"
                                    type="text"
                                    component={renderField}
                                    label="Domain description..."
                                />
                                <ReduxField
                                    name="api_key"
                                    type="text"
                                    component={renderField}
                                    label="API Key..."
                                />
                                <ReduxField
                                    name="service"
                                    type="text"
                                    component={renderField}
                                    label="EG. https://nlu.feersum.io"
                                />
                            </Box>
                            {error && <Error message={error} />}

                            <DialogActions>
                                <ActionButton
                                    label="Create"
                                    type="submit"
                                    disabled={submitting}
                                />
                                <ActionButton
                                    plain
                                    label="Cancel"
                                    type="button"
                                    onClick={this.resetDialog}
                                    data-cy="close-dialog"
                                />
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

NewDomainDialog.propTypes = {
    createDomain: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    navbar: PropTypes.bool,
    reset: PropTypes.func,
    card: PropTypes.any
};

let newDomainDialog = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NewDomainDialog)
);

export default reduxForm({
    form: 'NewDomainDialog', // a unique identifier for this form
    validateDomain,
    initialValues: { service: 'https://nlu.feersum.io' }
})(newDomainDialog);
