import superagent from 'superagent';

import { logout } from '../redux/actions/auth';
import BASE_URL from '../utils/config';

const tokenPlugin = req => {
    const token = localStorage.getItem('jwt');
    token && req.set('authorization', `jwt ${token}`);
};

const ApiEntries = {
    // Auth
    authLoginUser: payload =>
        superagent.post(`${BASE_URL}/users/login/`).send(payload),
    authRegister: payload =>
        superagent.post(`${BASE_URL}/registration/`).send(payload),

    // Domains
    getDomains: () => superagent.get(`${BASE_URL}/domain/`).use(tokenPlugin),
    deleteDomain: ({ domainId }) =>
        superagent.delete(`${BASE_URL}/domain/${domainId}/`).use(tokenPlugin),
    editDomain: payload =>
        superagent
            .put(`${BASE_URL}/domain/${payload.id}/`)
            .use(tokenPlugin)
            .send(payload),
    createDomain: payload =>
        superagent
            .post(`${BASE_URL}/domain/`)
            .use(tokenPlugin)
            .send(payload),

    // Dashboard
    getAllModels: id =>
        superagent.get(`${BASE_URL}/models/all/${id}/`).use(tokenPlugin),

    // Models
    createModel: ({ domainId, modelType, payload }) =>
        superagent
            .post(`${BASE_URL}/models/create/${domainId}/${modelType}/`)
            .use(tokenPlugin)
            .send(payload),
    deleteModel: ({ domainId, modelType, modelName, payload }) =>
        superagent
            .post(
                `${BASE_URL}/models/delete/${domainId}/${modelType}/${modelName}/`
            )
            .use(tokenPlugin)
            .send(payload),
    getModel: ({ domainId, modelType, modelName }) =>
        superagent
            .get(
                `${BASE_URL}/models/detail/${domainId}/${modelType}/${modelName}/`
            )
            .use(tokenPlugin),
    updateModel: ({ domainId, modelType, modelName, payload }) =>
        superagent
            .post(
                `${BASE_URL}/models/update/${domainId}/${modelType}/${modelName}/`
            )
            .use(tokenPlugin)
            .send(payload),

    getSchema: () =>
        superagent.get(`${BASE_URL}/models/schema/`).use(tokenPlugin),
    train: ({
        domainId,
        modelName,
        modelType,
        threshold,
        word_manifold_list,
        language_model_list,
        clsfr_algorithm
    }) =>
        superagent
            .post(
                `${BASE_URL}/models/train/${domainId}/${modelType}/${modelName}/`
            )
            .use(tokenPlugin)
            .send({
                threshold,
                word_manifold_list,
                language_model_list,
                clsfr_algorithm
            }),
    test: ({ domainId, modelName, modelType }) =>
        superagent
            .post(
                `${BASE_URL}/models/test/${domainId}/${modelType}/${modelName}/`
            )
            .use(tokenPlugin)
            .send({}),
    /**
     * Structure of a classify payload
     *   {
     *      text: 'foo'
     *   }
     */
    classify: ({ domainId, modelName, modelType, payload }) =>
        superagent
            .post(
                `${BASE_URL}/models/classify/${domainId}/${modelType}/${modelName}/`
            )
            .use(tokenPlugin)
            .send(payload),

    /**
     * Structure of a sample
     * [
     *   {
     *      text: 'foo',
     *      label: 'bar',
     *      type: 'training/testing'
     *    }
     * ]
     */
    getSamples: ({ domainId, modelName, modelType }) =>
        superagent
            .get(`${BASE_URL}/samples/${domainId}/${modelType}/${modelName}/`)
            .use(tokenPlugin),
    addSamples: ({ domainId, modelName, modelType, samples }) =>
        superagent
            .post(
                `${BASE_URL}/samples/add/${domainId}/${modelType}/${modelName}/`
            )
            .use(tokenPlugin)
            .send(samples),
    editSample: ({ domainId, modelName, modelType, sample }) =>
        superagent
            .post(
                `${BASE_URL}/samples/edit/${domainId}/${modelType}/${modelName}/`
            )
            .use(tokenPlugin)
            .send(sample),
    deleteSamples: ({ domainId, modelName, modelType, sample }) =>
        superagent
            .post(
                `${BASE_URL}/samples/delete/${domainId}/${modelType}/${modelName}/`
            )
            .use(tokenPlugin)
            .send(sample),
    deleteAllSamples: ({ domainId, modelName, modelType, sample_type }) =>
        superagent
            .delete(
                `${BASE_URL}/samples/delete_all/${domainId}/${modelType}/${modelName}/${sample_type}/`
            )
            .use(tokenPlugin),
    upload: ({ domainId, modelName, modelType, sampleType, file }) =>
        superagent
            .put(
                `${BASE_URL}/samples/upload/${domainId}/${modelType}/${modelName}/${sampleType}/${file.name}/`
            )
            .use(tokenPlugin)
            .send(file),
    /**
     * Structure of a curate payload.
     * {
     *  "matrix_name": "train",
     *  "predicted_label": "question",
     *  "true_label": "greeting"
     * }
     */
    curate: ({ domainId, modelType, modelName, payload }) =>
        superagent
            .post(
                `${BASE_URL}/samples/curate/${domainId}/${modelType}/${modelName}/`
            )
            .use(tokenPlugin)
            .send(payload),
    tsne: ({ domainId, modelType, modelName, payload }) =>
        superagent
            .post(
                `${BASE_URL}/models/tsne/${domainId}/${modelType}/${modelName}/`
            )
            .use(tokenPlugin)
            .send(payload),
    language: ({ payload }) =>
        superagent
            .post(`${BASE_URL}/models/language/`)
            .use(tokenPlugin)
            .send(payload)
};

const Api = dispatch =>
    Object.entries(ApiEntries)
        .map(([key, fn]) => ({
            [key]: (...args) =>
                fn(...args).catch(reject => {
                    if (reject.status === 401) {
                        dispatch(logout());
                    }
                    throw reject;
                })
        }))
        .reduce((a, b) => Object.assign({}, a, b));

export default Api;
