import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';

const ListItems = ({
    items = [],
    // showFlagged = false,
    Inner,
    ...innerProps
}) => (
    <Fragment>
        {items.map(
            (item, i) => (
                //            showFlagged ? (
                //                item.flagged && (
                //                    <Inner key={cuid()} item={item} index={i} {...innerProps} />
                //                )
                //            ) : (
                <Inner key={cuid()} item={item} index={i} {...innerProps} />
            )
            //            )
        )}
    </Fragment>
);

ListItems.propTypes = {
    innerProps: PropTypes.any,
    items: PropTypes.array,
    Inner: PropTypes.func.isRequired
    // showFlagged: PropTypes.bool
};

export default ListItems;
