import * as customActions from '../utils/actions/lasso';
import { toast } from './toast';
import { clear } from '../utils/actions/resource';

export const addSimilarWord = ({ word }) => dispatch => {
    dispatch(
        customActions.addSimilarWord('similarWords')({
            word
        })
    );
    dispatch(toast(`You added the similar word ${word}.🎉`));
};

export const editSimilarWord = ({ word }) => dispatch => {
    dispatch(
        customActions.editSimilarWord('similarWords')({
            oldWord: word[0],
            newWord: word[1]
        })
    );
    dispatch(toast(`You edited a ${word[0]} with ${word[1]}. 🎉`));
};

export const deleteSimilarWord = ({ word }) => dispatch => {
    dispatch(clear('similarWords')(word));
    dispatch(toast(`You deleted the similar word: ${word}. 🎉`));
};
