import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    Tooltip
} from 'recharts';
import randomColor from 'randomcolor';
import { Card, Text } from '../../../../lavender';

const format = data => {
    const labels = data.map(item => item.label);
    let uniq = a => Array.from(new Set(a));
    const keys = uniq(labels);
    return keys.map(key => data.filter(item => item.label === key), data);
};

const customTooltip = value => {
    const payload = value.payload;
    const label = payload.length > 0 && payload[0].payload.label;
    return (
        <Card p={4}>
            <Text>{label}</Text>
        </Card>
    );
};

class Chart extends Component {
    shouldComponentUpdate(nextProps) {
        return nextProps.data !== this.props.data;
    }

    render() {
        const customData = format(this.props.data);
        return (
            <ScatterChart
                width={800}
                height={800}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            >
                <CartesianGrid />
                <XAxis dataKey={'x'} type="number" />
                <YAxis dataKey={'y'} type="number" />
                <ZAxis dataKey={'z'} range={[40, 500]} />
                {customData.map(item => (
                    <Scatter
                        key={item[0].label}
                        name={item[0].label}
                        data={item}
                        fill={randomColor({
                            luminosity: 'dark',
                            format: 'rgba',
                            alpha: 0.5 // e.g. 'rgba(9, 1, 107, 0.5)',
                        })}
                        label="label"
                    />
                ))}
                <Tooltip
                    content={customTooltip}
                    cursor={{ strokeDasharray: '3 3' }}
                />
            </ScatterChart>
        );
    }
}

Chart.displayName = 'Chart';

Chart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
};

export default Chart;
