import React from 'react';
import PropTypes from 'prop-types';
import { sizing, alignProps } from '../theme';
import { Flex, Box } from '../';

const Cluster = ({
    gutter,
    children,
    direction,
    alignment,
    spread,
    stretch,
    ...props
}) => (
    <Flex
        height={stretch && 1}
        width={1}
        justifyContent={alignment && alignProps[alignment]}
        flexDirection={direction}
        alignContent={alignment && alignProps[alignment]}
        alignItems="center"
        {...props}
    >
        {React.Children.map(children, (child, i) => (
            <Box
                flex={spread && '1 0 0'}
                key={child.name + i}
                px={sizing[gutter]}
            >
                {child}
            </Box>
        ))}
    </Flex>
);

Cluster.defaultProps = {
    gutter: 'm',
    direction: 'row'
};

Cluster.propTypes = {
    gutter: PropTypes.oneOf(Object.keys(sizing)),
    children: PropTypes.any.isRequired,
    direction: PropTypes.oneOf(['column', 'row']),
    alignment: PropTypes.oneOf(Object.keys(alignProps)),
    stretch: PropTypes.bool,
    spread: PropTypes.bool
};

export default Cluster;
