import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';

import * as selectors from '../../../redux/reducers';
import { Box, Flex, Text, Icon, Popover } from '../../lavender';
import DeleteDomainDialog from '../../Dialogs/DeleteDomainDialog';
import EditDomainDialog from '../../Dialogs/EditDomainDialog';
import MoreIcon from '../../Icons/MoreIcon';
import BaseCard from '../../Cards';
import { whichService } from '../../../utils';

const mapStateToProps = (state, ownProps) => ({
    item: selectors.getDomainById(state, ownProps.id)
});

export const DomainCard = ({ item }) => (
    <Link to={`/domain/${item.id}`} data-cy={`domain-${item.name}`}>
        <BaseCard>
            <Flex justifyContent="flex-end">
                <Popover
                    key={item.id}
                    menu={[
                        {
                            label: 'Delete',
                            key: 'delete',
                            action: <DeleteDomainDialog domain={item} />
                        },
                        {
                            label: 'Edit',
                            key: 'edit',
                            action: <EditDomainDialog domain={item} />
                        }
                    ]}
                >
                    <MoreIcon horizontal dataCy={`domain-${item.name}-more`} />
                </Popover>
            </Flex>
            <Flex mx={1} flexDirection="column" alignItems="center">
                <Icon color="tomato" size={7} name="layers" />
                <Box m={1}>
                    <Text color="text.0" fontSize={4} fontWeight="bold">
                        {item.name}
                    </Text>
                </Box>
                <Flex m={1}>
                    <Text color="support.3" fontWeight="bold">
                        {whichService(item.service)}
                    </Text>
                </Flex>
                <Box m={1} mb={3}>
                    <Text fontSize={0} fontWeight="bold" caps={true}>
                        Created By {item.owner}
                    </Text>
                </Box>
            </Flex>
        </BaseCard>
    </Link>
);

DomainCard.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        api_key: PropTypes.string.isRequired,
        service: PropTypes.string.isRequired
    }).isRequired,
    theme: PropTypes.object
};

export default withTheme(connect(mapStateToProps)(DomainCard));

export { default as AddCard } from './AddCard';
