import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '../lavender';

export const Highlight = ({ truth, ...props }) => (
    <Box
        css={{
            display: 'inline',
            borderRadius: '6px',
            padding: '5px'
        }}
        bg={truth ? 'support.1' : 'support.2'}
        {...props}
    />
);

Highlight.propTypes = {
    truth: PropTypes.bool
};

export default Highlight;
