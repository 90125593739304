import React from 'react';
import PropTypes from 'prop-types';
import reactStringReplace from 'react-string-replace';

export const Highlight = ({ entityList, text }) => {
    const orgText = text;
    // Each entity in this list contains an index, length and the corresponding
    // string value of the entity. The purpose of this code here is to find and replace
    // all entities that exist in the string with a styled highlight span.
    entityList.forEach(item => {
        const sub = orgText.substring(item.index, item.index + item.len);
        text = reactStringReplace(text, sub, (match, i) => (
            <span
                style={{
                    backgroundColor: '#42d9f4',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    borderRadius: '5px'
                }}
                key={text + i}
            >
                {match}
            </span>
        ));
        return text;
    }, text);
    return <span>{text}</span>;
};

Highlight.propTypes = {
    entityList: PropTypes.array.isRequired,
    text: PropTypes.string.isRequired
};

export default Highlight;
