import React from 'react';
import PropTypes from 'prop-types';

const Truncate = ({ letters, children, ...props }) => {
    return (
        <span {...props}>
            {letters &&
            children &&
            typeof children === 'string' &&
            children.length > letters
                ? children.substring(0, letters) + '...'
                : children}
        </span>
    );
};

Truncate.propTypes = {
    letters: PropTypes.array,
    children: PropTypes.string
};

export default Truncate;
