import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Sample from './Sample';
import AddSample from './AddSample';
import AddCRFSample from './AddCRFSample';
import CRFSample from './CRFSample';

const Samples = ({ samples = [], sampleType, crf = false }) =>
    crf ? (
        <Fragment>
            <AddCRFSample sampleType={sampleType} />
            {samples.map(item => (
                <CRFSample
                    key={item.text}
                    id={item.text}
                    sample={item}
                    sampleType={sampleType}
                />
            ))}
        </Fragment>
    ) : (
        <Fragment>
            <AddSample sampleType={sampleType} />
            {samples.map(item => (
                <Sample
                    key={item.label}
                    id={item.label}
                    texts={item.texts}
                    sampleType={sampleType}
                />
            ))}
        </Fragment>
    );

Samples.propTypes = {
    samples: PropTypes.array.isRequired,
    sampleType: PropTypes.string.isRequired,
    crf: PropTypes.bool
};

export default Samples;
