import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Flex, Box } from '../';
import { lighten, darken } from 'polished';
import theme from '../theme';

const formatGroupLabel = data => {
    return (
        <Flex alignItems="center" justifyContent="space-between" pb={2}>
            <span>{data.label}</span>
            <Box
                bg={theme.colors.brand[1]}
                color={theme.colors.text[0]}
                fontSize={1}
                px={'0.5em'}
                py={'0.16em'}
                textAlign="center"
                css={{ borderRadius: '2em', lineHeight: '1', minWidth: 1 }}
            >
                {data.options.length}
            </Box>
        </Flex>
    );
};

const styles = {
    option: base => ({
        ...base,
        color: 'black',
        backgroundColor: 'transparent',
        padding: 10,
        borderRadius: '8px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: lighten(0.1, theme.colors.brand[1])
        },
        '&:active': {
            backgroundColor: lighten(0.1, theme.colors.brand[1])
        }
    }),
    placeholder: base => ({
        ...base,
        color: 'white',
        fontSize: theme.fontSizes[1],
        fontWeight: theme.fontWeights.bold
    }),
    loadingIndicator: base => ({
        ...base,
        color: 'white'
    }),
    indicatorSeparator: () => ({
        color: 'transparent'
    }),
    dropdownIndicator: base => ({
        ...base,
        color: 'white',
        '&:hover': {
            color: theme.colors.brand[0],
            backgroundColor: 'white',
            borderRadius: '0px 5px 5px 0px'
        }
    }),
    control: base => ({
        ...base,
        backgroundColor: 'transparent',
        borderRadius: '10px',
        borderColor: 'white',
        border: '2px solid white',
        cursor: 'pointer',
        boxShadow: 0,
        '&:hover': {
            backgroundColor: darken(0.05, theme.colors.brand[0])
        }
    }),
    menu: base => ({
        ...base,
        borderRadius: '8px',
        fontSize: theme.fontSizes[2]
    }),
    menuList: base => ({ ...base }),
    input: base => ({
        ...base,
        backgroundColor: 'transparent',
        color: 'white',
        fontSize: theme.fontSizes[2],
        fontWeight: theme.fontWeights.bold
    }),
    selectContainer: base => ({
        ...base,
        backgroundColor: 'transparent',
        border: '2px solid white'
    }),
    container: base => ({
        ...base,
        backgroundColor: theme.colors.brand[0],
        borderRadius: '10px'
    }),
    singleValue: (base, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
            ...base,
            opacity,
            transition,
            color: 'white',
            fontWeight: 'bold'
        };
    }
};

class SelectBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: ''
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        if (value.value === 'button') {
            return;
        }
        this.props.onChange(value);
    }

    render() {
        const {
            placeholder,
            options,
            disabled,
            clearable,
            MenuList,
            defaultValue,
            isLoading,
            classNamePrefix,
            id,
            ...props
        } = this.props;
        const components = MenuList && { MenuList };

        return (
            <Box {...props}>
                <Select
                    options={options}
                    styles={styles}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    onChange={this.onChange}
                    isDisabled={disabled}
                    isSearchable={true}
                    isClearable={clearable}
                    isLoading={isLoading}
                    classNamePrefix={classNamePrefix}
                    components={components}
                    formatGroupLabel={formatGroupLabel}
                    id={id}
                    {...props}
                />
            </Box>
        );
    }
}

const optionsProps = PropTypes.arrayOf(PropTypes.any);

SelectBox.propTypes = {
    inline: PropTypes.bool,
    multiple: PropTypes.bool,
    onSearch: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    options: optionsProps,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    id: PropTypes.string,
    classNamePrefix: PropTypes.string,
    isLoading: PropTypes.bool,
    defaultValue: PropTypes.object,
    MenuList: PropTypes.array
};

export default SelectBox;
