import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Flex } from '../../../../../lavender';

const HoverCard = ({
    onHoverOver,
    onHoverOut,
    theme: { colors },
    ...props
}) => (
    <Flex
        alignItems="center"
        justifyContent="space-between"
        onMouseOver={onHoverOver}
        onMouseOut={onHoverOut}
        css={{
            borderBottom: `1px solid ${colors.ui[1]}`,
            '&:hover': {
                backgroundColor: colors.ui[1]
            }
        }}
        {...props}
    />
);

HoverCard.propTypes = {
    onHoverOver: PropTypes.func.isRequired,
    onHoverOut: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired
};

export default withTheme(HoverCard);
