import { colors } from './colors';

export const breakpoints = [32, 48, 64, 80].map(n => n + 'em');

export const space = [0, 4, 8, 16, 32, 64, 128];

export const sizing = {
    xxs: 0,
    xs: 1,
    s: 2,
    m: 3,
    l: 4,
    xl: 5,
    xxl: 6
};

export const alignProps = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
    between: 'space-between',
    around: 'space-around',
    even: 'space-evenly'
};

export const fontSizes = [12, 14, 16, 18, 20, 24, 32, 40, 48, 64, 72, 96];

export const fontWeights = {
    thin: 100,
    xlight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    xbold: 800,
    black: 900
};

export const lineHeights = {
    standard: 1.5,
    headers: 1.25
};

export const bodyCopy = {
    standard: 14,
    reading: 16
};

// Characters line length
export const lineLength = [52, 78];

export const radii = [0, 2, 4];

export const font = 'system-ui, sans-serif';

export const shadows = [
    'none',
    `inset 0 0 0 1px ${colors.ui[1]}`,
    `inset 0 0 0 1px ${colors.ui[1]}, 0 0 4px ${colors.ui[1]}`
];

// animation duration
export const duration = {
    fast: `150ms`,
    normal: `300ms`,
    slow: `450ms`,
    slowest: `600ms`
};

// animation easing curves
const easeInOut = 'cubic-bezier(0.5, 0, 0.25, 1)';
const easeOut = 'cubic-bezier(0, 0, 0.25, 1)';
const easeIn = 'cubic-bezier(0.5, 0, 1, 1)';

const timingFunctions = {
    easeInOut,
    easeOut,
    easeIn
};

// animation delay
const transitionDelays = {
    small: '60ms',
    medium: '160ms',
    large: '260ms',
    xLarge: '360ms'
};

const buttons = {
    hover: 'invert' // invert, darken or lighten
};

export const variantTypes = [
    'primary',
    'secondary',
    'tertiary',
    'warning',
    'plain'
];

const variants = {
    buttons: {
        primary: {
            color: colors.ui[0],
            backgroundColor: colors.brand[0],
            borderColor: colors.brand[0],
            '&:hover': {
                color: colors.brand[0],
                backgroundColor: 'transparent',
                borderColor: colors.brand[0]
            }
        },
        secondary: {
            color: colors.ui[0],
            backgroundColor: colors.brand[1],
            borderColor: colors.brand[1],
            '&:hover': {
                color: colors.brand[1],
                backgroundColor: 'transparent',
                borderColor: colors.brand[1]
            }
        },
        tertiary: {
            color: colors.ui[0],
            backgroundColor: colors.brand[2],
            borderColor: colors.brand[2],
            '&:hover': {
                color: colors.brand[2],
                backgroundColor: 'transparent',
                borderColor: colors.brand[2]
            }
        },
        warning: {
            color: colors.ui[0],
            backgroundColor: colors.support[0],
            borderColor: colors.support[0],
            '&:hover': {
                color: colors.support[0],
                backgroundColor: 'transparent',
                borderColor: colors.support[0]
            }
        },
        plain: {
            color: colors.brand[0],
            backgroundColor: 'transparent',
            borderColor: 'transparent'
        }
    }
};

export { default as colors } from './colors';

export const theme = {
    breakpoints,
    duration,
    timingFunctions,
    transitionDelays,
    space,
    lineHeights,
    fontSizes,
    fontWeights,
    font,
    colors,
    radii,
    shadows,
    buttons,
    variants
};

export default theme;
