import React from 'react';
import { Route } from 'react-router-dom';

import Login from '../Login';
import Register from '../Register';
import LoggedOutHeader from '../Headers/LoggedOut';
import Footer from '../Footer';
import { Flex, Box } from '../lavender';

const UnauthenticatedRoute = () => (
    <Flex flexDirection="column" css={{ minHeight: '100vh' }}>
        <LoggedOutHeader />
        <Box css={{ flex: 1 }}>
            <Flex
                flexDirection="column"
                css={{
                    borderRadius: '8px',
                    boxShadow: `rgba(8, 35, 51, 0.05) 0px 2px 4px 1px`
                }}
                width={[1, 3 / 4, 1 / 2, 1 / 3]}
                bg="white"
                m={[0, '2rem auto', '4rem auto']}
            >
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
            </Flex>
        </Box>
        <Footer />
    </Flex>
);

export default UnauthenticatedRoute;
