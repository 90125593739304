import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { Flex, Box, Text, Icon } from '../lavender';
import DeleteIcon from '../Icons/DeleteIcon';
import { EditableDiv } from '../EditableDiv';
import Highlight from '../Model/SamplesView/Samples/Highlight';

const SampleCard = ({
    styleProps,
    edited,
    colors,
    children,
    onMouseOver,
    onMouseOut,
    name,
    ...props
}) => (
    <Flex
        alignItems="center"
        css={{
            border: edited
                ? `4px solid ${colors.support[2]}`
                : '4px solid transparent',
            borderRadius: edited && '8px',
            '&:hover': {
                backgroundColor: colors.ui[1]
            }
        }}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        data-cy={name}
        {...styleProps}
        {...props}
    >
        {children}
    </Flex>
);

SampleCard.propTypes = {
    styleProps: PropTypes.object,
    edited: PropTypes.bool,
    colors: PropTypes.object,
    children: PropTypes.any,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    name: PropTypes.string,
    onEdit: PropTypes.func
};

export const Chevron = ({ hover, open, onClick, dataCy, ...props }) => {
    const chevron = open ? { down: 'true' } : { right: 'true' };
    return (
        <Icon
            size={3}
            css={{
                visibility: 'visible' // hover | open ? 'visible' : 'hidden'
            }}
            name="chevron"
            onClick={onClick}
            mx={1}
            data-cy={dataCy}
            {...chevron}
            {...props}
        />
    );
};

Chevron.propTypes = {
    hover: PropTypes.bool,
    open: PropTypes.bool,
    onClick: PropTypes.func,
    dataCy: PropTypes.string
};

export const Save = ({ onClick, ...props }) => (
    <Text
        caps
        mx={2}
        my={1}
        color="support.1"
        weight="bold"
        p={1}
        onClick={onClick}
        css={{
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#11e275',
                color: 'white',
                borderRadius: '8px'
            }
        }}
        {...props}
    >
        Save
    </Text>
);

Save.propTypes = {
    onClick: PropTypes.func
};

export const Cancel = ({ onClick, ...props }) => (
    <Text
        caps
        m={1}
        p={1}
        color="text.1"
        weight="bold"
        onClick={onClick}
        css={{
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#dfe3e6',
                borderRadius: '8px'
            }
        }}
        {...props}
    >
        Cancel
    </Text>
);

Cancel.propTypes = {
    onClick: PropTypes.func
};

class SampleField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            original: props.text,
            text: props.text,
            el: null
        };
    }

    onHoverOver = () => {
        this.setState({ hover: true });
    };

    onHoverOut = () => {
        this.setState({ hover: false });
    };

    onInput = event => {
        const value = event.target.innerText.trim();
        this.setState({ el: event.target, text: value });
    };

    cancel = () => {
        const { el } = this.state;
        const { text } = this.props;
        el.innerText = text;
        this.setState({ el, text, original: text });
        el.blur();
    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const value = e.target.innerText.trim();
            if (value !== this.state.original) {
                this.props.onEdit(value);
            }
        }
    };

    handleEdit = onEdit => {
        const { text, original } = this.state;

        text !== original && onEdit(text);
    };

    render() {
        const { hover } = this.state;
        const {
            onDelete,
            styleProps,
            name,
            expandable,
            open,
            divProps,
            toggle,
            text,
            theme,
            entityList,
            onEdit,
            ...props
        } = this.props;
        const edited = this.state.text !== text;

        return (
            <SampleCard
                onMouseOver={this.onHoverOver}
                onMouseOut={this.onHoverOut}
                edited={edited}
                name={name}
                colors={theme.colors}
                {...styleProps}
                {...props}
            >
                {expandable && (
                    <Chevron
                        onClick={toggle}
                        hover={hover}
                        open={open}
                        dataCy={`chevron-${text}`}
                    />
                )}
                <Box width={1}>
                    <EditableDiv
                        placeholder="Type a new utterance here..."
                        onInput={this.onInput}
                        onKeyPress={this.handleKeyPress}
                        fontSize={3}
                        data-cy={`editable-div-${text}`}
                        {...divProps}
                    >
                        {entityList ? (
                            <Highlight entityList={entityList} text={text} />
                        ) : (
                            text
                        )}
                    </EditableDiv>
                </Box>
                {edited && (
                    <Flex ml="auto" mr={2}>
                        <Save onClick={() => this.handleEdit(onEdit)}>
                            Save
                        </Save>
                        <Cancel onClick={this.cancel}>Cancel</Cancel>
                    </Flex>
                )}
                {!edited && onDelete && (
                    <DeleteIcon hover={hover} onClick={onDelete} />
                )}
            </SampleCard>
        );
    }
}

SampleField.propTypes = {
    text: PropTypes.string.isRequired,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    expandable: PropTypes.bool,
    theme: PropTypes.shape({
        colors: PropTypes.object.isRequired
    }),
    name: PropTypes.string,
    toggle: PropTypes.func,
    open: PropTypes.bool,
    entityList: PropTypes.array,
    styleProps: PropTypes.object,
    divProps: PropTypes.object
};

export default withTheme(SampleField);
