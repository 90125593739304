import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';

import {
    DialogTitle,
    DialogContent,
    DialogActions,
    ActionButton,
    DialogContentText
} from '../styles';

class DeleteAllSamplesDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        this.props.onConfirm();
        this.toggleDialog();
    }

    toggleDialog() {
        this.setState({ open: !this.state.open });
    }

    render() {
        return (
            <Fragment>
                <div onClick={this.toggleDialog}>{this.props.children}</div>
                <Dialog
                    open={this.state.open}
                    onClose={this.toggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogTitle>Delete samples</DialogTitle>
                        <DialogContentText>
                            Are you sure you want to delete the selected
                            samples?
                        </DialogContentText>
                        <DialogActions>
                            <ActionButton
                                warning
                                label="Yes"
                                onClick={this.handleSubmit}
                            />
                            <ActionButton
                                plain
                                label="No"
                                onClick={this.toggleDialog}
                            />
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

DeleteAllSamplesDialog.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    children: PropTypes.any
};
export default DeleteAllSamplesDialog;
