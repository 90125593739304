import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form';

import Api from '../../middleware/api';
import {
    AUTH_LOGIN_USER_SUCCESS,
    USER_LOGOUT,
    REGISTER,
    TOKEN_EXPIRED,
    GET_TOKEN
} from '../constants/actionTypes';
import { getSchema, clearSchema } from './schema';
import { toast } from './toast';

export const authRegisterSuccess = payload => {
    localStorage.setItem('jwt', payload.token);
    return {
        type: REGISTER,
        payload
    };
};

export const authLoginUserSuccess = payload => {
    localStorage.setItem('jwt', payload.token);
    return {
        type: AUTH_LOGIN_USER_SUCCESS,
        payload
    };
};

export const authLogout = () => {
    localStorage.removeItem('jwt');

    return {
        type: USER_LOGOUT
    };
};

export const toggleTokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const getToken = () => ({
    type: GET_TOKEN
});

export const logout = () => dispatch => {
    dispatch(authLogout());
    dispatch(clearSchema());
    dispatch(push('/'));
    dispatch(toggleTokenExpired());
    dispatch(
        toast(
            'Your session has expired. Please log back in to start using Feersum Lasso again.'
        )
    );
};

export const register = payload => dispatch => {
    return Api(dispatch)
        .authRegister(payload)
        .then(({ body }) => {
            dispatch(authRegisterSuccess(body));
            dispatch(push('/'));
            dispatch(toast('Welcome to Feersum Lasso! 🎉'));
        })
        .catch(err => {
            if (err.status === 400) {
                let errors = JSON.parse(err.response.text);
                if (errors.non_field_errors !== undefined) {
                    errors._error = errors.non_field_errors;
                    Reflect.deleteProperty(errors, 'non_field_errors');
                }
                throw new SubmissionError(errors);
            }
        });
};

export const login = payload => dispatch => {
    return Api(dispatch)
        .authLoginUser(payload)
        .then(({ body }) => {
            dispatch(authLoginUserSuccess(body));
            dispatch(getSchema());
            dispatch(push('/'));
            dispatch(toast('Welcome back to Feersum Lasso! 🎉'));
        })
        .catch(err => {
            if (err.status === 400) {
                let errors = JSON.parse(err.response.text);
                if (errors.non_field_errors !== undefined) {
                    errors['_error'] = errors.non_field_errors;
                    Reflect.deleteProperty(errors, 'non_field_errors');
                }
                throw new SubmissionError(errors);
            }
        });
};
