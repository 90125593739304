import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router';

import AuthenticatedRoute from './components/AuthenticatedRoute';
import Toast from './components/Toast';
import { getToken } from './redux/actions/auth';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import * as selectors from './redux/reducers';

const mapStateToProps = state => ({
    tokenExpired: selectors.getTokenExpired(state)
});

const mapDispatchToProps = {
    getToken
};

class App extends Component {
    constructor(props) {
        super(props);
        this.props.getToken();
    }

    render() {
        return (
            <Fragment>
                <Switch>
                    <Route path="/login" component={UnauthenticatedRoute} />
                    <Route path="/register" component={UnauthenticatedRoute} />
                    <AuthenticatedRoute path="/" />
                </Switch>
                <Toast />
            </Fragment>
        );
    }
}

App.propTypes = {
    getToken: PropTypes.func.isRequired
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
