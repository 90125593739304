import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Fuse from 'fuse.js';
import { CSVLink } from 'react-csv';

import { Button, Flex, Popover, Text, Icon, SelectBox } from '../../lavender';
import DeleteAllSamplesDialog from '../../Dialogs/DeleteAllSamplesDialog';
import SearchInput from '../../SearchInput';
import { LoadingIndicator } from '../../Loading';
import * as selectors from '../../../redux/reducers/';
import MoreIcon from '../../Icons/MoreIcon';
import { sortingOptions } from '../../../config';
import { styles } from '../SamplesView/selectStyles';
import { SamplesHeader, SamplesContainer } from '../SamplesView';
import Words from './Words';
import UploadSimilarWordsDialog from '../../Dialogs/UploadSimilarWordsDialog';
import { deleteSimilarWord } from '../../../redux/actions/similarWords';

const fuseOptions = {
    shouldSort: true,
    threshold: 0.3,
    includeMatches: true,
    matchAllTokens: true,
    tokenize: true,
    location: 0,
    distance: 1000,
    maxPatternLength: 1000,
    minMatchCharLength: 4,
    keys: ['word']
};

const mapStateToProps = state => ({
    loading: selectors.getIsLoadingSamples(state),
    // Returning an array of objects for Fuse.js to index on.
    words: selectors.getAllSimilarWordsIds(state)
});

const mapDispatchToProps = dispatch => ({
    removeWord: payload => dispatch(deleteSimilarWord(payload))
});

class SimilarWords extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sampleType: 'training',
            query: '',
            sort: 'default'
        };
        this.handleChange = this.handleChange.bind(this);
        this.deleteAllSamples = this.deleteAllSamples.bind(this);
    }

    updateQuery = query => {
        this.setState({
            query
        });
    };

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    changeSort = value => {
        this.setState({
            sort: value.value
        });
    };

    changeSampleType = value => {
        this.setState({
            sampleType: value.value
        });
    };

    deleteAllSamples() {
        this.props.words.map(
            word =>
                this.props.removeWord({
                    word
                }),
            this
        );
    }

    exportSamplesFormat = () => {
        return this.props.words.reduce((acc, item) => [[item], ...acc], []);
    };

    handleExport = () => {
        this.setState({ isExport: true });
    };

    render() {
        const { query, sort } = this.state;
        const { loading, words } = this.props;
        const { modelName } = this.props.match.params;
        const fuse = new Fuse(words, fuseOptions); // "list" is the item array
        const results = query.trim()
            ? fuse.search(query.trim()).map(({ item }) => words[item])
            : words;
        const exportData = this.exportSamplesFormat(words);
        sort === 'az' && results.sort() && words.sort();

        return (
            <Fragment>
                <SamplesHeader>
                    <Text ml={2} mr="auto" fontSize={3} weight="bold">
                        {words.length} Training Samples
                    </Text>
                    <Flex alignItems="center">
                        <UploadSimilarWordsDialog>
                            <Button
                                plain
                                size="xs"
                                label="Import"
                                icon={<Icon color="text.1" name="import" />}
                            />
                        </UploadSimilarWordsDialog>
                        <CSVLink
                            data={exportData}
                            uFEFF={false}
                            filename={`${modelName}_similar_words.csv`}
                        >
                            <Button
                                plain
                                disabled={!words.length}
                                size="xs"
                                label="Export"
                                icon={<Icon color="text.1" name="export" />}
                            />
                        </CSVLink>
                        <SearchInput
                            width={150}
                            py={1}
                            fontSize={1}
                            dataCy="search-samples"
                            setQuery={this.updateQuery}
                        />
                        <Popover
                            menu={[
                                {
                                    label: 'Delete',
                                    key: 'delete',
                                    action: (
                                        <DeleteAllSamplesDialog
                                            onConfirm={this.deleteAllSamples}
                                        >
                                            <Flex
                                                m={2}
                                                onClick={this.toggleDialog}
                                                alignItems="center"
                                            >
                                                <Icon
                                                    color="support.0"
                                                    size={1}
                                                    name="delete"
                                                />
                                                <Text
                                                    ml={2}
                                                    fontSize={1}
                                                    weight="medium"
                                                >
                                                    Delete all Similar Words
                                                </Text>
                                            </Flex>
                                        </DeleteAllSamplesDialog>
                                    )
                                }
                            ]}
                        >
                            <MoreIcon vertical />
                        </Popover>
                    </Flex>
                </SamplesHeader>
                <Flex>
                    <SelectBox
                        pl={2}
                        width={125}
                        styles={styles}
                        options={sortingOptions}
                        defaultValue={sortingOptions.find(
                            ({ value }) => value === sort
                        )}
                        placeholder="Sort"
                        onChange={this.changeSort}
                    />
                </Flex>
                {loading ? (
                    <LoadingIndicator message="Loading similar words..." />
                ) : (
                    <SamplesContainer>
                        <Words words={results} />
                    </SamplesContainer>
                )}
            </Fragment>
        );
    }
}

SimilarWords.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }).isRequired,
    loading: PropTypes.bool,
    words: PropTypes.array.isRequired,
    removeWord: PropTypes.func
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SimilarWords)
);
