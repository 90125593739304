import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { withTheme } from 'styled-components';

import { Flex, Icon, Input } from '../lavender';

export class SearchInput extends Component {
    state = {
        query: ''
    };

    setSearchTerm = debounce(query => {
        this.props.setQuery(query);
    }, 50);

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.props.setQuery(e.target.value);
        }
    };

    onKeyDown = e => {
        if (e.keyCode === 8 && e.target.value.length <= 1) {
            this.props.setQuery('');
        }
    };

    onChange = e => {
        const { disableKeyPress = false } = this.props;
        !disableKeyPress && this.setSearchTerm(e.target.value);
    };

    render() {
        const { placeholder, dataCy } = this.props;

        return (
            <Flex css={{ position: 'relative' }} width={1}>
                <Icon
                    css={{ position: 'absolute', top: '8px', left: '6px' }}
                    name="search"
                    color="text.2"
                />
                <Input
                    py={10}
                    px={24}
                    bg="white"
                    fontSize={4}
                    placeholder={placeholder || 'Search...'}
                    data-cy={dataCy || 'search-input'}
                    onKeyDown={this.onKeyDown}
                    onKeyPress={this.handleKeyPress}
                    onChange={this.onChange}
                    css={{
                        '&:focus': {
                            transition: 'width 0.4s ease-in-out',
                            width: '300px'
                        }
                    }}
                    {...this.props}
                />
            </Flex>
        );
    }
}

SearchInput.propTypes = {
    placeholder: PropTypes.string,
    dataCy: PropTypes.string,
    setQuery: PropTypes.func,
    disableKeyPress: PropTypes.bool
};

export default withTheme(SearchInput);
