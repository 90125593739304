import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';

const Error = ({ message, ...props }) => {
    return (
        <Text color="support.0" fontSize={2} {...props}>
            {message}
        </Text>
    );
};

Error.propTypes = {
    message: PropTypes.string.isRequired
};

export default Error;
