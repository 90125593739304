import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';

import configureStore from './configureStore';
import history from './history';
import App from './App';
import { ThemeProvider } from './components/lavender';

const store = configureStore();

const Component = (
    <Provider store={store}>
        <ThemeProvider>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/" render={App} />
                </Switch>
            </ConnectedRouter>
        </ThemeProvider>
    </Provider>
);

const app = document.getElementById('app');

render(Component, app);
