import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, Text, Flex, Box, Input, Error, Button } from '../../../lavender';
import Chart from './Chart';
import * as selectors from '../../../../redux/reducers';
import { tsne } from '../../../../redux/actions/models';
import { LoadingIndicator } from '../../../Loading';

export const AxisLabel = props => (
    <Text
        my="auto"
        textAlign="center"
        py={2}
        fontSize={5}
        weight="bold"
        {...props}
    />
);

const Params = props => (
    <Flex
        flexDirection={['column', 'column', 'column', 'column', 'row']}
        mx={5}
        my={3}
        pb={4}
        {...props}
    />
);

const mapStateToProps = state => ({
    tsne: selectors.getActiveModelTsne(state)
});

const mapDispatchToProps = dispatch => ({
    setTsne: payload => dispatch(tsne(payload))
});

class TSNE extends Component {
    constructor(props) {
        super(props);
        this.state = {
            learningRate: 200,
            nComponents: 2,
            perplexity: 30,
            loading: false
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.handleTSNE = this.handleTSNE.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        this.handleTSNE();
    }

    changeHandler({ target: { name, value } }) {
        this.setState({
            [name]: value
        });
    }

    handleTSNE() {
        const { params } = this.props.match;
        const { learningRate, nComponents, perplexity } = this.state;
        const payload = {
            learning_rate: parseFloat(learningRate),
            n_components: parseInt(nComponents),
            perplexity: parseFloat(perplexity)
        };

        this.props.setTsne({
            ...params,
            payload
        });
    }

    validate(type) {
        const { learningRate, nComponents, perplexity } = this.state;
        if (type === 'learningRate') return !(learningRate && learningRate > 0);

        if (type === 'nComponents')
            return !(nComponents <= 2 && nComponents >= 1);

        if (type === 'perplexity') return !(perplexity && perplexity > 0);

        return false;
    }

    render() {
        const { learningRate, nComponents, perplexity } = this.state;
        const { tsne } = this.props;
        const data = tsne && tsne.data ? tsne.data : [];

        return (
            <Card mb={4} p={3}>
                <Flex mx="auto" flexDirection="column" mb={2}>
                    <Text textAlign="center" py={3} fontSize={5} weight="bold">
                        T-distributed Stochastic Neighbor Embedding (t-SNE) |
                        Visualization
                    </Text>
                    <Flex>
                        <AxisLabel>Y</AxisLabel>
                        <Box mx="auto">
                            {data.length ? (
                                <Chart data={data} />
                            ) : (
                                <LoadingIndicator message="Loading TSNE visualization..." />
                            )}
                        </Box>
                    </Flex>
                </Flex>
                <AxisLabel>X</AxisLabel>
                <Params>
                    <Box width={300}>
                        <Flex mx={2}>
                            <Text my="auto" mr={3}>
                                Learning Rate:
                            </Text>
                            <Input
                                width={100}
                                error={this.validate('learningRate')}
                                name="learningRate"
                                placeholder="Learning Rate"
                                type="number"
                                value={learningRate}
                                onChange={this.changeHandler}
                            />
                        </Flex>
                        {this.validate('learningRate') && (
                            <Error message={'Learning Rate must be > 0.'} />
                        )}
                    </Box>
                    <Box width={300}>
                        <Flex mx={2}>
                            <Text my="auto" mr={3}>
                                N Components:
                            </Text>
                            <Input
                                width={100}
                                error={this.validate('nComponents')}
                                name="nComponents"
                                placeholder="N-Components"
                                type="number"
                                value={nComponents}
                                onChange={this.changeHandler}
                            />
                        </Flex>
                        {this.validate('nComponents') && (
                            <Error
                                mt={1}
                                message={'N Components must either be 1 or 2.'}
                            />
                        )}
                    </Box>
                    <Box width={300}>
                        <Flex mx={2}>
                            <Text my="auto" mr={3}>
                                Perplexity:
                            </Text>
                            <Input
                                width={100}
                                error={this.validate('perplexity')}
                                name="perplexity"
                                placeholder="Perplexity"
                                type="number"
                                value={perplexity}
                                onChange={this.changeHandler}
                            />
                        </Flex>
                        {this.validate('perplexity') && (
                            <Error mt={1} message={'Perplexity must be > 0.'} />
                        )}
                    </Box>
                    <Button
                        ml="auto"
                        size="s"
                        onClick={this.handleTSNE}
                        label="Update"
                        data-cy="save-tsne"
                    />
                </Params>
            </Card>
        );
    }
}

TSNE.displayName = 'TSNE';

TSNE.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }).isRequired,
    setTsne: PropTypes.func.isRequired,
    tsne: PropTypes.object
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TSNE)
);
