import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ListItems from './ListItems';
import CurationEntry from './CurationEntry';
import { Text } from '../../lavender';
import { Highlight } from '../../Highlight';

const Curation = ({ items }) => (
    <Fragment>
        <Text fontSize={3} my={3}>
            This interface shows samples that did not get assigned to their{' '}
            <Highlight truth>True Labels</Highlight>, but have been
            mis-classified under <Highlight predicted>Other Labels</Highlight>{' '}
            during the last testing pass. A predicted label of '_nc' (i.e. not
            classified) means the sample had no good match. The list only shows
            the top ten problems found during testing.
        </Text>
        {items.length ? (
            <ListItems Inner={CurationEntry} items={items} />
        ) : (
            <Text>
                Your model has no issues! According to the confusion matrix.
            </Text>
        )}
    </Fragment>
);

Curation.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            truth: PropTypes.string,
            predicted: PropTypes.string,
            metrics: PropTypes.object,
            samples: PropTypes.array
        })
    ).isRequired
};

export default Curation;
