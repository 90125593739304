import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import NewDomainDialog from '../Dialogs/NewDomainDialog';
import { Box, Icon, Text, Flex } from '../lavender';
import { lighten } from 'polished';

const MenuItem = styled(Box)(props => ({
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: lighten(0.1, props.theme.colors.brand[1])
    }
}));

MenuItem.defaultProps = {
    p: 10
};

MenuItem.propTypes = {
    children: PropTypes.any
};

export const MenuList = props => {
    return (
        <Box p={10}>
            <Box>{props.children}</Box>
            <MenuItem>
                <NewDomainDialog navbar />
            </MenuItem>
            <Link to="/">
                <MenuItem>
                    <Flex alignItems="center">
                        <Icon color="black" mr={1} name="menu" />
                        <Text color="brand.0" ml={1} fontSize={2} weight="bold">
                            View domains
                        </Text>
                    </Flex>
                </MenuItem>
            </Link>
        </Box>
    );
};

MenuList.propTypes = {
    children: PropTypes.any
};

export default MenuList;
