import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import history from './history';
import reducer from './redux/reducers';

const configureStore = preloadedState => {
    const middlewares = [routerMiddleware(history), thunk];

    // const composeEnhancers =
    //     typeof window === 'object' &&
    //     window.__REDUX__DEVTOOLS_ENXTENSION_COMPOSE__
    //         ? window.__REDUX__DEVTOOLS_ENXTENSION_COMPOSE__(devToolsOptions)
    //         : compose;

    const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

    const store = createStore(reducer, preloadedState, enhancer);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./redux/reducers', () => {
            const nextRootReducer = require('./redux/reducers');
            store.replaceReducer(nextRootReducer);
        });
    }
    return store;
};

export default configureStore;
