import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Box, Flex, Text, Popover } from '../lavender';
import { Link } from 'react-router-dom';
import { DeleteModelDialog } from '../Dialogs';
import { whichModel, whichIcon } from './utils';
import MoreIcon from '../Icons/MoreIcon';
import BaseCard from '../Cards';

const ModelCard = ({ domainId, item, ...props }) => {
    return (
        <Link
            to={`/model/${domainId}/${item.name}/${item.model_type}/samples/`}
        >
            <BaseCard theme={props.theme}>
                <Flex justifyContent="flex-end">
                    <Popover
                        key={item.id}
                        menu={[
                            {
                                label: 'Delete',
                                key: 'delete',
                                action: (
                                    <DeleteModelDialog
                                        drop
                                        model={item}
                                        domain={domainId}
                                    />
                                )
                            }
                        ]}
                    >
                        <MoreIcon horizontal />
                    </Popover>
                </Flex>
                <Flex mx={1} flexDirection="column" alignItems="center">
                    {whichIcon(item.model_type)}
                    <Box m={1} css={{ wordBreak: 'break-word' }}>
                        <Text
                            color="text.0"
                            textAlign="center"
                            fontSize={4}
                            fontWeight="bold"
                        >
                            {item.long_name || item.name}
                        </Text>
                    </Box>
                    <Box m={1}>
                        <Text truncate={40} color="text.1" fontSize={1}>
                            {item.desc}
                        </Text>
                    </Box>
                    <Box m={1}>
                        <Text color="text.0" fontWeight="medium">
                            {whichModel(item.model_type)}
                        </Text>
                    </Box>
                    <Box m={1} mb={3}>
                        <Text
                            variant={item.trashed ? 'warning' : 'primary'}
                            fontSize={0}
                            color="text.0"
                            fontWeight="bold"
                            caps
                        >
                            {`${item.trashed ? 'Trashed' : ''}`}
                        </Text>
                    </Box>
                </Flex>
            </BaseCard>
        </Link>
    );
};

ModelCard.propTypes = {
    domainId: PropTypes.string.isRequired,
    item: PropTypes.shape({
        desc: PropTypes.string,
        long_name: PropTypes.string,
        name: PropTypes.string.isRequired,
        model_type: PropTypes.string.isRequired,
        trashed: PropTypes.bool
    }).isRequired,
    theme: PropTypes.object.isRequired
};

export default withTheme(ModelCard);
