import {
    REQUEST_SUCCESS,
    REQUEST_SLUG_SUCCESS,
    REQUEST_ID_SUCCESS,
    CLEAR,
    CLEAR_ALL
} from '../actionTypes';
import omit from 'lodash/omit';

/* example of a generic factory reducer to extend resource reducer
 * current UNUSED. remove this line if it becomes uses.
 */

export const SET_ACTIVE = 'SET_ACTIVE';
export const CLEAR_ACTIVE = 'CLEAR_ACTIVE';
export const SET_RESULT = 'SET_RESULT';
export const TRAIN_START = 'TRAIN_START';
export const TRAIN_ERROR = 'TRAIN_ERROR';
export const TRAIN_SUCCESS = 'TRAIN_SUCCESS';
export const TEST_START = 'TEST_START';
export const TEST_ERROR = 'TEST_ERROR';
export const TEST_SUCCESS = 'TEST_SUCCESS';
export const TSNE = 'TSNE';
export const CLEAR_TSNE = 'CLEAR_TSNE';
export const EDIT_SIMILAR_WORD = 'EDIT_SIMILAR_WORD';
export const DELETE_SIMILAR_WORD = 'DELETE_SIMILAR_WORD';
export const ADD_SIMILAR_WORD = 'ADD_SIMILAR_WORD';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_LANGUAGE_MODELS = 'SET_LANGUAGE_MODELS';
export const SET_CLASSIFIER_ALGORITHM = 'SET_CLASSIFIER_ALGORITHM';

export const algorithm = entity => (state = null, { type, payload }) => {
    switch (type) {
        case `${SET_CLASSIFIER_ALGORITHM}/${entity}`:
            return payload;
        default:
            return state;
    }
};

export const languageModels = entity => (state = null, { type, payload }) => {
    switch (type) {
        case `${SET_LANGUAGE_MODELS}/${entity}`:
            return payload;
        default:
            return state;
    }
};

export const train = entity => (state = null, { type }) => {
    switch (type) {
        case `${TRAIN_START}/${entity}`:
            return true;
        case `${TRAIN_ERROR}/${entity}`:
            return false;
        default:
            return state;
    }
};

export const test = entity => (state = null, { type }) => {
    switch (type) {
        case `${TEST_START}/${entity}`:
            return true;
        case `${TEST_ERROR}/${entity}`:
            return false;
        default:
            return state;
    }
};

export const languages = entity => (state = null, { type, payload }) => {
    switch (type) {
        case `${SET_LANGUAGES}/${entity}`:
            return payload;
        default:
            return state;
    }
};

export const tsne = entity => (state = null, { type, payload }) => {
    switch (type) {
        case `${CLEAR_TSNE}/${entity}`:
            return null;
        case `${TSNE}/${entity}`:
            return payload;
        default:
            return state;
    }
};

export const active = entity => (state = null, { type, payload }) => {
    switch (type) {
        case `${SET_ACTIVE}/${entity}`:
            return payload;
        case `${CLEAR_ACTIVE}/${entity}`:
            return null;
        default:
            return state;
    }
};

export const result = entity => (state = null, { type, payload }) => {
    switch (type) {
        case `${SET_RESULT}/${entity}`:
            return payload;
        default:
            return state;
    }
};

export const byId = entity => (state = {}, { type, payload }) => {
    switch (type) {
        case `${REQUEST_SUCCESS}/${entity}`:
            return {
                ...state,
                ...Object.entries(payload).reduce(
                    // Object.entries returns [key, value]
                    (acc, [key, entity]) => ({
                        ...acc,
                        [key]: {
                            ...(state[key] || {}),
                            ...entity
                        }
                    }),
                    {}
                )
            };
        case `${REQUEST_SLUG_SUCCESS}/${entity}`:
        case `${REQUEST_ID_SUCCESS}/${entity}`: {
            return {
                ...state,
                [payload.id]: {
                    ...(state[payload.id] || {}),
                    ...payload
                }
            };
        }
        case `${CLEAR}/${entity}`:
            // https://lodash.com/docs/4.17.11#omit
            return omit(state, [payload]);
        case `${CLEAR_ALL}/${entity}`:
            return {};
        case `${ADD_SIMILAR_WORD}/${entity}`:
            return {
                [payload.word]: {
                    word: payload.word
                },
                ...state
            };
        case `${EDIT_SIMILAR_WORD}/${entity}`:
            return {
                [payload.newWord]: {
                    word: payload.newWord
                },
                ...omit(state, [payload.oldWord])
            };
        default:
            return state;
    }
};

export const allIds = entity => (state = [], { type, payload }) => {
    switch (type) {
        case `${ADD_SIMILAR_WORD}/${entity}`:
            return Array.from(new Set([payload.word, ...state]));
        case `${EDIT_SIMILAR_WORD}/${entity}`:
            var index = state.findIndex(id => id === payload.oldWord);
            return Array.from(
                new Set([
                    ...state.slice(0, index),
                    payload.newWord,
                    ...state.slice(index + 1)
                ])
            );
        case `${REQUEST_SUCCESS}/${entity}`:
            // this insures that the entries are unique due to the nature of sets
            // return Array.from([new Set([...state, ...Object.keys(payload)])];
            // Do to the babel config on feersum-lasso I cant spread the set.
            return Array.from(new Set([...Object.keys(payload), ...state]));
        case `${CLEAR}/${entity}`:
            return state.filter(id => id !== payload);
        case `${CLEAR_ALL}/${entity}`:
            return [];
        default:
            return state;
    }
};
