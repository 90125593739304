import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router';
import { Flex } from '../../../../lavender';
import { addSamples } from '../../../../../redux/actions/samples';
import { EditableDiv } from '../../../../EditableDiv';
import * as selectors from '../../../../../redux/reducers';
import { validateSample } from '../../../../../utils';
import AddSampleCard from './AddSampleCard';
import AddSampleButton from './AddSampleButton';
import HoverCard from './HoverCard';

const mapStateToProps = state => ({
    samples: selectors.getAllSamplesByIds(state)
});

const mapDispatchToProps = dispatch => ({
    addSample: payload => dispatch(addSamples(payload))
});

export class AddSample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: '',
            utterance: '',
            focused: false,
            labelEl: null,
            utteranceEl: null
        };
    }
    onHoverOver = () => {
        this.setState({ hover: true });
    };

    onHoverOut = () => {
        this.setState({ hover: false });
    };

    valid = () => {
        const { samples } = this.props;
        const { label, utterance } = this.state;
        if (label === '' || utterance === '') return false;
        if (!samples[label]) return true;

        return !samples[label].texts.find(text => text.text === utterance);
    };

    handleAdd = () => {
        const { label, utterance, labelEl, utteranceEl } = this.state;
        const { sampleType, samples } = this.props;
        if (validateSample(label, utterance, samples)) {
            const sample = { label, text: utterance, type: sampleType };
            this.props
                .addSample({
                    ...this.props.match.params,
                    samples: [sample]
                })
                .then(() => {
                    labelEl.innerText = '';
                    utteranceEl.innerText = '';
                    labelEl.focused = false;
                    utteranceEl.focused = false;
                });
            this.onBlur();
            this.setState({ label: '', utterance: '' });
        }
    };

    onInputLabel = event => {
        const label = event.target.innerText.trim();
        this.setState({ label, labelEl: event.target });
    };

    onInputUtterance = event => {
        const utterance = event.target.innerText.trim();
        this.setState({ utterance, utteranceEl: event.target });
    };

    onBlur = () => {
        this.setState({ focused: false });
    };

    onFocus = () => {
        this.setState({ focused: true });
    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleAdd();
        }
    };

    render() {
        const { theme } = this.props;
        const { focused, label, utterance } = this.state;
        return (
            <AddSampleCard focused={focused} theme={theme}>
                <HoverCard
                    onHoverOver={this.onHoverOver}
                    onHoverOut={this.onHoverOut}
                    theme={theme}
                >
                    <EditableDiv
                        placeholder="Type a new label here..."
                        onInput={this.onInputLabel}
                        onKeyPress={this.handleKeyPress}
                        contentEditable="true"
                        suppressContentEditableWarning="true"
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        width={1}
                        fontSize={3}
                        py={25}
                        pl={4}
                        data-cy={'type-label'}
                    />
                </HoverCard>
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    onMouseOver={this.onHoverOver}
                    onMouseOut={this.onHoverOut}
                    css={{
                        '&:hover': {
                            backgroundColor: theme.colors.ui[1]
                        }
                    }}
                >
                    <EditableDiv
                        placeholder="Type a new utterance for the new label here..."
                        onInput={this.onInputUtterance}
                        onKeyPress={this.handleKeyPress}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        fontSize={3}
                        py={25}
                        pl={4}
                        data-cy={'type-text'}
                    />
                    {label && utterance && (
                        <AddSampleButton onClick={this.handleAdd}>
                            Add Sample
                        </AddSampleButton>
                    )}
                </Flex>
            </AddSampleCard>
        );
    }
}

AddSample.propTypes = {
    label: PropTypes.string,
    sample: PropTypes.object,
    samples: PropTypes.object,
    sampleType: PropTypes.string.isRequired,
    addSample: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }),
    theme: PropTypes.object.isRequired
};

export default withRouter(
    withTheme(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddSample)
    )
);
