import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Dialog from '@material-ui/core/Dialog';

import { Error, Text, Flex, Box, Icon } from '../../lavender';
import {
    addSamples,
    upload,
    deleteAllSamples
} from '../../../redux/actions/samples';
import Upload from '../../Upload';
import {
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    ActionButton
} from '../styles';
import * as selectors from '../../../redux/reducers';

const mapStateToProps = state => ({
    samples: Object.values(selectors.getAllSamplesByIds(state))
});

const mapDispatchToProps = dispatch => ({
    addSamples: payload => dispatch(addSamples(payload)),
    upload: payload => dispatch(upload(payload)),
    deleteAllSamples: payload => dispatch(deleteAllSamples(payload))
});

class UploadCRFEntitiesDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            uploadSamples: [],
            file: null,
            clicked: false
        };
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.validate = this.validate.bind(this);
    }

    toggleDialog() {
        const { open } = this.state;
        this.setState({ open: !open });
        open && this.clear();
    }

    clear = () => {
        this.setState({ uploadSamples: [], file: null, clicked: false });
    };

    validate() {
        const { file } = this.state;
        return file && file.type === 'application/json';
    }

    handleUpload() {
        const { params } = this.props.match;
        const { replace, sampleType } = this.props;
        const { file } = this.state;
        this.setState({ clicked: true });

        if (this.validate()) {
            replace &&
                this.props.deleteAllSamples({
                    ...params,
                    sample_type: sampleType
                });
            this.props.upload({
                ...params,
                sampleType,
                file
            });
            this.setState({ clicked: false });
            this.toggleDialog();
        }
    }

    handleChange = file => {
        this.setState({ file });
    };

    handleRemoveFile = () => {
        this.setState({ file: null, uploadSamples: [] });
    };

    render() {
        const { file, clicked } = this.state;
        const { children } = this.props;

        return (
            <Fragment>
                <div onClick={this.toggleDialog}>{children}</div>
                <Dialog
                    open={this.state.open}
                    onClose={this.toggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogTitle>Import Samples</DialogTitle>
                        <DialogContentText>
                            If you need to import more than one sample, this is
                            where you can do just that 🎉. Unfortunately, we
                            only support json files for now, but soon we will be
                            adding more.
                        </DialogContentText>
                        <Upload handleChange={this.handleChange} />
                        {file && (
                            <Flex my={3} alignItems="center">
                                <Text
                                    color={
                                        !this.validate()
                                            ? 'support.0'
                                            : 'support.1'
                                    }
                                    fontSize={3}
                                    weight="bold"
                                >
                                    {file.name}
                                </Text>
                                {this.validate() ? (
                                    <Icon
                                        color="support.1"
                                        size={6}
                                        ml={2}
                                        name="check"
                                    />
                                ) : (
                                    <Icon
                                        color="support.0"
                                        size={1}
                                        ml={2}
                                        name="x"
                                        onClick={this.handleRemoveFile}
                                    />
                                )}
                                <Icon
                                    size={1}
                                    ml="auto"
                                    name="x"
                                    mr={2}
                                    onClick={this.handleRemoveFile}
                                />
                            </Flex>
                        )}
                        {!this.validate() && (file || clicked) && (
                            <Error
                                my={2}
                                weight="medium"
                                message={
                                    !file
                                        ? 'Please choose a file.'
                                        : file.type !== 'application/json'
                                        ? 'Wrong file type. Supported file types: [.json]'
                                        : ''
                                }
                            />
                        )}
                        <Flex my={3} alignItems="center">
                            <Text pr={2} fontSize={3} weight="bold">
                                Supported file types:
                            </Text>
                            <Text> (&nbsp;.json)</Text>
                        </Flex>
                        <Flex flexDirection="column" my={3}>
                            <Text pr={2} fontSize={3} weight="bold">
                                Format:
                            </Text>
                            <Text color="text.1" fontSize={2}>
                                Each sample must be stored in list of json
                                objects.
                            </Text>
                            <Text my={3} color="text.1" fontSize={2}>
                                If there is text in a label or intent (text)
                                that contains a <strong>{`,`}</strong>, wrap the
                                sentence with double quotes
                                <strong>{`"`}</strong>.
                            </Text>
                        </Flex>

                        <Box
                            mb={4}
                            p={3}
                            bg="#f4f7fb"
                            css={{ borderRadius: '8px' }}
                        >
                            <Text as="pre" weight="medium">
                                {JSON.stringify(
                                    [
                                        {
                                            intent: 'find_restaurant',
                                            text:
                                                "I'd like to order some chinese food please",
                                            entity_list: [
                                                {
                                                    entity: 'cuisine',
                                                    index: 23,
                                                    len: 7
                                                }
                                            ]
                                        }
                                    ],
                                    undefined,
                                    2
                                )}
                            </Text>
                        </Box>
                        <DialogActions>
                            <ActionButton
                                label="Import"
                                onClick={this.handleUpload}
                            />
                            <ActionButton
                                plain
                                label="Cancel"
                                onClick={this.toggleDialog}
                            />
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

UploadCRFEntitiesDialog.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }).isRequired,
    table: PropTypes.bool,
    replace: PropTypes.bool,
    deleteAllSamples: PropTypes.func.isRequired,
    sampleType: PropTypes.string.isRequired,
    upload: PropTypes.func.isRequired,
    children: PropTypes.any
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UploadCRFEntitiesDialog)
);
