import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';

import { Button, Icon, Input, Flex, Text, Box, CheckBox } from '../../lavender';
import { deleteModel } from '../../../redux/actions/models';
import {
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    ActionButton
} from '../styles';

const mapDispatchToProps = dispatch => ({
    deleteModel: payload => dispatch(deleteModel(payload))
});

class DeleteModelDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            vaporise: false,
            del: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
    }

    handleDelete() {
        const { del, vaporise } = this.state;
        const { model, domain } = this.props;

        if (del === 'DELETE') {
            this.props.deleteModel({
                domainId: domain,
                modelType: model.model_type,
                modelName: model.name,
                payload: {
                    vaporise
                }
            });
            this.toggleDialog();
        }
    }

    toggleDialog() {
        this.setState({ open: !this.state.open });
    }

    handleChange({ target: { name, value } }) {
        this.setState({
            [name]: value
        });
    }

    handleCheckedChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    render() {
        let { domainId } = this.props.match.params;
        const { model, drop, table } = this.props;
        const { open, vaporise, del } = this.state;
        return (
            <Fragment>
                {table ? (
                    <Icon
                        name="delete"
                        danger
                        onClick={this.toggleDialog}
                        data-cy={`delete-model-dialog-table-${model.name}`}
                    />
                ) : drop ? (
                    <Flex
                        p={3}
                        onClick={this.toggleDialog}
                        data-cy={`delete-model-dialog-table-${model.name}`}
                        alignItems="center"
                    >
                        <Icon color="support.0" name="delete" />
                        <Text ml={2} fontSize={3} weight="medium">
                            Delete
                        </Text>
                    </Flex>
                ) : (
                    <Button
                        danger
                        label="Delete model"
                        onClick={this.toggleDialog}
                        data-cy="delete-model-dialog"
                    />
                )}

                {open && (
                    <Dialog
                        open={open}
                        onClose={this.toggleDialog}
                        data-cy="delete-model-dialog"
                    >
                        <DialogContent>
                            <DialogTitle>Delete {model.name}?</DialogTitle>
                            <DialogContentText>
                                Are you sure you want to delete the model&nbsp;
                                <strong>{model.long_name}</strong> with
                                slug&nbsp;
                                <strong>{model.name}</strong>? This will destroy
                                the model with all corresponding data and cannot
                                be undone!
                            </DialogContentText>
                            <CheckBox
                                label="Vaporise Model. (WARNING: Will be lost forever.)"
                                value="vaporise"
                                checked={vaporise}
                                handleChange={this.handleCheckedChange}
                            />
                            <Box mb={2}>
                                <Input
                                    name="del"
                                    placeholder="Type DELETE here and click the delete button."
                                    onChange={this.handleChange}
                                    data-cy="delete-model-dialog-input"
                                />
                            </Box>
                            <DialogActions>
                                <Link to={`/domain/${domainId}/`}>
                                    <ActionButton
                                        warning
                                        label="DELETE"
                                        onClick={this.handleDelete}
                                        disabled={del !== 'DELETE'}
                                        data-cy="delete-model-button"
                                    />
                                </Link>

                                <ActionButton
                                    plain
                                    label="CANCEL"
                                    onClick={this.toggleDialog}
                                    data-cy="close-dialog"
                                />
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                )}
            </Fragment>
        );
    }
}

DeleteModelDialog.propTypes = {
    deleteModel: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired
        })
    }).isRequired,
    table: PropTypes.bool,
    drop: PropTypes.bool,
    domain: PropTypes.string.isRequired,
    model: PropTypes.object.isRequired
};

export default connect(
    null,
    mapDispatchToProps
)(withRouter(DeleteModelDialog));
