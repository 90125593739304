import React from 'react';
import PropTypes from 'prop-types';

import { Text, Box, Flex, Button } from '../lavender';

export const DialogContent = props => (
    <Box m={4} {...props}>
        {props.children}
    </Box>
);

DialogContent.propTypes = {
    children: PropTypes.any
};

export const DialogTitle = props => (
    <Text fontSize={6} weight="bold" {...props}>
        {props.children}
    </Text>
);

DialogTitle.propTypes = {
    children: PropTypes.any
};

export const DialogContentText = props => (
    <Box my={2}>
        <Text color="text.1" fontSize={2} mb={20} {...props}>
            {props.children}
        </Text>
    </Box>
);

DialogContentText.propTypes = {
    children: PropTypes.any
};

export const DialogActions = props => (
    <Flex justifyContent="flex-end" {...props}>
        {props.children}
    </Flex>
);

DialogActions.propTypes = {
    children: PropTypes.any
};

export const ActionButton = props => (
    <Button
        size="m"
        width={1 / 5}
        mr={2}
        css={{ borderRadius: '20px' }}
        {...props}
    />
);
