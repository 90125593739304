import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { Flex, Text } from '../';

const _Base = styled(Checkbox)({
    root: { color: 'tomato' }
});

const CheckBox = ({ checked, label, value, handleChange, ...props }) => {
    return (
        <Flex alignItems="center" {...props}>
            <_Base
                checked={checked}
                onChange={handleChange(value)}
                value={value}
            />
            <Text weight="medium">{label}</Text>
        </Flex>
    );
};

CheckBox.propTypes = {
    checked: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    value: PropTypes.string.isRequired
};

export default CheckBox;
