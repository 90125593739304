import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Dialog from '@material-ui/core/Dialog';

import { Error, Text, Flex, Box, Icon } from '../../lavender';
import {
    addSamples,
    upload,
    deleteAllSamples
} from '../../../redux/actions/samples';
import Upload from '../../Upload';
import {
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    ActionButton
} from '../styles';
import * as selectors from '../../../redux/reducers';

const mapStateToProps = state => ({
    samples: Object.values(selectors.getAllSamplesByIds(state))
});

const mapDispatchToProps = dispatch => ({
    addSamples: payload => dispatch(addSamples(payload)),
    upload: payload => dispatch(upload(payload)),
    deleteAllSamples: payload => dispatch(deleteAllSamples(payload))
});

class UploadSimilarWordsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            uploadSamples: [],
            file: null,
            clicked: false
        };
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.validateFile = this.validateFile.bind(this);
    }

    toggleDialog() {
        const { open } = this.state;
        this.setState({ open: !open });
        open && this.clear();
    }

    clear = () => {
        this.setState({ uploadSamples: [], file: null, clicked: false });
    };

    validateFile() {
        const { file } = this.state;
        return file && file.type === 'text/csv';
    }

    handleUpload() {
        const { params } = this.props.match;
        const { file } = this.state;
        this.setState({ clicked: true });
        if (this.validateFile()) {
            this.props.upload({
                ...params,
                sample_type: 'training',
                file
            });
            this.setState({ clicked: false });
            this.toggleDialog();
        }
    }

    handleChange = file => {
        this.setState({ file });
    };

    handleRemoveFile = () => {
        this.setState({ file: null, uploadSamples: [] });
    };

    render() {
        const { file, clicked } = this.state;
        const { children } = this.props;
        const valid = this.validateFile();

        return (
            <Fragment>
                <div onClick={this.toggleDialog}>{children}</div>
                <Dialog
                    open={this.state.open}
                    onClose={this.toggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogTitle>Import Similar Words</DialogTitle>
                        <DialogContentText>
                            If you need to import more than one word, this is
                            where you can do just that 🎉. Unfortunately, we
                            only support csv files for now, but soon we will be
                            adding more.
                        </DialogContentText>
                        <Upload handleChange={this.handleChange} />
                        {file && (
                            <Flex my={3} alignItems="center">
                                <Text
                                    color={!valid ? 'support.0' : 'support.1'}
                                    fontSize={3}
                                    weight="bold"
                                >
                                    {file.name}
                                </Text>
                                <Icon
                                    size={1}
                                    ml="auto"
                                    name="x"
                                    mr={2}
                                    onClick={this.handleRemoveFile}
                                />
                            </Flex>
                        )}
                        {!valid && (file || clicked) && (
                            <Error
                                my={2}
                                weight="medium"
                                message={
                                    !file
                                        ? 'Please choose a file.'
                                        : file.type !== 'text/csv'
                                        ? 'Wrong file type. Supported file types: [.csv]'
                                        : ''
                                }
                            />
                        )}
                        <Flex my={3} alignItems="center">
                            <Text pr={2} fontSize={3} weight="bold">
                                Supported file types:
                            </Text>
                            <Text> (&nbsp;.csv)</Text>
                        </Flex>
                        <Flex flexDirection="column" my={3}>
                            <Text pr={2} fontSize={3} weight="bold">
                                Format:
                            </Text>
                            <Text color="text.1" fontSize={2}>
                                Each word must be on its own line.
                            </Text>
                            <Text my={3} color="text.1" fontSize={2}>
                                If there is text in a word that contains a
                                <strong>{`,`}</strong>, wrap the sentence with
                                double quotes
                                <strong>{`"`}</strong>.
                            </Text>
                        </Flex>

                        <Box
                            mb={4}
                            p={3}
                            bg="#f4f7fb"
                            css={{ borderRadius: '8px' }}
                        >
                            <Text weight="medium">red</Text>
                            <Text weight="medium">blue</Text>
                            <Text weight="medium">{`"car's"`}</Text>
                        </Box>
                        <DialogActions>
                            <ActionButton
                                label="Import"
                                onClick={this.handleUpload}
                            />
                            <ActionButton
                                plain
                                label="Cancel"
                                onClick={this.toggleDialog}
                            />
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

UploadSimilarWordsDialog.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }).isRequired,
    deleteAllSamples: PropTypes.func.isRequired,
    upload: PropTypes.func.isRequired,
    children: PropTypes.any
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UploadSimilarWordsDialog)
);
