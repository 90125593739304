import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import * as domainActions from '../../redux/actions/domains';
import { AddCard } from './Card';
import DomainCard from './Card';
import NewDomainDialog from '../Dialogs/NewDomainDialog';
import { Text, Flex, Box, Grid, Cell } from '../lavender';
import { LoadingIndicator } from '../Loading';
import { SearchInput } from '../SearchInput';
import Fuse from 'fuse.js';
import Header from '../Headers/LoggedIn';
import * as selectors from '../../redux/reducers';
import { clear } from '../../redux/utils/actions/resource';
import { baseFuseConfig } from '../../config';

baseFuseConfig['keys'] = ['name', 'description', 'service', 'uid'];

const mapStateToProps = state => ({
    // Returning an array of objects for Fuse.js to index on.
    domains: Object.values(selectors.getAllDomainsByIds(state)),
    isLoading: selectors.getIsLoadingDomains(state)
});

const mapDispatchToProps = dispatch => ({
    editDomain: payload => dispatch(domainActions.editDomain(payload)),
    clear: () => dispatch(clear('domains')('active'))
});

class Domains extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: ''
        };
    }

    componentDidMount() {
        this.props.clear();
    }

    updateQuery = query => {
        this.setState({
            query
        });
    };

    render() {
        const { isLoading, domains } = this.props;
        const { query } = this.state;
        const fuse = new Fuse(domains, baseFuseConfig);
        const result = query ? fuse.search(query) : domains;

        return (
            <Fragment>
                <Header title="Domains" />
                <Flex m={4}>
                    <SearchInput
                        py={1}
                        fontSize={2}
                        placeholder="Search domains..."
                        setQuery={this.updateQuery}
                        value={query}
                        dataCy="search-domain"
                    />
                </Flex>
                {isLoading ? (
                    <LoadingIndicator message="Loading domains..." />
                ) : (
                    <Box mx={4} my={2}>
                        <Grid
                            gap="30px"
                            columns="repeat(auto-fit, minmax(300px, 1fr))"
                        >
                            <Cell>
                                <NewDomainDialog
                                    card={
                                        <AddCard text="Create a New Domain" />
                                    }
                                />
                            </Cell>
                            {!result.length ? (
                                <Flex justifyContent="center">
                                    <Text mt={4} fontSize={6}>
                                        {query
                                            ? 'No results.'
                                            : `You don't have any domains.`}
                                    </Text>
                                </Flex>
                            ) : (
                                result.map(item => (
                                    <Cell key={item.id}>
                                        <DomainCard id={item.id} />
                                    </Cell>
                                ))
                            )}
                        </Grid>
                    </Box>
                )}
            </Fragment>
        );
    }
}

Domains.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string,
            modelType: PropTypes.string,
            modelName: PropTypes.string
        })
    }),
    domains: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    clear: PropTypes.func
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Domains)
);
