import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Flex, Box, Card, Text, Icon } from '../../lavender';

const AddCard = ({ text, theme }) => (
    <Card
        bg="inherit"
        border={`4px solid #11e275`}
        css={{
            height: '200px',
            '&:hover': {
                backgroundColor: theme.colors.ui[1],
                boxShadow: '2px 2px 2px 1px #CCC',
                transition: 'box-shadow 0.2s ease-in-out',
                cursor: 'pointer'
            }
        }}
    >
        <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            css={{ height: '100%' }}
        >
            <Box>
                <Icon size={8} color="#11e275" name="addCircle" />
            </Box>
            <Box mt={1}>
                <Text fontSize={4} fontWeight="bold">
                    {text}
                </Text>
            </Box>
        </Flex>
    </Card>
);

AddCard.propTypes = {
    text: PropTypes.any.isRequired,
    theme: PropTypes.object
};

export default withTheme(AddCard);
