import { darken, rgba } from 'polished';

export const lifeAquatic = {
    azure: '#1DACE8',
    darkSlateBlue: '#1C366B',
    tomato: '#F24D29',
    beige: '#E5C4A1',
    silver: '#C4CFD0'
};

export const royalTenenBaums = {
    leather: '#9A872D',
    lightKhaki: '#F5CDB6',
    blush: '#F7B0AA',
    palePeach: '#FDDDA4',
    greyishTeal: '#76A08A'
};

export const grandBudapestHotel = {
    fadedPink: '#D8A49B',
    lightPeriwinkle: '#C7CEF6',
    fadedBlue: '#7496D2'
};

export const moonriseKingdom = {
    brick: '#B62A3D',
    dullYellow: '#EDCB64',
    dust: '#B5966D',
    lightBlueGrey: '#DAECED',
    greenishBeige: '#CECD7B'
};

export const fantasticMrFox = {
    maize: '#F8DF4F',
    bownish: '#A35E60',
    mudBrown: '#541F12',
    dullOrange: '#CC8B3C',
    beige: '#E8D2B9'
};

export const darjeelingLimited = {
    greyish: '#AEA8A8',
    brownishOrange: '#CB9E23',
    reddishGrey: '#957A6D',
    clay: '#AC6E49'
};

export const hotelChevalier = {
    gunmetal: '#456355',
    paleGold: '#FCD16B',
    silver: '#D3DDDC',
    pinkishGrey: '#C6B19D'
};

export const rushmore = {
    sand: '#DBB165',
    pinkishTan: '#DEB18B',
    pine: '#2E604A',
    dark: '#27223C',
    fadedRed: '#D1362F'
};

const feersum = [
    '#000000', // primary brand color
    '#ffffff', // secondary brand color
    '#3f3f3f' // tertiary brand color
];

const brand = [
    feersum[0], // primary brand color
    lifeAquatic.azure, // secondary brand color
    lifeAquatic.tomato // tertiary brand color
];

const ui = [
    '#ffffff', // primary background
    '#f4f7fb', // secondary background
    '#dfe3e6', // subtle border
    '#8897a2', // emphasis border, 3:1 contrast
    '#5a6872',
    '#424242' // high contrast border, secondary icons, 4.5:1 contrast
];

const text = [
    '#152935', // primary text, body copy
    '#5a6872', // secondary text, subtle text, help text
    '#cdd1d4' // placeholder text
];

const inverse = [
    '#ffffff', // inverse text color, inverse icon color
    '#272d33' // inverse background color
];

const field = [
    '#f4f7fb', // field color on ui.0
    '#ffffff' // field color on ui.1
];

const support = [
    '#F24D29', // error
    '#11e275', // success
    '#F8DF4F', // warning
    '#1DACE8' // info
];

const hover = [
    darken(0.1, brand[0]), // hover primary brand
    darken(0.15, brand[0]), // hover primary text
    darken(0.1, support[0]), // hover danger
    brand[0], // hover secondary, ui.4 element hover
    rgba(brand[0], 0.1) // hover row
];

export const colors = {
    brand,
    ui,
    text,
    inverse,
    field,
    support,
    hover
};

export default colors;
