export const validateRegister = values => {
    const errors = {};
    if (!values.username) {
        errors.username = 'This field is required.';
    } else if (values.username.length > 15) {
        errors.username = 'Must be 15 characters or less';
    }
    if (!values.email) {
        errors.email = 'This field is required.';
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address';
    }

    if (!values.password1) {
        errors.password1 = 'This field is required.';
    }

    if (!values.password2) {
        errors.password2 = 'This field is required.';
    }
    return errors;
};

export const validateLogin = values => {
    const errors = {};
    if (!values.username) {
        errors.username = 'This field is required.';
    } else if (values.username.length > 15) {
        errors.username = 'Must be 15 characters or less';
    }

    if (!values.password) {
        errors.password = 'This field is required.';
    }
    return errors;
};

export const validateDomain = values => {
    const errors = {};
    if (!values.name) {
        errors.name = 'This field is required.';
    }

    if (!values.api_key) {
        errors.api_key = 'This field is required.';
    }

    if (!values.service) {
        errors.service = 'This field is required.';
    }
    return errors;
};
