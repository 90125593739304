import React from 'react';
import styled from 'styled-components';

import { Text } from '../lavender';

const Base = styled(Text)`
    outline: none;
    user-select: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    width: 100%;

    :empty:before {
        color: #cdd1d4;
        content: attr(placeholder);
    }
`;

export const EditableDiv = ({ ...props }) => (
    <Base
        contentEditable="true"
        suppressContentEditableWarning="true"
        width={1}
        placeholder="Type your example here..."
        {...props}
    />
);
