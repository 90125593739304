import {
    SCHEMA_GET_SUCCESS,
    SCHEMA_GET,
    LOAD_SCHEMA,
    CLEAR_SCHEMA
} from '../constants/actionTypes';

export default (
    state = {
        loading: false
    },
    action
) => {
    switch (action.type) {
        case SCHEMA_GET:
            return {
                ...state,
                loading: true
            };
        case SCHEMA_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload
            };
        case LOAD_SCHEMA:
            return {
                ...state,
                ...action.payload
            };
        case CLEAR_SCHEMA:
            return { loading: false };
        default:
            return state;
    }
};
