import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Text } from '../../../../lavender';
import * as selectors from '../../../../../redux/reducers';

const Title = props => <Text fontSize={4} weight="bold" {...props} />;

const mapStateToProps = (state, prevProps) => ({
    cmArray: prevProps.training
        ? selectors.getActiveModelTrainingCMMatrix(state)
        : selectors.getActiveModelTestingCMMatrix(state),
    labelsText: Object.values(selectors.getActiveModelCMLabelsLookup(state))
});

class Tooltip extends Component {
    render() {
        const {
            labelsText,
            cmArray,
            node: { xKey, yKey }
        } = this.props;
        const index =
            cmArray.hasOwnProperty(xKey) &&
            cmArray.hasOwnProperty(yKey) &&
            cmArray[yKey][xKey];

        return (
            <Box p={2}>
                {index && (index.sample_count || xKey === yKey) ? (
                    <Box>
                        <Title>
                            True Label: &nbsp;
                            <Text as="span" weight="medium" color="support.1">
                                {index.true_label}
                            </Text>
                        </Title>
                        <Title>
                            Pred Label: &nbsp;
                            <Text
                                as="span"
                                weight="medium"
                                color={xKey === yKey ? 'support.1' : 'tomato'}
                            >
                                {index.pred_label}
                            </Text>
                        </Title>
                        {xKey !== yKey ? (
                            <Box>
                                <Title>
                                    Incorrectly Predicted: {index.sample_count}
                                </Title>
                                <Text fontSize={2} my={2} weight="medium">
                                    Click on the
                                    <Text as="span" weight="bold">
                                        &nbsp;Curation&nbsp;
                                    </Text>
                                    tab above to fix samples.
                                </Text>
                            </Box>
                        ) : (
                            <Title>
                                Correctly Predicted: {index.sample_count}
                            </Title>
                        )}
                    </Box>
                ) : (
                    'No issues'
                )}
            </Box>
        );
    }
}

Tooltip.propTypes = {
    cmArray: PropTypes.object.isRequired,
    labelsText: PropTypes.array.isRequired,
    node: PropTypes.object,
    training: PropTypes.bool
};

export default connect(mapStateToProps)(Tooltip);
