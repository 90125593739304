import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

import { Icon } from '../../lavender';
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    ActionButton,
    DialogContentText
} from '../styles';

class DeleteSampleDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.toggleDialog = this.toggleDialog.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    toggleDialog() {
        this.setState({ open: !this.state.open });
    }

    handleSubmit() {
        this.props.onConfirm();
        this.toggleDialog();
    }

    render() {
        return (
            <div data-cy="delete-dialog">
                <Icon
                    danger
                    name="delete"
                    onClick={this.toggleDialog}
                    data-cy="delete-sample"
                />
                <Dialog
                    open={this.state.open}
                    onClose={this.toggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogTitle>Deleting sample</DialogTitle>
                        <DialogContentText>
                            Are you sure you want to delete this sample?
                        </DialogContentText>
                        <DialogActions>
                            <ActionButton
                                warning
                                label="Yes"
                                onClick={this.handleSubmit}
                                data-cy="delete-yes"
                            />
                            <ActionButton
                                plain
                                label="No"
                                onClick={this.toggleDialog}
                                data-cy="delete-no"
                            />
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

DeleteSampleDialog.propTypes = {
    onConfirm: PropTypes.func.isRequired
};

export default DeleteSampleDialog;
