import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { Flex, Box } from '../../../lavender';
import { Save, Cancel } from '../../../SampleField';
import { EditableDiv } from '../../../EditableDiv';

class EditableField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            original: props.text,
            text: props.text,
            el: null
        };
        this.onHoverOver = this.onHoverOver.bind(this);
        this.onHoverOut = this.onHoverOut.bind(this);
        this.onInput = this.onInput.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    onHoverOver() {
        this.setState({ hover: true });
    }

    onHoverOut() {
        this.setState({ hover: false });
    }

    onInput(event) {
        const value = event.target.innerText.trim();
        this.setState({ el: event.target, text: value });
    }

    cancel() {
        const { el } = this.state;
        const { text } = this.props;
        el.innerText = text;
        this.setState({ el, text, original: text });
        el.blur();
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            const value = e.target.innerText.trim();
            if (value !== this.state.original) {
                this.props.onEdit(value);
            }
        }
    }

    handleEdit() {
        const { text, original } = this.state;

        text !== original && this.props.onEdit(text);
    }

    render() {
        const { text, theme } = this.props;
        const edited = this.state.text !== text;
        const { colors } = theme;
        return (
            <Box>
                <Flex
                    width={1}
                    alignItems="center"
                    py={2}
                    css={{
                        border: edited
                            ? `4px solid ${colors.support[2]}`
                            : '4px solid transparent',
                        borderRadius: edited && '8px',
                        '&:hover': {
                            backgroundColor: colors.ui[1]
                        }
                    }}
                >
                    <EditableDiv
                        name="desc"
                        placeholder="Type your model description example here..."
                        onInput={this.onInput}
                        onKeyPress={this.handleKeyPress}
                    >
                        {text}
                    </EditableDiv>
                    {edited && (
                        <Flex ml="auto">
                            <Save onClick={this.handleEdit}>Save</Save>
                            <Cancel onClick={this.cancel}>Cancel</Cancel>
                        </Flex>
                    )}
                </Flex>
            </Box>
        );
    }
}

EditableField.propTypes = {
    text: PropTypes.string,
    onEdit: PropTypes.func.isRequired,
    theme: PropTypes.object
};

export default withTheme(EditableField);
