import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell } from '../lavender';
import { CreateModelDialog } from '../Dialogs';
import AddCard from '../Domains/Card/AddCard';
import ModelCard from './ModelCard';

const ModelGrid = ({ items, group, domainId }) => {
    return (
        <Grid gap="30px" columns="repeat(auto-fit, minmax(300px, 1fr))">
            {group != 'sentiment' && (
                <Cell>
                    <CreateModelDialog
                        group={group}
                        card={<AddCard text="Create New Model" />}
                    />
                </Cell>
            )}
            {items.map((item, i) => (
                <Cell key={`${item.name}-${i}`}>
                    <ModelCard item={item} domainId={domainId} />
                </Cell>
            ))}
        </Grid>
    );
};

ModelGrid.propTypes = {
    items: PropTypes.array,
    group: PropTypes.string,
    domainId: PropTypes.string.isRequired
};

export default ModelGrid;
