import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AddWord from './AddWord';
import Word from './Word';

const Words = ({ words = [] }) => (
    <Fragment>
        <AddWord words={words} />
        {words.map(word => (
            <Word key={word} id={word} />
        ))}
    </Fragment>
);

Words.propTypes = {
    words: PropTypes.array.isRequired
};

export default Words;
