import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TextArea, Flex, Box, Button } from '../../lavender';

import { updateModel } from '../../../redux/actions/models';

const mapDispatchToProps = dispatch => ({
    updateModel: payload => dispatch(updateModel(payload))
});

class Regex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expression: props.model.regex || ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        const { model, updateModel } = this.props;
        const { expression } = this.state;

        return (
            <Fragment>
                <h1>Regex extractor</h1>
                <h3>Regular Expression</h3>
                <form
                    onSubmit={() =>
                        updateModel({
                            ...this.props.match.params,
                            payload: { regex: expression }
                        })
                    }
                >
                    <TextArea
                        value={expression}
                        name="expression"
                        onChange={this.handleChange}
                        wrap="hard"
                        rows="2"
                    />
                    <Box pt={2}>
                        <Flex justifyContent="flex-end">
                            <Button
                                disabled={expression === model.regex}
                                type="submit"
                            >
                                Update
                            </Button>
                        </Flex>
                    </Box>
                </form>
            </Fragment>
        );
    }
}

Regex.propTypes = {
    model: PropTypes.shape({
        regex: PropTypes.string
    }),
    updateTestResults: PropTypes.func,
    updateModel: PropTypes.func,
    testResults: PropTypes.array,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }).isRequired
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(Regex)
);
