import { theme } from '../../lavender';
import { darken } from 'polished';

export const styles = {
    option: base => ({
        ...base,
        color: theme.colors.text[0],
        backgroundColor: 'transparent',
        padding: 10,
        borderRadius: '8px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.colors.brand[1]
        },
        '&:active': {
            backgroundColor: theme.colors.brand[1]
        }
    }),
    placeholder: base => ({
        ...base,
        color: theme.colors.text[0],
        fontSize: theme.fontSizes[1],
        fontWeight: theme.fontWeights.bold
    }),
    loadingIndicator: base => ({
        ...base,
        color: theme.colors.text[0]
    }),
    indicatorSeparator: () => ({
        color: 'transparent'
    }),
    dropdownIndicator: base => ({
        ...base,
        color: theme.colors.text[0],
        '&:hover': {
            color: theme.colors.text[0],
            backgroundColor: theme.colors.ui[2],
            borderRadius: '0px 5px 5px 0px'
        }
    }),
    control: base => ({
        ...base,
        backgroundColor: 'transparent',
        borderRadius: '10px',
        borderColor: 'white',
        border: '2px solid white',
        cursor: 'pointer',
        boxShadow: 0,
        '&:hover': {
            backgroundColor: darken(0.05, theme.colors.ui[2])
        }
    }),
    menu: base => ({
        ...base,
        borderRadius: '8px',
        fontSize: theme.fontSizes[2]
    }),
    menuList: base => ({ ...base }),
    input: base => ({
        ...base,
        backgroundColor: 'transparent',
        color: theme.colors.text[0],
        fontSize: theme.fontSizes[2],
        fontWeight: theme.fontWeights.bold
    }),
    selectContainer: base => ({
        ...base,
        backgroundColor: 'transparent',
        border: '2px solid white'
    }),
    container: base => ({
        ...base,
        backgroundColor: theme.colors.ui[2],
        borderRadius: '10px'
    }),
    singleValue: (base, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
            ...base,
            opacity,
            transition,
            color: theme.colors.text[0],
            fontWeight: 'bold'
        };
    }
};
