import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

import { toast, hideToast } from '../../redux/actions/toast';
import { Icon, Flex, Text } from '../lavender';
import * as selectors from '../../redux/reducers';

const mapStateToProps = state => ({
    open: selectors.getToastOpen(state),
    message: selectors.getToastMessage(state)
});

const mapDispatchToProps = {
    toast,
    hideToast
};

class Toast extends Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') return;
        this.props.hideToast();
    }

    render() {
        const { open, message } = this.props;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={open}
                autoHideDuration={6000}
                onClose={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id'
                }}
                message={
                    <Text
                        fontSize={2}
                        weight="bold"
                        color="ui.0"
                        id="message-id"
                    >
                        {message}
                    </Text>
                }
                action={[
                    <Flex
                        key="close"
                        alignItems="center"
                        onClick={this.handleClose}
                    >
                        <Icon size={2} name="x" color="white" />
                    </Flex>
                ]}
            />
        );
    }
}

Toast.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    hideToast: PropTypes.func.isRequired,
    toast: PropTypes.func.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Toast);
