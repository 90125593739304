import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field as ReduxField, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { login } from '../../redux/actions/auth';
import renderField from './renderField';
import { validateLogin } from '../../utils/validate';
import { Button, Box, Error } from '../lavender';

const mapDispatchToProps = {
    login
};

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        const { username, password } = values;
        return this.props.login({
            username,
            password
        });
    }

    render() {
        const { error, handleSubmit, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <Box>
                    <Box my={3}>
                        <ReduxField
                            name="username"
                            type="text"
                            component={renderField}
                            label="Username"
                        />
                    </Box>
                    <Box my={3}>
                        <ReduxField
                            name="password"
                            type="password"
                            component={renderField}
                            label="Password"
                        />
                    </Box>
                    {error && <Error message={error} />}
                    <Box my={3}>
                        <Button
                            label="Log In"
                            type="submit"
                            disabled={submitting}
                        />
                    </Box>
                </Box>
            </form>
        );
    }
}

LoginForm.propTypes = {
    login: PropTypes.func.isRequired,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool
};

export default reduxForm({
    form: 'loginForm', // a unique identifier for this form
    validateLogin
})(
    withRouter(
        connect(
            null,
            mapDispatchToProps
        )(LoginForm)
    )
);
