import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';

import CreateModelForm from './CreateModelForm';
import { Button, Loading, Text, Box } from '../../lavender';
import { DialogContent, DialogTitle, DialogContentText } from '../styles';
import * as selectors from '../../../redux/reducers';

const mapStateToProps = state => ({
    schema: selectors.getSchema(state),
    schemaLoading: selectors.getIsLoadingSchema(state)
});

class CreateModelDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.toggleDialog = this.toggleDialog.bind(this);
    }

    toggleDialog() {
        this.setState({ open: !this.state.open });
    }

    render() {
        const { schema, schemaLoading, card, group } = this.props;
        return (
            <Fragment>
                {card ? (
                    <Box onClick={this.toggleDialog} data-cy="create-model">
                        {card}
                    </Box>
                ) : (
                    <Box>
                        <Button
                            label="New model"
                            icon={
                                <Text color="white" fontSize={4} weight="xbold">
                                    +
                                </Text>
                            }
                            onClick={this.toggleDialog}
                            data-cy="create-model"
                        />
                    </Box>
                )}
                <Dialog
                    open={this.state.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    data-cy="create-model-dialog"
                >
                    <DialogContent>
                        <DialogTitle>Create New Model</DialogTitle>
                        <DialogContentText>
                            Here you can create one of many useful machine
                            learning models, including: Text Classifier, FAQ
                            Matcher, Intent Classifier, Date Parser, Sentiment
                            Detector, Regex Entity Extractor etc.
                        </DialogContentText>
                        {schemaLoading && !schema ? (
                            <div>
                                <Loading />
                                <Text>Loading schema...</Text>
                            </div>
                        ) : (
                            <CreateModelForm
                                schema={schema}
                                toggleDialog={this.toggleDialog}
                                group={group}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

CreateModelDialog.propTypes = {
    schema: PropTypes.object,
    schemaLoading: PropTypes.bool.isRequired,
    card: PropTypes.any,
    modelType: PropTypes.string,
    group: PropTypes.string
};

export default connect(mapStateToProps)(CreateModelDialog);
