import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../lavender';

const DeleteIcon = ({ hover, onClick, ...props }) => (
    <Icon
        mr={4}
        size={4}
        ml="auto"
        color="support.0"
        css={{
            visibility: hover ? 'visible' : 'hidden'
        }}
        name="delete"
        onClick={onClick}
        data-cy="delete"
        {...props}
    />
);

DeleteIcon.propTypes = {
    hover: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};

export default DeleteIcon;
