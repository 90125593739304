import React from 'react';
import { Card as Base } from 'rebass';

const Card = props => {
    return (
        <Base
            bg="white"
            borderRadius={8}
            boxShadow="0 2px 4px 0 rgba(176,180,209,.5)"
            {...props}
        />
    );
};

export default Card;
