import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import { Flex, Icon, Text } from '../lavender';

const NavItem = ({ name, icon, open, ...props }) => {
    return (
        <Tooltip title={name} placement="right" disableHoverListener={open}>
            <Flex
                alignItems="center"
                justifyContent={!open ? 'center' : 'undefined'}
                mx={open ? 2 : 0}
                css={{
                    height: '46px',
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: '#ff8dda',
                        color: 'black',
                        borderRadius: '10px'
                    }
                }}
                {...props}
            >
                <Icon ml={open ? 15 : 0} size={5} color="white" name={icon} />
                {open && (
                    <Text pl={15} fontSize={3} weight="bold" color="white">
                        {name}
                    </Text>
                )}
            </Flex>
        </Tooltip>
    );
};

NavItem.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    open: PropTypes.bool
};

export default withTheme(NavItem);
