export const lower = item =>
    item.hasOwnProperty('toLowerCase') ? item.toLowerCase() : item;

// Checks if a value has been entered, and if it exists in the samples' text array.
export const validateText = (text, texts) => {
    if (!text) return false;
    return !texts.find(({ text: item }) => item === text);
};

// Checks if a value has been entered, and if it exists in the samples' text array.
export const validateSynonym = (synonym, sample) => {
    if (!synonym) return false;
    return !sample.entity_list[0].syn_set.find(item => item === synonym);
};

// Checks if label or text has been entered, and if a sample with that label already exists,
// only if that is true, then does it check in the samples' text array.
export const validateSample = (label, text, samples) => {
    if (!label || !text) return false;
    if (!samples[label]) return true;
    return !samples[label].texts.find(({ text: item }) => item === text);
};

export const isClassifier = modelType =>
    modelType === 'text_classifier' ||
    modelType === 'intent_classifier' ||
    modelType === 'faq_matcher' ||
    modelType === 'crf_entity_extractor' ||
    modelType === 'synonym_entity_extractor';

export const isExtractor = modelType =>
    modelType === 'regex_entity_extractor' ||
    modelType === 'sentiment_detector' ||
    modelType === 'date_parser' ||
    modelType === 'duckling_entity_extractor' ||
    modelType === 'language_recogniser';

export const domainFromParams = props => props.match.params.domainId;

export const whichService = service => {
    if (service.includes('playground')) return 'Playground';
    if (service.includes('dev')) return 'Develop';
    return 'Production';
};
