import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Text, Box, SelectBox } from '../../lavender';
import { train, getModel, setAlgorithm } from '../../../redux/actions/models';
import { styles } from '../SamplesView/selectStyles';

import * as selectors from '../../../redux/reducers';

const mapDispatchToProps = {
    train,
    getModel,
    setAlgorithm
};

const mapStateToProps = state => ({
    algorithm: selectors.getActiveModelAlgorithm(state)
});

class Training extends Component {
    constructor(props) {
        super(props);
        this.state = {
            algorithm: this.props.model.clsfr_algorithm
        };
        this.changeAlgorithm = this.changeAlgorithm.bind(this);
        this.updated = this.updated.bind(this);
    }

    updated() {
        const { algorithm } = this.state;
        const oldAlgorithm = this.props.model.clsfr_algorithm;

        return oldAlgorithm !== algorithm;
    }

    changeAlgorithm(value) {
        if (value.value !== this.props.algorithm) {
            this.props.setAlgorithm(value.value);
            this.setState({ algorithm: value.value });
        }
    }

    render() {
        const { schema } = this.props;
        const { modelType } = this.props.match.params;
        const { algorithm } = this.state;
        const modelSchema = schema && schema.train && schema.train[modelType];
        const hasAlgorithm =
            modelSchema && modelSchema.hasOwnProperty('clsfr_algorithm');
        const algorithms = schema && schema.classifier_algorithms;
        return (
            <Fragment>
                {!hasAlgorithm && (
                    <Box p={1}>
                        <Text>This model has no training settings.</Text>
                    </Box>
                )}
                {hasAlgorithm && (
                    <Box p={1} mr={6}>
                        <Text my={3} weight="bold" fontSize={4}>
                            Classifier Algorithm
                        </Text>
                        <Text color="text.1" fontSize={2} mb={20}>
                            The different algorithms one can set this model to
                            use when training.
                        </Text>
                        <SelectBox
                            id="clsfr_algorithm"
                            width={300}
                            styles={styles}
                            options={algorithms}
                            defaultValue={algorithms.find(
                                ({ value }) => value === algorithm
                            )}
                            onChange={this.changeAlgorithm}
                        />
                    </Box>
                )}

                {this.updated() && (
                    <Box
                        bg="#f8df4f"
                        my={3}
                        p={3}
                        css={{ borderRadius: '8px' }}
                    >
                        <Text color="#043f38" weight="medium">
                            Don&apos;t forget to train your model with the
                            updated Classifier Algorithm!
                        </Text>
                    </Box>
                )}
            </Fragment>
        );
    }
}

Training.propTypes = {
    model: PropTypes.shape({
        clsfr_algorithm: PropTypes.string
    }),
    algorithm: PropTypes.string.isRequired,
    setAlgorithm: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }).isRequired,
    train: PropTypes.func.isRequired,
    getModel: PropTypes.func.isRequired,
    schema: PropTypes.shape({
        train: PropTypes.object
    })
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Training)
);
