import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router';

import { Text } from '../../../../lavender';
import { addSamples } from '../../../../../redux/actions/samples';
import { EditableDiv } from '../../../../EditableDiv';
import * as selectors from '../../../../../redux/reducers';
import { validateText } from '../../../../../utils';
import HoverCard from '../AddSample/HoverCard';

export const ConfirmAdd = props => (
    <Text
        css={{
            width: 200,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#11e275',
                color: 'white',
                borderRadius: '8px'
            }
        }}
        p={2}
        mx={2}
        my={1}
        weight="bold"
        fontWeight="medium"
        fontSize={3}
        color="support.1"
        {...props}
    />
);

const mapStateToProps = (state, prevProps) => ({
    sample: selectors.getSampleById(state, prevProps.label)
});

const mapDispatchToProps = dispatch => ({
    addSample: payload => dispatch(addSamples(payload))
});

export class AddUtterance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            utterance: '',
            element: null
        };
    }
    onHoverOver = () => {
        this.setState({ hover: true });
    };

    onHoverOut = () => {
        this.setState({ hover: false });
    };

    handleAdd = () => {
        const { utterance, element } = this.state;
        const { label, sampleType, texts } = this.props;

        if (validateText(utterance, texts)) {
            const sample = { label, text: utterance, type: sampleType };
            this.props
                .addSample({
                    ...this.props.match.params,
                    samples: [sample]
                })
                .then(() => (element.innerText = ''));
            this.setState({ utterance: '' });
            element.blur();
        }
    };

    onInput = event => {
        const utterance = event.target.innerText;
        this.setState({ utterance, element: event.target });
    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleAdd();
        }
    };

    render() {
        const { theme, label } = this.props;
        const { utterance } = this.state;
        return (
            <HoverCard
                onHoverOver={this.onHoverOver}
                onHoverOut={this.onHoverOut}
                theme={theme}
                pl={3}
            >
                <EditableDiv
                    placeholder="Type your training example here..."
                    onInput={this.onInput}
                    onKeyPress={this.handleKeyPress}
                    fontSize={3}
                    py={25}
                    pl={40}
                    data-cy={`type-${label}-text`}
                />
                {utterance && (
                    <ConfirmAdd onClick={this.handleAdd}>
                        Add Example
                    </ConfirmAdd>
                )}
            </HoverCard>
        );
    }
}

AddUtterance.propTypes = {
    sample: PropTypes.object,
    texts: PropTypes.array,
    label: PropTypes.string.isRequired,
    sampleType: PropTypes.string.isRequired,
    addSample: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    })
};

export default withRouter(
    withTheme(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddUtterance)
    )
);
