import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Fuse from 'fuse.js';
import { CSVLink } from 'react-csv';

import Samples from '../SamplesView/Samples';
import {
    Button,
    Flex,
    Popover,
    Text,
    Icon,
    SelectBox,
    Box
} from '../../lavender';
import DeleteAllSamplesDialog from '../../Dialogs/DeleteAllSamplesDialog';
import UploadSamplesDialog from '../../Dialogs/UploadSamplesDialog';
import SearchInput from '../../SearchInput';
import { LoadingIndicator } from '../../Loading';
import { deleteAllSamples } from '../../../redux/actions/samples';
import { stringCompare } from '../../../utils/stringCompare';
import * as selectors from '../../../redux/reducers/';
import MoreIcon from '../../Icons/MoreIcon';
import { styles } from './selectStyles';
import UploadCRFEntitiesDialog from '../../Dialogs/UploadCRFEntitiesDialog';
import { isClassifier } from '../../../utils';
import { sortingOptions, samplesOptions } from '../../../config';
import Pagination from '../../Pagination';

export const SamplesHeader = props => (
    <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent={'space-between'}
        alignItems="center"
        my={2}
        {...props}
    />
);

export const SamplesContainer = props => <Box mt={3} {...props} />;

const fuseOptions = {
    shouldSort: true,
    threshold: 0.3,
    includeMatches: true,
    matchAllTokens: true,
    tokenize: true,
    location: 0,
    distance: 1000,
    maxPatternLength: 1000,
    minMatchCharLength: 4,
    keys: ['label', 'texts.text']
};

const getModelType = props => props.match.params.modelType;

const processSamples = samples =>
    samples.reduce((acc, item) => {
        let { label, ...rest } = item;
        acc[label] = acc[label]
            ? {
                  ...acc[label],
                  texts: [...acc[label].texts, { ...rest }]
              }
            : {
                  label,
                  texts: [{ ...rest }]
              };
        return acc;
    }, {});

const getSamplePairsForExport = samples =>
    Object.values(processSamples(Object.values(samples))).reduce(
        (acc, item) => [
            ...item.texts.map(
                ({ text }) => ({ label: item.label, text }),
                item.label
            ),
            ...acc
        ],
        []
    );

const mapStateToProps = (state, prevProps) => ({
    loading: selectors.getIsLoadingSamples(state),
    // Returning an array of objects for Fuse.js to index on.
    samples: Object.values(selectors.getAllSamplesByIds(state)),
    sampleExport:
        isClassifier(getModelType(prevProps)) &&
        getModelType(prevProps) !== 'crf_entity_extractor'
            ? getSamplePairsForExport(selectors.getAllSamplesByIds(state))
            : []
});

const mapDispatchToProps = dispatch => ({
    deleteAllSamples: payload => dispatch(deleteAllSamples(payload))
});

class SamplesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sampleType: 'training',
            query: '',
            sort: 'default',
            currentPage: 1,
            totalPages: null,
            pageLimit: 10000,
            currentSamples: [],
            samples: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.deleteAllSamples = this.deleteAllSamples.bind(this);
        this.handleJSONExport = this.handleJSONExport.bind(this);
        this.updateQuery = this.updateQuery.bind(this);
        this.changeSort = this.changeSort.bind(this);
        this.changeSampleType = this.changeSampleType.bind(this);
        this.exportSamplesFormat = this.exportSamplesFormat.bind(this);
        this.handleExport = this.handleExport.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { samples } = props;
        if (samples && samples !== state.samples) {
            return {
                ...state,
                samples: samples.filter(
                    sample => sample.type === state.sampleType
                )
            };
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        const { samples } = this.props;
        const { sampleType } = this.state;
        const { currentPage, totalPages, pageLimit } = this.state;
        const offset = (currentPage - 1) * pageLimit;
        const currentSamples = samples
            .filter(sample => sample.type === sampleType)
            .slice(offset, offset + pageLimit);
        let pageNumber = currentPage;
        if (samples && samples !== prevProps.samples) {
            if (samples.length > 0 && currentSamples.length === 0) {
                if (pageNumber > 1) {
                    pageNumber -= 1;
                }
            }
            this.onPageChanged({
                currentPage: pageNumber,
                totalPages,
                pageLimit
            });
        }
    }

    updateQuery(query) {
        this.setState({
            query
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    changeSort(value) {
        this.setState({
            sort: value.value,
            samples: this.state.samples.sort(stringCompare('label'))
        });
    }

    changeSampleType(value) {
        this.setState({
            sampleType: value.value
        });
    }

    deleteAllSamples() {
        this.props.deleteAllSamples({
            ...this.props.match.params,
            sample_type: this.state.sampleType
        });
    }

    exportSamplesFormat() {
        return this.props.samples.reduce(
            (acc, item) => [
                ...item.texts.map(
                    ({ text }) => ({ label: item.label, text }),
                    item.label
                ),
                ...acc
            ],
            []
        );
    }

    handleExport() {
        this.setState({ isExport: true });
    }

    handleJSONExport() {
        const {
            samples,
            match: {
                params: { modelName, modelType }
            }
        } = this.props;

        if (samples && samples.length) {
            const fileData = JSON.stringify(samples, null, 2);
            const blob = new Blob([fileData], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = `${modelName}-${modelType}.json`;
            link.href = url;
            link.click();
        }
    }

    onPageChanged = data => {
        const { samples, sampleType } = this.state;
        const { currentPage, totalPages, pageLimit } = data;

        const offset = (currentPage - 1) * pageLimit;
        const currentSamples = samples
            .filter(sample => sample.type === sampleType)
            .slice(offset, offset + pageLimit);
        this.setState({ currentPage, currentSamples, totalPages, pageLimit });
        return { currentPage, totalPages };
    };

    render() {
        const {
            sampleType,
            pageLimit,
            samples,
            currentPage,
            sort,
            query
        } = this.state;

        const {
            loading,
            // sampleExport,
            match: {
                params: { modelName, modelType }
            }
        } = this.props;

        const offset = (currentPage - 1) * pageLimit;
        const samplesOfType = samples.filter(
            sample => sample.type === sampleType
        );
        const lenSamplesOfType = samplesOfType.length;

        const currentSamples = samplesOfType.slice(offset, offset + pageLimit);

        let results = Object.values(processSamples(currentSamples));
        const fuse = new Fuse(results, fuseOptions); // "list" is the item array
        results = query
            ? fuse.search(query.trim()).map(({ item }) => item)
            : results;

        return (
            <Fragment>
                <SamplesHeader>
                    <Flex>
                        <SelectBox
                            width={125}
                            styles={styles}
                            options={samplesOptions}
                            defaultValue={samplesOptions.find(
                                ({ value }) => value === sampleType
                            )}
                            placeholder="Samples"
                            onChange={this.changeSampleType}
                        />
                        <SelectBox
                            pl={2}
                            width={125}
                            styles={styles}
                            options={sortingOptions}
                            defaultValue={sortingOptions.find(
                                ({ value }) => value === sort
                            )}
                            placeholder="Sort"
                            onChange={this.changeSort}
                        />
                    </Flex>
                    <Text ml={2} mr="auto" fontSize={3} weight="bold">
                        {lenSamplesOfType}&nbsp;
                        {sampleType === 'training' ? 'Training' : 'Testing'}
                        &nbsp; Samples
                    </Text>
                    <Flex alignItems="center">
                        <Box mx={2}>
                            {modelType === 'crf_entity_extractor' ? (
                                <UploadCRFEntitiesDialog
                                    sampleType={sampleType}
                                >
                                    <Button
                                        plain
                                        size="xs"
                                        label="Import"
                                        icon={
                                            <Icon
                                                color="text.1"
                                                name="import"
                                            />
                                        }
                                    />
                                </UploadCRFEntitiesDialog>
                            ) : (
                                <UploadSamplesDialog sampleType={sampleType}>
                                    <Button
                                        plain
                                        size="xs"
                                        label="Import"
                                        icon={
                                            <Icon
                                                color="text.1"
                                                name="import"
                                            />
                                        }
                                    />
                                </UploadSamplesDialog>
                            )}
                        </Box>
                        <Box mx={2}>
                            {modelType === 'crf_entity_extractor' ? (
                                <Button
                                    as="a"
                                    plain
                                    disabled={!samples.length}
                                    size="xs"
                                    label="Export"
                                    icon={<Icon color="text.1" name="export" />}
                                    onClick={this.handleJSONExport}
                                />
                            ) : (
                                <CSVLink
                                    // data={sampleExport}
                                    data={samplesOfType}
                                    filename={`${modelName}_${sampleType}_samples.csv`}
                                >
                                    <Button
                                        plain
                                        disabled={!lenSamplesOfType}
                                        size="xs"
                                        label="Export"
                                        icon={
                                            <Icon
                                                color="text.1"
                                                name="export"
                                            />
                                        }
                                    />
                                </CSVLink>
                            )}
                        </Box>
                        <Box mx={2}>
                            <SearchInput
                                width={150}
                                py={1}
                                fontSize={1}
                                dataCy="search-samples"
                                setQuery={this.updateQuery}
                            />
                        </Box>
                        <Popover
                            menu={[
                                {
                                    label: 'Delete',
                                    key: 'delete',
                                    action: (
                                        <DeleteAllSamplesDialog
                                            onConfirm={this.deleteAllSamples}
                                        >
                                            <Flex
                                                m={2}
                                                onClick={this.toggleDialog}
                                                alignItems="center"
                                            >
                                                <Icon
                                                    color="support.0"
                                                    size={1}
                                                    name="delete"
                                                />
                                                <Text
                                                    ml={2}
                                                    fontSize={1}
                                                    weight="medium"
                                                >
                                                    {sampleType === 'training'
                                                        ? 'Delete training samples'
                                                        : 'Delete testing samples'}
                                                </Text>
                                            </Flex>
                                        </DeleteAllSamplesDialog>
                                    )
                                }
                            ]}
                        >
                            <MoreIcon vertical />
                        </Popover>
                    </Flex>
                </SamplesHeader>
                {loading ? (
                    <LoadingIndicator message="Loading samples..." />
                ) : (
                    <SamplesContainer>
                        {lenSamplesOfType ? (
                            <Flex my={2} alignItems="center">
                                <Pagination
                                    totalRecords={lenSamplesOfType}
                                    pageLimit={pageLimit}
                                    neighbours={2}
                                    onPageChanged={this.onPageChanged}
                                />
                            </Flex>
                        ) : (
                            <span />
                        )}
                        <Samples
                            sampleType={sampleType}
                            samples={results}
                            crf={modelType === 'crf_entity_extractor'}
                        />
                    </SamplesContainer>
                )}
            </Fragment>
        );
    }
}

SamplesView.propTypes = {
    deleteAllSamples: PropTypes.func.isRequired,
    samples: PropTypes.array.isRequired,
    // sampleExport: PropTypes.array,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }).isRequired,
    loading: PropTypes.bool
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SamplesView)
);
