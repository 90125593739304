export const editableDomain = (state = null, { type, payload }) => {
    switch (type) {
        case `DOMAIN_CLEAR_EDITABLE`:
            return null;
        case `DOMAIN_SET_EDITABLE`:
            return payload;
        default:
            return state;
    }
};
