import { schema } from 'normalizr';

// define a items schema
const domain = new schema.Entity('domains');
export const domains = [domain];

const model = new schema.Entity(
    'models',
    {},
    {
        idAttribute: 'name',
        processStrategy: entity => ({
            ...entity,
            id: entity.name
        })
    }
);

export const models = [model];

const sample = new schema.Entity(
    'samples',
    {},
    {
        idAttribute: 'uuid'
    }
);

export const samples = [sample];
