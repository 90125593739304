import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field as ReduxField, reduxForm } from 'redux-form';

import Dialog from '@material-ui/core/Dialog';

import { validateDomain } from '../../../utils/validate';
import { Icon, Flex, Text, Box, Error } from '../../lavender';
import { editDomain } from '../../../redux/actions/domains';
import renderField from '../../Login/renderField';
import {
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    ActionButton
} from '../styles';
import { setActive } from '../../../redux/utils/actions/lasso';
import * as selectors from '../../../redux/reducers';

const mapDispatchToProps = dispatch => ({
    editDomain: payload => dispatch(editDomain(payload)),
    setActiveDomain: id => dispatch(setActive('domains')(id))
});

const mapStateToProps = state => ({
    initialValues: selectors.getActiveDomain(state) // pull initial values from account reducer
});

class EditDomainDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            domain: props.domain
        };
        this.toggleDialog = this.toggleDialog.bind(this);
    }

    componentDidMount() {
        this.forceUpdate();
    }

    toggleDialog() {
        !this.state.open && this.props.setActiveDomain(this.props.domain.id);
        this.setState({ open: !this.state.open });
    }

    render() {
        const {
            error,
            handleSubmit,
            submitting,
            domain,
            editDomain
        } = this.props;
        return (
            <Fragment>
                <Flex
                    m={3}
                    onClick={this.toggleDialog}
                    data-cy={`edit-domain-${domain.name}`}
                    alignItems="center"
                >
                    <Icon name="edit" />
                    <Text ml={2} fontSize={3} weight="medium">
                        Edit
                    </Text>
                </Flex>
                <Dialog open={this.state.open}>
                    <DialogContent>
                        <DialogTitle>Edit Domain</DialogTitle>
                        <DialogContentText>
                            All you need is a client api key, and the service
                            url to create a new domain. Once your domain is
                            created, you can then begin creating machine
                            learning models.
                        </DialogContentText>
                        <form
                            onSubmit={handleSubmit(values => {
                                editDomain(values);
                                this.toggleDialog();
                            })}
                        >
                            <Box>
                                <ReduxField
                                    name="name"
                                    type="text"
                                    component={renderField}
                                    label="Domain name..."
                                />
                                <ReduxField
                                    name="description"
                                    type="text"
                                    component={renderField}
                                    label="Domain description..."
                                />
                                <ReduxField
                                    name="api_key"
                                    type="text"
                                    component={renderField}
                                    label="API Key..."
                                />
                                <ReduxField
                                    name="service"
                                    type="text"
                                    component={renderField}
                                    label="EG. https://nlu.feersum.io"
                                />
                                {error && <Error message={error} />}
                                <DialogActions>
                                    <ActionButton
                                        label="Save"
                                        type="submit"
                                        disabled={submitting}
                                    />
                                    <ActionButton
                                        plain
                                        label="Cancel"
                                        type="button"
                                        onClick={this.toggleDialog}
                                        data-cy="close-dialog"
                                    />
                                </DialogActions>
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

EditDomainDialog.propTypes = {
    setActiveDomain: PropTypes.func,
    domain: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        api_key: PropTypes.string.isRequired,
        service: PropTypes.string.isRequired
    }).isRequired,
    editDomain: PropTypes.func,
    submitting: PropTypes.bool,
    error: PropTypes.any,
    handleSubmit: PropTypes.func
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: 'EditDomainDialog',
        validate: validateDomain,
        enableReinitialize: true
    })(EditDomainDialog)
);
