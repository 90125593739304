import Api from '../../middleware/api';

import { toast } from './toast';
import {
    request,
    requestSuccess,
    requestById,
    requestByIdSuccess,
    clearAll,
    requestCreate,
    clear
} from '../utils/actions/resource';
import { models as schema } from '../schema';
import { normalize } from 'normalizr';
import {
    setActive,
    trainStart,
    trainError,
    testStart,
    testError,
    setTsne,
    clearTsne,
    setLanguages,
    setLanguageModels,
    setClassifierAlgorithm
} from '../utils/actions/lasso';

const getLanguageModels = models =>
    Object.entries(models)
        .filter(model => model[1].model_type === 'sentence_encoder')
        .map(model => model[1]);

const processSimilarWords = words =>
    words.reduce((acc, word) => {
        acc[word] = {
            word
        };
        return acc;
    }, {});

export const getAllLanguageModels = payload => dispatch => {
    Api(dispatch)
        .getAllModels(payload)
        .then(models => {
            const entities = normalize(models.body.model_list, schema).entities;
            dispatch(
                setLanguageModels('models')(
                    getLanguageModels(entities.models || [])
                )
            );
        });
};

export const getAllModels = payload => dispatch => {
    dispatch(setActive('domains')(payload));
    dispatch(clearAll('samples')());
    dispatch(clearAll('models')());
    dispatch(request('models'));
    Api(dispatch)
        .getAllModels(payload)
        .then(models => {
            const entities = normalize(models.body.model_list, schema).entities;
            dispatch(
                setLanguageModels('models')(
                    getLanguageModels(entities.models || [])
                )
            );
            dispatch(requestSuccess('models')(entities.models || []));
        });
};

export const getModel = payload => dispatch => {
    const { modelName, modelType } = payload;
    dispatch(requestById('models')({ id: modelName }));
    Api(dispatch)
        .getModel(payload)
        .then(model => {
            dispatch(setActive('models')(model.body));
            dispatch(requestByIdSuccess('models')(model.body));
            if (modelType === 'text_classifier') {
                dispatch(
                    setLanguages('models')(
                        model.body.language_model_list.map(lang => ({
                            languageModel: lang.lang_model,
                            label: lang.lang_code
                        }))
                    )
                );
            } else if (
                modelType === 'intent_classifier' ||
                modelType === 'faq_matcher'
            ) {
                dispatch(
                    setLanguages('models')(
                        model.body.word_manifold_list.map(lang => ({
                            languageModel: lang.word_manifold,
                            label: lang.label
                        }))
                    )
                );
            }
        });
};

export const createModel = payload => dispatch => {
    dispatch(requestCreate('models')(payload));

    return Api(dispatch)
        .createModel(payload)
        .then(model => {
            dispatch(requestSuccess('models')(model.body));
            dispatch(toast('Model successfully created! 🎉'));
            return model;
        });
};

export const updateModel = payload => dispatch => {
    return Api(dispatch)
        .updateModel(payload)
        .then(model => {
            dispatch(toast('Model successfully updated! 🎉'));
            dispatch(requestByIdSuccess('models')(model.body));
            dispatch(getModel(payload));
        });
};

export const deleteModel = payload => dispatch => {
    return Api(dispatch)
        .deleteModel(payload)
        .then(() => {
            dispatch(clear('models')(payload.modelName));
            dispatch(toast('Model successfully deleted! 🎉'));
        });
};

export const train = payload => dispatch => {
    dispatch(trainStart());
    dispatch(toast('Training job submitted. 💪'));
    Api(dispatch)
        .train(payload)
        .then(() => {
            dispatch(getModel(payload));
            dispatch(toast('Training job successful. 🎉'));
        })
        .catch(err => {
            if (err.status === 400) {
                const errors = JSON.parse(err.response.text);
                dispatch(trainError(errors.train_error));
                dispatch(toast('Training error occurred. 😞'));
            }
        });
};

export const test = payload => dispatch => {
    dispatch(testStart());
    dispatch(toast('Testing job submitted. 💪'));
    Api(dispatch)
        .test(payload)
        .then(() => {
            dispatch(getModel(payload));
            dispatch(toast('Testing job successful. 🎉'));
        })
        .catch(err => {
            if (err.status === 400) {
                const errors = JSON.parse(err.response.text);
                dispatch(testError(errors.test_error));
                dispatch(toast('Testing error occurred. 😞'));
            }
        });
};

export const tsne = payload => dispatch => {
    dispatch(clearTsne('models')());
    dispatch(toast('Updating TSNE parameters...'));
    Api(dispatch)
        .tsne(payload)
        .then(response => {
            dispatch(toast('TSNE parameters updated. 🎉'));
            dispatch(setTsne('models')(response.body));
        });
};

export const languages = payload => dispatch => {
    dispatch(toast('Updating languages.'));
    dispatch(setLanguages('models')(payload));
    dispatch(toast('Languages updated'));
};

export const setAlgorithm = payload => dispatch => {
    dispatch(setClassifierAlgorithm('models')(payload));
    dispatch(toast('Updated Classifier Algorithm.'));
};
