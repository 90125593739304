import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import SampleField from '../../../../SampleField';
import LabelCard from '../Sample/LabelCard';
import * as actions from '../../../../../redux/actions/samples';
import * as selectors from '../../../../../redux/reducers';

const mapStateToProps = (state, prevProps) => ({
    samples: selectors.getAllSamplesIds(state),
    sample: selectors.getSampleById(state, prevProps.id)
});

const mapDispatchToProps = dispatch => ({
    editLabel: payload => dispatch(actions.editLabel(payload)),
    deleteCRFSample: payload => dispatch(actions.deleteCRFSample(payload))
});

class CRFSample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            text: props.id,
            original: props.id
        };
    }

    handleEdit = () => {
        // TODO
    };

    handleDelete = () => {
        const {
            match: { params },
            sampleType,
            sample,
            deleteCRFSample
        } = this.props;
        const { text } = this.state;

        deleteCRFSample({
            ...params,
            sample: [{ ...sample, type: sampleType }],
            id: text
        });
    };

    toggle = () => {
        this.setState({ open: !this.state.open });
    };

    valid = value => {
        const { samples } = this.props;
        if (value === '' || value === this.state.original) return false;
        return !samples.find(id => id === value);
    };

    render() {
        const { open } = this.state;
        const { sample, id } = this.props;

        return (
            <Fragment>
                <LabelCard key={id} data-cy={`label-card-${id}`}>
                    <SampleField
                        text={sample.text}
                        onEdit={this.handleEdit}
                        onDelete={this.handleDelete}
                        expandable={false}
                        toggle={this.toggle}
                        open={open}
                        divProps={{ fontWeight: 'medium', p: 1 }}
                        name="sample-utterance"
                        styleProps={{ pl: 10 }}
                        entityList={sample.entityList}
                    />
                </LabelCard>
            </Fragment>
        );
    }
}

CRFSample.propTypes = {
    id: PropTypes.string.isRequired,
    sample: PropTypes.shape({
        text: PropTypes.string.isRequired,
        entityList: PropTypes.arrayOf(
            PropTypes.shape({
                index: PropTypes.number.isRequired,
                len: PropTypes.number.isRequired,
                entity: PropTypes.string.isRequired
            })
        )
    }),
    samples: PropTypes.array.isRequired,
    sampleType: PropTypes.string.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }),
    deleteCRFSample: PropTypes.func
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CRFSample)
);
