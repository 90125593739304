import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Text } from '../lavender';
import { lighten } from 'polished';
import { hover } from './utils';

const NavItem = ({ item, group, onClick, ...props }) => {
    return (
        <Text
            key={item.name}
            color={group === item.key ? 'white' : undefined}
            p={2}
            onClick={() => onClick(item)}
            css={{
                borderRadius: '8px',
                cursor: 'pointer',
                color: props.theme.colors.text[0],
                ...hover(group, item.key, props.theme),
                '&:focus': {
                    color: 'white'
                },
                '&:hover': {
                    backgroundColor: lighten(0.05, props.theme.colors.brand[0]),
                    color: 'white'
                },
                '&:active': {
                    backgroundColor: lighten(0.05, props.theme.colors.brand[0]),
                    color: 'white'
                }
            }}
            fontSize={2}
            weight="bold"
            data-cy={`${item.key}-tab`}
        >
            {item.name}
        </Text>
    );
};

NavItem.propTypes = {
    item: PropTypes.object.isRequired,
    group: PropTypes.string,
    onClick: PropTypes.func,
    theme: PropTypes.object
};

export default withTheme(NavItem);
