import {
    REQUEST,
    REQUEST_ID,
    REQUEST_SUCCESS,
    REQUEST_ID_SUCCESS,
    REQUEST_FAILURE,
    REQUEST_ID_FAILURE,
    CLEAR,
    CLEAR_ALL,
    CLEAR_ERRORS,
    SET_COUNT,
    REQUEST_SLUG_SUCCESS,
    REQUEST_SLUG,
    REQUEST_SLUG_FAILURE,
    REQUEST_CREATE,
    REQUEST_UPDATE
} from '../actionTypes';

// kick off a network request to create a new entity
export const requestCreate = entity => item => ({
    type: `${REQUEST_CREATE}/${entity}`,
    payload: item
});
// kick off a network request to update an existing entity
export const requestUpdate = entity => item => ({
    type: `${REQUEST_UPDATE}/${entity}`,
    payload: item
});

// kick off a network request for a collection
export const request = entity => ({
    type: `${REQUEST}/${entity}`
});

export const requestSuccess = entity => list => ({
    type: `${REQUEST_SUCCESS}/${entity}`,
    payload: list
});

export const requestFailure = entity => list => ({
    type: `${REQUEST_FAILURE}/${entity}`,
    payload: list,
    error: list instanceof Error
});

// kick off a network request for a specific ID
export const requestById = entity => id => ({
    type: `${REQUEST_ID}/${entity}`,
    payload: { id }
});

export const requestByIdSuccess = entity => item => ({
    type: `${REQUEST_ID_SUCCESS}/${entity}`,
    payload: item
});

export const requestByIdFailure = entity => item => ({
    type: `${REQUEST_ID_FAILURE}/${entity}`,
    payload: item,
    error: item instanceof Error
});

// kick off a network request for a specific slug (if the backend supports this)
export const requestSlug = entity => slug => ({
    type: `${REQUEST_SLUG}/${entity}`,
    payload: { slug }
});

export const requestSlugSuccess = entity => item => ({
    type: `${REQUEST_SLUG_SUCCESS}/${entity}`,
    payload: item
});

export const requestSlugFailure = entity => item => ({
    type: `${REQUEST_SLUG_FAILURE}/${entity}`,
    payload: item,
    error: item instanceof Error
});

// clear an item out of the redux store
export const clear = entity => id => ({
    type: `${CLEAR}/${entity}`,
    payload: id
});

// clear all items out of the redux store
export const clearAll = entity => () => ({
    type: `${CLEAR_ALL}/${entity}`
});

// clear the errors on an entity
export const clearErrors = entity => () => ({
    type: `${CLEAR_ERRORS}/${entity}`
});

// set the count on a specific entity
export const setCount = entity => count => ({
    type: `${SET_COUNT}/${entity}`,
    payload: count
});
