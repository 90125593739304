import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import LabelCard from '../../SamplesView/Samples/Sample/LabelCard';
import SampleField from '../../../SampleField';
import * as actions from '../../../../redux/actions/samples';
import * as selectors from '../../../../redux/reducers';
import SampleBorder from '../../SamplesView/Samples/Sample/SampleBorder';
import { Card } from '../../../lavender';
import AddSynonym from '../AddSynonym';
import Synonym from '../Synonym';

const mapStateToProps = (state, prevProps) => ({
    samples: selectors.getAllSamplesIds(state),
    sample: selectors.getSampleById(state, prevProps.id)
});

const mapDispatchToProps = dispatch => ({
    editSample: payload => dispatch(actions.editSample(payload)),
    deleteSample: payload => dispatch(actions.deleteSample(payload))
});

class SynonymEntity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            label: props.id,
            original: props.id
        };
        this.toggle = this.toggle.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    toggle() {
        this.setState({ open: !this.state.open });
    }

    handleEdit(value) {
        const {
            sample,
            editSample,
            sampleType,
            match: { params }
        } = this.props;
        const entityList = sample.entity_list[0];

        return editSample({
            ...params,
            sample: [
                {
                    ...sample,
                    entity_list: [{ ...entityList, entity: value }],
                    type: sampleType
                }
            ]
        });
    }

    handleDelete() {
        const {
            sample,
            sampleType,
            deleteSample,
            match: { params }
        } = this.props;
        return deleteSample({
            ...params,
            sample: [{ ...sample, type: sampleType }]
        });
    }

    render() {
        const { open } = this.state;
        const { id, sampleType, sample } = this.props;
        const { entity, syn_set: synonyms } = sample.entity_list[0];

        return (
            <Fragment>
                <LabelCard data-cy={`label-card-${entity}`}>
                    <SampleField
                        text={entity}
                        onEdit={this.handleEdit}
                        onDelete={this.handleDelete}
                        expandable={true}
                        toggle={this.toggle}
                        open={open}
                        divProps={{ fontWeight: 'medium', p: 1 }}
                        name="sample-label"
                        styleProps={{ pl: 10 }}
                    />
                </LabelCard>
                {open && (
                    <SampleBorder>
                        <Card borderRadius={0} p={0}>
                            <AddSynonym
                                id={id}
                                label={entity}
                                sampleType={sampleType}
                            />
                            {synonyms.map((item, i) => (
                                <Synonym
                                    key={`${id}-${item}-${i}`}
                                    item={item}
                                    id={id}
                                    sampleType={sampleType}
                                />
                            ))}
                        </Card>
                    </SampleBorder>
                )}
            </Fragment>
        );
    }
}

SynonymEntity.propTypes = {
    id: PropTypes.string.isRequired,
    samples: PropTypes.array.isRequired,
    sample: PropTypes.object.isRequired,
    sampleType: PropTypes.string.isRequired,
    deleteSample: PropTypes.func.isRequired,
    editSample: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    })
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SynonymEntity)
);
