import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

// import CurationEntrySampleRow from '../CurationEntrySampleRow';
import ListItems from '../ListItems';
import { Highlight } from '../../../Highlight';
import { Text, Truncate, Box, Flex } from '../../../lavender';
import * as selectors from '../../../../redux/reducers';
import { Chevron } from '../../../SampleField';

const mapStateToProps = state => ({
    samples: selectors.getAllSamplesByIds(state),
    labels: selectors.getActiveModelCMLabelsLookup(state)
});

class CurationEntrySampleSet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            hover: false
        };
    }

    toggle = () => {
        this.setState({ open: !this.state.open });
    };

    onHoverOver = () => {
        this.setState({ hover: true });
    };

    onHoverOut = () => {
        this.setState({ hover: false });
    };

    render() {
        const { hover, open } = this.state;
        const { item, labelType } = this.props;
        const labels = this.props.labels;
        const sample_set = item.samples;

        return (
            <Box>
                <Flex
                    onMouseOver={this.onHoverOver}
                    onMouseOut={this.onHoverOut}
                    onClick={this.toggle}
                    data-cy={`open-curation-${labelType}`}
                >
                    <Box>
                        <ul>
                            {sample_set.map(sample => (
                                <li>
                                    <Text fontSize={3} weight="medium">
                                        {' '}
                                        {sample.text}{' '}
                                    </Text>
                                </li>
                            ))}
                        </ul>
                    </Box>
                </Flex>
            </Box>
        );
    }
}
//                    <ListItems
//                        Inner={CurationEntrySampleRow}
//                        items={samples}
//                        entry={item}
//                        labelType={labelType}
//                    />

CurationEntrySampleSet.propTypes = {
    samples: PropTypes.object.isRequired,
    open: PropTypes.bool,
    labels: PropTypes.object.isRequired,
    labelType: PropTypes.string.isRequired,

    item: PropTypes.shape({
        truth: PropTypes.string,
        predicted: PropTypes.string,
        metrics: PropTypes.object,
        samples: PropTypes.array
    }).isRequired
};

export default withRouter(connect(mapStateToProps)(CurationEntrySampleSet));
