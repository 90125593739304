import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from 'styled-system';

import { Text } from '../Text';

export const lineHeight = props => ({
    lineHeight: themeGet('lineHeights.headers', 1.5)(props)
});

const Heading = styled(Text)({ fontSize: 60 }, lineHeight);

Heading.displayName = 'Heading';

Heading.propTypes = {
    children: PropTypes.any,
    fontSize: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.array
    ]),
    align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
    underline: PropTypes.bool,
    caps: PropTypes.bool,
    regular: PropTypes.bool,
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    color: PropTypes.string,
    is: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    tertiary: PropTypes.bool,
    danger: PropTypes.bool,
    truncate: PropTypes.number
};

Heading.defaultProps = {
    fontSize: 50,
    bold: true,
    is: 'h2'
};

export default Heading;
