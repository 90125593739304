import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Card } from '../../../../lavender';
import Text from '../Text';
import AddUtterance from '../AddUtterance';
import SampleField from '../../../../SampleField';
import LabelCard from './LabelCard';
import * as actions from '../../../../../redux/actions/samples';
import * as selectors from '../../../../../redux/reducers';
import SampleBorder from './SampleBorder';

const mapStateToProps = state => ({
    samples: selectors.getAllSamplesIds(state)
});

const mapDispatchToProps = dispatch => ({
    editLabel: payload => dispatch(actions.editLabel(payload)),
    deleteLabel: payload => dispatch(actions.deleteLabel(payload))
});

class Sample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            label: props.id,
            original: props.id
        };
    }

    toggle = () => {
        this.setState({ open: !this.state.open });
    };

    valid = value => {
        const { samples } = this.props;
        if (value === '' || value === this.state.original) return false;
        return !samples.find(id => id === value);
    };

    handleEdit = value => {
        const {
            sampleType,
            match: { params },
            editLabel,
            texts
        } = this.props;

        if (this.valid(value)) {
            const sample = texts.map(item => ({
                uuid: item.uuid,
                label: value,
                text: item.text,
                type: sampleType
            }));

            return editLabel({
                ...params,
                sample
            });
        }
    };

    handleDelete = () => {
        const {
            match: { params },
            sampleType,
            deleteLabel,
            texts
        } = this.props;
        const { label } = this.state;

        deleteLabel({
            ...params,
            sample: texts.map(({ text }) => ({
                label: label,
                text: text,
                type: sampleType
            })),
            id: label
        });
    };

    render() {
        const { open } = this.state;
        const { sampleType, texts, id } = this.props;

        return (
            <Fragment>
                <LabelCard key={id} data-cy={`label-card-${id}`}>
                    <SampleField
                        text={id}
                        onEdit={this.handleEdit}
                        onDelete={this.handleDelete}
                        expandable={true}
                        toggle={this.toggle}
                        open={open}
                        divProps={{ fontWeight: 'medium', p: 1 }}
                        name="sample-utterance"
                        styleProps={{ pl: 10 }}
                    />
                </LabelCard>
                {open && (
                    <SampleBorder>
                        <Card borderRadius={0} p={0}>
                            <AddUtterance
                                label={id}
                                sampleType={sampleType}
                                texts={texts}
                            />
                            {texts.map(
                                item =>
                                    item && (
                                        <Text
                                            key={`${id}-${item.text}`}
                                            item={item}
                                            label={id}
                                            sampleType={sampleType}
                                            texts={texts}
                                        />
                                    )
                            )}
                        </Card>
                    </SampleBorder>
                )}
            </Fragment>
        );
    }
}

Sample.propTypes = {
    id: PropTypes.string.isRequired,
    samples: PropTypes.array.isRequired,
    sampleType: PropTypes.string.isRequired,
    deleteLabel: PropTypes.func.isRequired,
    editLabel: PropTypes.func.isRequired,
    texts: PropTypes.array.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    })
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Sample)
);
