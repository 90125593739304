import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../lavender';

const MoreIcon = ({ dataCy, vertical = false, horizontal = false }) => (
    <Icon
        size={6}
        m={1}
        name={vertical ? 'moreVert' : horizontal ? 'moreHoriz' : 'moreHoriz'}
        color="text.1"
        css={{
            '&:hover': {
                backgroundColor: '#dfe3e6',
                borderRadius: '15px'
            }
        }}
        data-cy={dataCy}
    />
);

MoreIcon.propTypes = {
    dataCy: PropTypes.string,
    vertical: PropTypes.bool,
    horizontal: PropTypes.bool
};

export default MoreIcon;
