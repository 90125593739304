import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../lavender';

const Duckling = ({ model }) => (
    <Text my={4} weight="medium" fontSize={3}>
        Use Live Assistant to test your {model.long_name} {model.model_group}
        &nbsp;samples
    </Text>
);

Duckling.propTypes = {
    model: PropTypes.shape({
        regex: PropTypes.string
    })
};

export default Duckling;
