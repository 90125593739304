import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import { Highlight } from '../../../Highlight';
import { Text, Truncate } from '../../../lavender';
import * as selectors from '../../../../redux/reducers';

const mapStateToProps = state => ({
    labels: selectors.getActiveModelCMLabelsLookup(state)
});

class CurationEntryHeader extends React.Component {
    render() {
        const entry = this.props.item;
        const { labels } = this.props;
        const { truth, predicted } = entry;

        return (
            <Fragment>
                <Text m={1} fontSize={3} weight="bold">
                    True label:&nbsp;
                    <Tooltip
                        enterDelay={300}
                        leaveDelay={300}
                        id="tooltip-icon"
                        title="The actual label of the utterance the model attempted to classify."
                        placement="top"
                    >
                        <Highlight truth>
                            <Truncate letters={50}>{labels[truth]}</Truncate>
                        </Highlight>
                    </Tooltip>
                    &nbsp;Predicted label:&nbsp;
                    <Tooltip
                        enterDelay={300}
                        leaveDelay={300}
                        id="tooltip-icon"
                        title="The model incorrectly attempted to predict the utterance as this label."
                        placement="top"
                    >
                        <Highlight predicted>
                            <Truncate letters={50}>
                                {labels[predicted]}
                            </Truncate>
                        </Highlight>
                    </Tooltip>
                </Text>
            </Fragment>
        );
    }
}

CurationEntryHeader.propTypes = {
    labels: PropTypes.object.isRequired,
    item: PropTypes.shape({
        truth: PropTypes.string.isRequired,
        predicted: PropTypes.string.isRequired
    })
};

export default connect(mapStateToProps)(CurationEntryHeader);
