import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Button, Input, Text, Box, Error } from '../../lavender';
import { updateModel, getModel } from '../../../redux/actions/models';

const mapDispatchToProps = {
    updateModel,
    getModel
};

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            threshold: this.props.model.threshold,
            temperature: this.props.model.temperature,
            setParams: {}
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.isValid = this.isValid.bind(this);
        this.isValidThreshold = this.isValidThreshold.bind(this);
        this.isValidTemperature = this.isValidTemperature.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.updated = this.updated.bind(this);
        this.errorMessage = this.errorMessage.bind(this);
    }

    componentDidMount() {
        const { modelType } = this.props.match.params;
        const { schema } = this.props;
        const updateParams = Object.keys(schema.update[modelType]);

        this.setState({
            setParams: {
                ...updateParams.reduce((a, b) => {
                    a[b] = '';
                    return a;
                }, {})
            }
        });
    }

    changeHandler({ target: { name, value } }) {
        this.setState({
            [name]: value
        });
    }

    isValid() {
        return this.isValidTemperature() || this.isValidThreshold();
    }

    isValidThreshold() {
        const { threshold } = this.state;
        const empty = typeof threshold === 'string' && threshold.trim() === '';
        return !empty && !isNaN(threshold) && threshold >= 0;
    }

    isValidTemperature() {
        const { temperature } = this.state;
        const empty =
            typeof temperature === 'string' && temperature.trim() === '';
        return !empty && !isNaN(temperature) && temperature >= 0;
    }

    handleSave() {
        const params = this.props.match.params;
        const threshold = parseFloat(this.state.threshold);
        const temperature = parseFloat(this.state.temperature);

        if (this.isValid()) {
            this.props.updateModel({
                ...params,
                payload: { threshold, temperature }
            });
        }
    }

    updated() {
        const { threshold, temperature } = this.state;
        const oldThreshold = this.props.model.threshold;
        const oldTemerature = this.props.model.temperature;

        return (
            oldThreshold !== parseFloat(threshold) ||
            oldTemerature !== parseFloat(temperature)
        );
    }

    errorMessage(value) {
        const empty = typeof value === 'string' && value.trim() === '';
        const message = empty
            ? 'This field is required'
            : isNaN(value)
            ? 'The threshold must be a valid floating point number.'
            : value < 0
            ? 'The threshold must be a number greater than 0.'
            : '';

        if (message) return <Error message={message} />;
    }

    render() {
        const { schema } = this.props;
        const { modelType } = this.props.match.params;
        const { threshold, temperature } = this.state;
        const modelSchema = schema.update[modelType];
        const hasThreshold = modelSchema.hasOwnProperty('threshold');
        const hasTemperature = modelSchema.hasOwnProperty('temperature');

        return (
            <Fragment>
                {!hasThreshold && (
                    <Box p={1}>
                        <Text>This model has no settings.</Text>
                    </Box>
                )}
                {hasThreshold && (
                    <Box p={1} mr={6}>
                        <Text my={3} weight="bold" fontSize={4}>
                            Classification Threshold
                        </Text>
                        <Text color="text.1" fontSize={2} mb={20}>
                            The match threshold value for the classifier. A
                            lower value will decrease the model recall, but
                            improve precision. The default value is 0.95.
                        </Text>

                        <Input
                            width={100}
                            error={!this.isValidThreshold()}
                            name="threshold"
                            value={threshold}
                            onChange={this.changeHandler}
                            placeholder="Enter a threshold..."
                        />
                        {this.errorMessage(threshold)}
                    </Box>
                )}
                {hasTemperature && (
                    <Box p={1} mr={6}>
                        <Text my={3} weight="bold" fontSize={4}>
                            Temperature
                        </Text>
                        <Text color="text.1" fontSize={2} mb={20}>
                            The softmax temperature value for the classifier.
                            Allows one to calibrate the output probabilities.
                            Lowering the temperature makes the model quicker to
                            swing to high confidence outputs. Increasing the
                            temperature makes the model output smoother and
                            slower to swing to high confidence outputs. The
                            default value is 1.0
                        </Text>

                        <Input
                            width={100}
                            error={!this.isValidTemperature()}
                            name="temperature"
                            value={temperature}
                            onChange={this.changeHandler}
                            placeholder="Enter a temperature..."
                        />
                        {this.errorMessage(temperature)}
                    </Box>
                )}
                {(hasThreshold || hasTemperature) && (
                    <Box px={1} py={3}>
                        <Button
                            label="Update"
                            disabled={!this.updated()}
                            onClick={() => this.handleSave()}
                        />
                    </Box>
                )}
            </Fragment>
        );
    }
}

Settings.propTypes = {
    model: PropTypes.shape({
        threshold: PropTypes.number,
        temperature: PropTypes.number
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }).isRequired,
    updateModel: PropTypes.func.isRequired,
    getModel: PropTypes.func.isRequired,
    schema: PropTypes.shape({
        update: PropTypes.object
    })
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(Settings)
);
