import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../../lavender';
import TimeAgo from 'react-timeago';

const TrainTimeAgo = ({ stamp }) => {
    return (
        <Text textAlign="center">
            {stamp ? <TimeAgo date={stamp} /> : 'Never'}
        </Text>
    );
};

TrainTimeAgo.propTypes = {
    stamp: PropTypes.string
};

export default TrainTimeAgo;
