import React from 'react';
import PropTypes from 'prop-types';

import { Box, Input, Error } from '../lavender';

const { any, bool, func, shape, string } = PropTypes;

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <Box my={3}>
        <Input
            error={touched && error}
            {...input}
            placeholder={label}
            type={type}
        />
        {touched && error && <Error mt={1} message={error} />}
    </Box>
);

export const fieldMetaPropTypes = {
    active: bool.isRequired,
    asyncValidating: bool.isRequired,
    autofilled: bool.isRequired,
    dirty: bool.isRequired,
    dispatch: func.isRequired,
    error: any,
    form: string.isRequired,
    invalid: bool.isRequired,
    pristine: bool.isRequired,
    submitting: bool.isRequired,
    submitFailed: bool.isRequired,
    touched: bool.isRequired,
    valid: bool.isRequired,
    visited: bool.isRequired,
    warning: string
};

export const fieldInputPropTypes = {
    checked: bool,
    name: string.isRequired,
    onBlur: func.isRequired,
    onChange: func.isRequired,
    onDragStart: func.isRequired,
    onDrop: func.isRequired,
    onFocus: func.isRequired,
    value: any
};

renderField.propTypes = {
    input: shape(fieldInputPropTypes).isRequired,
    meta: shape(fieldMetaPropTypes).isRequired,
    label: PropTypes.string,
    type: PropTypes.string
};

export default renderField;
