import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EditableDiv } from '../../../../EditableDiv';
import AddSampleCard from '../../../SamplesView/Samples/AddSample/AddSampleCard';
import AddSampleButton from '../../../SamplesView/Samples/AddSample/AddSampleButton';
import HoverCard from '../../../SamplesView/Samples/AddSample/HoverCard';
import { addSimilarWord } from '../../../../../redux/actions/similarWords';

const mapDispatchToProps = dispatch => ({
    addWord: payload => dispatch(addSimilarWord(payload))
});

class AddWord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            focused: false,
            element: null
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onHoverOver = this.onHoverOver.bind(this);
        this.onHoverOut = this.onHoverOut.bind(this);
        this.valid = this.valid.bind(this);
        this.onInputText = this.onInputText.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
    }

    onHoverOver() {
        this.setState({ hover: true });
    }

    onHoverOut() {
        this.setState({ hover: false });
    }

    valid() {
        const { words } = this.props;
        const { text } = this.state;
        if (text === '') return false;
        return !words.find(({ word }) => word === text);
    }

    onInputText(event) {
        const text = event.target.innerText.trim();
        this.setState({ text, element: event.target });
    }

    onBlur() {
        this.setState({ focused: false });
    }

    onFocus() {
        this.setState({ focused: true });
    }

    handleAdd() {
        const { text, element } = this.state;
        if (this.valid()) {
            this.props.addWord({ word: text });
            this.setState({ text: '' });
            element.innerText = '';
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleAdd();
        }
    }

    render() {
        const { focused, text } = this.state;
        return (
            <AddSampleCard focused={focused}>
                <HoverCard
                    onHoverOver={this.onHoverOver}
                    onHoverOut={this.onHoverOut}
                >
                    <EditableDiv
                        placeholder="Type your similar word text here..."
                        onInput={this.onInputText}
                        onKeyPress={this.handleKeyPress}
                        contentEditable="true"
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        width={1}
                        fontSize={3}
                        py={25}
                        pl={4}
                        data-cy={'type-word'}
                    />
                    {text && (
                        <AddSampleButton onClick={this.handleAdd}>
                            Add Sample
                        </AddSampleButton>
                    )}
                </HoverCard>
            </AddSampleCard>
        );
    }
}

AddWord.propTypes = {
    words: PropTypes.array.isRequired,
    addWord: PropTypes.func,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    })
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(AddWord)
);
