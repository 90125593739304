import Api from '../../middleware/api';

import { UPLOAD_START, UPLOAD_SUCCESS } from '../constants/actionTypes';
import { toast } from './toast';
import { request, requestSuccess, clear } from '../utils/actions/resource';
import { getModel } from './models';
import { samples as schema } from '../schema';
import { normalize } from 'normalizr';

export const uploadStart = () => ({
    type: UPLOAD_START
});

export const uploadSuccess = () => ({
    type: UPLOAD_SUCCESS
});

export const upload = payload => dispatch => {
    dispatch(uploadStart());
    Api(dispatch)
        .upload(payload)
        .then(() => {
            dispatch(getSamples(payload));
        });
    dispatch(uploadSuccess());
};

export const getSamples = payload => dispatch => {
    dispatch(request('samples'));
    Api(dispatch)
        .getSamples(payload)
        .then(response => {
            let normalizedData = normalize(response.body, schema);
            Object.keys(normalizedData.entities).length
                ? Object.keys(normalizedData.entities).map(key =>
                      dispatch(
                          requestSuccess(key)(normalizedData.entities[key])
                      )
                  )
                : dispatch(requestSuccess('samples')([]));
        });
};

export const addSamples = payload => dispatch => {
    return Api(dispatch)
        .addSamples(payload)
        .then(response => {
            let normalizedData = normalize(response.body, schema);
            Object.keys(normalizedData.entities).map(key =>
                dispatch(requestSuccess(key)(normalizedData.entities[key]))
            );
            dispatch(toast('Samples added. 🎉'));
        });
};

export const editSample = payload => dispatch => {
    return Api(dispatch)
        .editSample(payload)
        .then(response => {
            let normalizedData = normalize(response.body, schema);
            Object.keys(normalizedData.entities).map(key =>
                dispatch(requestSuccess(key)(normalizedData.entities[key]))
            );
            dispatch(toast("You edited a sample's text. 🎉"));
        });
};

export const editLabel = payload => dispatch => {
    return Api(dispatch)
        .editSample(payload)
        .then(response => {
            let normalizedData = normalize(response.body, schema);
            Object.keys(normalizedData.entities).map(key =>
                dispatch(requestSuccess(key)(normalizedData.entities[key]))
            );
            dispatch(toast('You edited a label. 🎉'));
        });
};

export const deleteLabel = payload => dispatch => {
    Api(dispatch)
        .deleteSamples(payload)
        .then(response => {
            let normalizedData = normalize(response.body, schema);
            Object.keys(normalizedData.entities).map(key => {
                normalizedData.result.map(id => dispatch(clear(key)(id)));
            });
            dispatch(
                toast(
                    `Sample${payload.sample.length > 1 ? 's' : ''} deleted. 🎉`
                )
            );
        });
};

export const deleteCRFSample = payload => dispatch => {
    Api(dispatch)
        .deleteSamples(payload)
        .then(() => {
            payload.sample.forEach(item => dispatch(clear('samples')(item)));
            dispatch(toast('Samples deleted. 🎉'));
        });
};

export const deleteSample = payload => dispatch => {
    Api(dispatch)
        .deleteSamples(payload)
        .then(response => {
            let normalizedData = normalize(response.body, schema);
            Object.keys(normalizedData.entities).map(key => {
                normalizedData.result.map(id => dispatch(clear(key)(id)));
            });
            dispatch(toast('Samples deleted. 🎉'));
        });
};

export const deleteAllSamples = payload => dispatch => {
    Api(dispatch)
        .deleteAllSamples(payload)
        .then(response => {
            let normalizedData = normalize(response.body, schema);
            Object.keys(normalizedData.entities).map(key =>
                normalizedData.result.map(id => dispatch(clear(key)(id)))
            );
            dispatch(toast('Samples deleted. 🎉'));
        });
};
