import React from 'react';
import PropTypes from 'prop-types';
import ConfusionMatrix from './ConfusionMatrix';
// import TSNE from './TSNE';
import { Box } from '../../lavender';

const Analytics = ({ model }) => (
    <Box mx="auto">
        {model.hasOwnProperty('training_confusion_matrix') &&
            model.training_confusion_matrix.length && (
                <ConfusionMatrix training />
            )}
        {model.hasOwnProperty('testing_confusion_matrix') &&
            model.testing_confusion_matrix.length && (
                <ConfusionMatrix testing />
            )}
    </Box>
);

Analytics.propTypes = {
    model: PropTypes.object
};

export default Analytics;
