import styled from 'styled-components';
import {
    space,
    width,
    fontSize,
    color,
    borders,
    borderRadius,
    borderColor
} from 'styled-system';

import { error, defaultStyles, focus } from '../Input';

const css = props => props.css;

export const TextArea = styled('textarea')(
    defaultStyles,
    space,
    borders,
    borderColor,
    borderRadius,
    width,
    fontSize,
    color,
    css,
    error,
    focus
);

TextArea.propTypes = {
    ...space.propTypes,
    ...width.propTypes,
    ...fontSize.propTypes,
    ...color.propTypes,
    ...borders.propTypes,
    ...borderColor.propTypes,
    ...borderRadius.propTypes
};

TextArea.defaultProps = {
    borderRadius: 6,
    px: 3,
    py: 2,
    m: 0,
    bg: 'white',
    fontSize: 3,
    width: 1
};

TextArea.displayName = 'TextArea';

export default TextArea;
