/*eslint one-var: ["error", "always"]*/
export const REDIRECT = 'REDIRECT',
    // Schema
    SCHEMA_GET = 'SCHEMA_GET',
    SCHEMA_GET_SUCCESS = 'SCHEMA_GET_SUCCESS',
    LOAD_SCHEMA = 'LOAD_SCHEMA',
    CLEAR_SCHEMA = 'CLEAR_SCHEMA',
    // Auth
    USER_LOGOUT = 'USER_LOGOUT',
    LOGIN = 'LOGIN',
    REGISTER = 'REGISTER',
    AUTH_LOGIN_USER_SUCCESS = 'AUTH_LOGIN_USER_SUCCESS',
    TOKEN_EXPIRED = 'TOKEN_EXPIRED',
    GET_TOKEN = 'GET_TOKEN',
    // Curate
    GET_CURATE = 'GET_CURATE',
    CURATE_SUCCESS = 'CURATE_SUCCESS',
    // Upload
    UPLOAD_START = 'UPLOAD_START',
    UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
    // Toast
    SHOW_TOAST = 'SHOW_TOAST',
    HIDE_TOAST = 'HIDE_TOAST';
