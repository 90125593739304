import React, { Component } from 'react';
import { withRouter, Route } from 'react-router';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { Flex, Cluster, Text } from '../../lavender';
import NavItem from './NavItem';
import { modelNavItems, modelNavItemsExtractors } from '../../../config';
import { isClassifier } from '../../../utils';

// A value > 0.8 implies that the accuracy of the model is good. Between 0.5 and 0.8,
// fair and lower than 0.5 poor. Colors are updated accordingly.
const accuracyColor = value =>
    value >= 0.9 ? 'support.1' : value >= 0.5 ? 'support.2' : 'support.0';

class ModelNav extends Component {
    render() {
        const {
            training_accuracy,
            testing_accuracy,
            training_f1,
            testing_f1,
            testing_precision,
            testing_recall,
            training_curated_list,
            testing_curated_list,
            model_group
        } = this.props.model;
        const errors_int = testing_curated_list
            ? testing_curated_list.length
            : 0;
        const errors_str = errors_int <= 9 ? errors_int.toString() : '9+';

        const { modelType } = this.props.match.params;
        const navItems = isClassifier(modelType)
            ? modelNavItems
            : modelNavItemsExtractors;
        return (
            <Flex>
                <Flex ml={28} bg="white" py={3}>
                    <Cluster gutter="s" alignment="left">
                        {navItems.map((item, i) => (
                            <Route
                                key={(item.name, i)}
                                path={`/model/:domainId/:modelName/:modelType/:tab/`}
                                component={({ match: { params } }) => (
                                    <NavItem
                                        key={item.name + i}
                                        item={item}
                                        params={params}
                                        badge={
                                            item.name === 'Curation'
                                                ? errors_str
                                                : null
                                        }
                                    />
                                )}
                            />
                        ))}
                    </Cluster>
                </Flex>
                {model_group == 'classifier' &&
                    modelType !== 'synonym_entity_extractor' && (
                        <Flex
                            width={0.21}
                            flexDirection="column"
                            ml="auto"
                            mr={3}
                        >
                            <Tooltip title="Measured on the test set, the recall indicates the fraction of samples the model's confidence was above the set threshold while the precision indicates the accuracy of those high confidence 'matched' responses.">
                                <Flex alignItems="center">
                                    <Text
                                        mr={2}
                                        weight="bold"
                                        color="text.1"
                                        fontSize={1}
                                    >
                                        Precision & Recall =
                                    </Text>
                                    <Text
                                        mr={2}
                                        color={accuracyColor(testing_precision)}
                                        fontSize={1}
                                        weight="bold"
                                    >
                                        {(testing_precision * 100).toFixed(1)}
                                    </Text>
                                    <Text
                                        mr={2}
                                        weight="bold"
                                        color="text.1"
                                        fontSize={1}
                                    >
                                        ,
                                    </Text>
                                    <Text
                                        mr={2}
                                        color={accuracyColor(testing_recall)}
                                        fontSize={1}
                                        weight="bold"
                                    >
                                        {(testing_recall * 100).toFixed(1)}
                                    </Text>
                                </Flex>
                            </Tooltip>
                            <Tooltip title="Training Accuracy & F1 - The training accuracy shows how many training samples were correctly predicted while F1-score = 2 * (training_precision * training_recall)/(training_precision + training_recall). Note: Training stats are usually overly optimistic.">
                                <Flex alignItems="center">
                                    <Text
                                        mr={2}
                                        weight="bold"
                                        color="text.1"
                                        fontSize={1}
                                    >
                                        Training Acc & F1 =
                                    </Text>
                                    <Text
                                        mr={2}
                                        color="text.1"
                                        fontSize={1}
                                        weight="bold"
                                    >
                                        {(training_accuracy * 100).toFixed(1)}
                                    </Text>
                                    <Text
                                        mr={2}
                                        weight="bold"
                                        color="text.1"
                                        fontSize={1}
                                    >
                                        ,
                                    </Text>
                                    <Text
                                        mr={2}
                                        color="text.1"
                                        fontSize={1}
                                        weight="bold"
                                    >
                                        {(training_f1 * 100).toFixed(1)}
                                    </Text>
                                </Flex>
                            </Tooltip>
                        </Flex>
                    )}
            </Flex>
        );
    }
}

ModelNav.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            tab: PropTypes.string,
            modelType: PropTypes.string.isRequired
        })
    }),
    model: PropTypes.object
};

export default withRouter(ModelNav);
