import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { CSVLink } from 'react-csv';
import Fuse from 'fuse.js';

import * as selectors from '../../../redux/reducers';
import { SamplesHeader, SamplesContainer } from '../SamplesView';
import { Text, Flex, Button, Icon, Popover, SelectBox } from '../../lavender';
import { SearchInput } from '../../SearchInput';
import { DeleteAllSamplesDialog, UploadSamplesDialog } from '../../Dialogs';
import MoreIcon from '../../Icons/MoreIcon';
import { styles } from '../SamplesView/selectStyles';
import { sortingOptions, samplesOptions } from '../../../config';
import { LoadingIndicator } from '../../Loading';
import SynonymEntity from './SynonymEntity';
import { deleteAllSamples } from '../../../redux/actions/samples';
import AddSynonymEntity from './AddSynonymEntity';
import { stringCompare } from '../../../utils/stringCompare';
import Pagination from '../../Pagination';

const fuseOptions = {
    shouldSort: true,
    threshold: 0.3,
    includeMatches: true,
    matchAllTokens: true,
    tokenize: true,
    location: 0,
    distance: 1000,
    maxPatternLength: 1000,
    minMatchCharLength: 4,
    keys: ['entity', 'synonyms']
};

const processSamples = samples =>
    samples.reduce((acc, item) => {
        let { entity_list, uuid, type } = item;
        const { entity, syn_set } = entity_list[0];
        acc[uuid] = {
            entity,
            synonyms: syn_set,
            uuid,
            type
        };
        return acc;
    }, {});

const exportSamplesFormat = samples =>
    Object.values(samples).reduce((acc, item) => {
        const { entity, syn_set } = item.entity_list[0];
        return [{ entity, synonyms: syn_set }, ...acc];
    }, []);

const mapStateToProps = state => ({
    loading: selectors.getIsLoadingSamples(state),
    samples: Object.values(selectors.getAllSamplesByIds(state)),
    sampleExport: exportSamplesFormat(selectors.getAllSamplesByIds(state))
});

const mapDispatchToProps = dispatch => ({
    deleteAllSamples: payload => dispatch(deleteAllSamples(payload))
});

class Synonyms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sampleType: 'training',
            query: '',
            sort: 'default',
            currentPage: 1,
            totalPages: null,
            pageLimit: 100,
            currentSamples: [],
            samples: [],
            indexedSamples: []
        };
        this.deleteAllSamples = this.deleteAllSamples.bind(this);
        this.updateQuery = this.updateQuery.bind(this);
        this.changeSort = this.changeSort.bind(this);
        this.changeSampleType = this.changeSampleType.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { samples } = props;

        if (samples && samples !== state.samples) {
            return {
                ...state,
                samples: samples.filter(
                    sample => sample.type === state.sampleType
                ),
                indexedSamples: Object.values(
                    processSamples(
                        samples.filter(
                            sample => sample.type === state.sampleType
                        )
                    )
                )
            };
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        const { samples } = this.props;
        const { currentPage, totalPages, pageLimit, sampleType } = this.state;
        const offset = (currentPage - 1) * pageLimit;
        const currentSamples = samples
            .filter(sample => sample.type === sampleType)
            .slice(offset, offset + pageLimit);
        let pageNumber = currentPage;
        if (samples && samples !== prevProps.samples) {
            if (samples.length > 0 && currentSamples.length === 0) {
                if (pageNumber > 1) {
                    pageNumber -= 1;
                }
            }
            this.onPageChanged({
                currentPage: pageNumber,
                totalPages,
                pageLimit
            });
        }
    }

    onPageChanged = data => {
        const { samples, sampleType } = this.state;
        const { currentPage, totalPages, pageLimit } = data;

        const offset = (currentPage - 1) * pageLimit;
        const currentSamples = samples
            .filter(sample => sample.type === sampleType)
            .slice(offset, offset + pageLimit);
        this.setState({
            currentPage,
            currentSamples,
            totalPages,
            pageLimit
        });
        return { currentPage, totalPages };
    };

    deleteAllSamples() {
        const {
            match: { params }
        } = this.props;
        const { sampleType } = this.state;

        this.props.deleteAllSamples({
            ...params,
            sample_type: sampleType
        });
    }

    updateQuery(query) {
        this.setState({
            query
        });
    }

    changeSort(value) {
        const { samples } = this.state;
        const sort = value.value;
        this.setState({
            sort,
            samples:
                sort === 'az' ? samples.sort(stringCompare('entity')) : samples
        });
    }

    changeSampleType(value) {
        this.setState({
            sampleType: value.value
        });
    }

    render() {
        const {
            sampleType,
            pageLimit,
            samples,
            currentPage,
            sort,
            query,
            indexedSamples
        } = this.state;
        const {
            loading,
            sampleExport,
            match: {
                params: { modelName, modelType }
            }
        } = this.props;

        const offset = (currentPage - 1) * pageLimit;
        const currentSamples = samples
            .filter(sample => sample.type === sampleType)
            .slice(offset, offset + pageLimit);
        let results = Object.values(processSamples(currentSamples));
        const fuse = new Fuse(indexedSamples, fuseOptions); // "list" is the item array
        results = query
            ? fuse.search(query.trim()).map(({ item }) => item)
            : results;
        const totalSamples = query
            ? results.length
            : samples.filter(sample => sample.type === sampleType).length;

        return (
            <Fragment>
                <SamplesHeader>
                    <Text ml={2} mr="auto" fontSize={3} weight="bold">
                        {totalSamples}&nbsp;
                        {sampleType === 'training' ? 'Training' : 'Testing'}
                        &nbsp; Samples
                    </Text>
                    <Flex alignItems="center">
                        <UploadSamplesDialog sampleType={sampleType}>
                            <Button
                                plain
                                size="xs"
                                label="Import"
                                icon={<Icon color="text.1" name="import" />}
                            />
                        </UploadSamplesDialog>
                        <CSVLink
                            data={sampleExport}
                            uFEFF={false}
                            filename={`${modelName}_${modelType}.csv`}
                        >
                            <Button
                                plain
                                disabled={!samples.length}
                                size="xs"
                                label="Export"
                                icon={<Icon color="text.1" name="export" />}
                            />
                        </CSVLink>
                        <SearchInput
                            width={150}
                            py={1}
                            fontSize={1}
                            dataCy="search-samples"
                            setQuery={this.updateQuery}
                            disableKeyPress
                        />
                        <Popover
                            menu={[
                                {
                                    label: 'Delete',
                                    key: 'delete',
                                    action: (
                                        <DeleteAllSamplesDialog
                                            onConfirm={this.deleteAllSamples}
                                        >
                                            <Flex
                                                m={2}
                                                onClick={this.toggleDialog}
                                                alignItems="center"
                                            >
                                                <Icon
                                                    color="support.0"
                                                    size={1}
                                                    name="delete"
                                                />
                                                <Text
                                                    ml={2}
                                                    fontSize={1}
                                                    weight="medium"
                                                >
                                                    Delete all Synonym Samples
                                                </Text>
                                            </Flex>
                                        </DeleteAllSamplesDialog>
                                    )
                                }
                            ]}
                        >
                            <MoreIcon vertical />
                        </Popover>
                    </Flex>
                </SamplesHeader>
                <Flex>
                    <SelectBox
                        width={125}
                        styles={styles}
                        options={samplesOptions}
                        defaultValue={samplesOptions.find(
                            ({ value }) => value === sampleType
                        )}
                        placeholder="Samples"
                        onChange={this.changeSampleType}
                    />
                    <SelectBox
                        pl={2}
                        width={125}
                        styles={styles}
                        options={sortingOptions}
                        defaultValue={sortingOptions.find(
                            ({ value }) => value === sort
                        )}
                        placeholder="Sort"
                        onChange={this.changeSort}
                    />
                </Flex>
                {loading ? (
                    <LoadingIndicator message="Loading synonym samples..." />
                ) : (
                    <SamplesContainer>
                        {totalSamples ? (
                            <Flex my={2} alignItems="center">
                                <Pagination
                                    totalRecords={totalSamples}
                                    pageLimit={pageLimit}
                                    neighbours={2}
                                    onPageChanged={this.onPageChanged}
                                />
                            </Flex>
                        ) : (
                            <span />
                        )}
                        <AddSynonymEntity sampleType={sampleType} />
                        {results.map(sample => (
                            <SynonymEntity
                                sampleType={sampleType}
                                key={sample.uuid}
                                id={sample.uuid}
                            />
                        ))}
                    </SamplesContainer>
                )}
            </Fragment>
        );
    }
}

Synonyms.propTypes = {
    samples: PropTypes.array,
    loading: PropTypes.bool,
    deleteAllSamples: PropTypes.func.isRequired,
    sampleExport: PropTypes.array,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired,
            modelType: PropTypes.string.isRequired,
            modelName: PropTypes.string.isRequired
        })
    }).isRequired
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Synonyms)
);
