import React from 'react';
import PropTypes from 'prop-types';
import { Field as ReduxField, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';

import { Box, Button, Error } from '../lavender';
import renderField from '../Login/renderField';
import { validateRegister } from '../../utils/validate';
import { register } from '../../redux/actions/auth';

const mapDispatchToProps = {
    register,
    redirect: push
};

class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        return this.props.register({ ...values });
    }

    render() {
        const { error, handleSubmit, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <Box mt={'1rem'}>
                    <Box mb={'2rem'}>
                        <ReduxField
                            name="username"
                            type="text"
                            component={renderField}
                            label="Username"
                        />
                    </Box>
                    <Box mb={'2rem'}>
                        <ReduxField
                            name="email"
                            type="email"
                            component={renderField}
                            label="Email"
                        />
                    </Box>
                    <Box mb={'2rem'}>
                        <ReduxField
                            name="password1"
                            type="password"
                            component={renderField}
                            label="Password"
                        />
                    </Box>
                    <Box mb={'2rem'}>
                        <ReduxField
                            name="password2"
                            type="password"
                            component={renderField}
                            label="Password"
                        />
                    </Box>
                    {error && <Error message={error} />}
                    <Box mt={30}>
                        <Button
                            label="Sign Up"
                            type="submit"
                            disabled={submitting}
                        />
                    </Box>
                </Box>
            </form>
        );
    }
}

RegisterForm.propTypes = {
    register: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool
};

const registerForm = withRouter(
    connect(
        null,
        mapDispatchToProps
    )(RegisterForm)
);

export default reduxForm({
    form: 'registerForm', // a unique identifier for this form
    validateRegister
})(registerForm);
