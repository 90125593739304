import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Text } from '../Text';
import { Link as _Link } from '../Link';
import theme from '../theme';

const Base = ({ to, is, children, ...props }) =>
    is === 'a' ? (
        <_Link {...props}>{children}</_Link>
    ) : (
        <Link to={to} is={is}>
            <Text {...props}>{children}</Text>
        </Link>
    );

Base.propTypes = {
    to: PropTypes.string,
    is: PropTypes.string,
    children: PropTypes.any
};

export const NavLink = props => (
    <Base
        {...props}
        css={{
            color: theme.colors.text[0],
            fontWeight: theme.fontWeights['medium'],
            cursor: 'pointer',
            '&:hover': {
                color: theme.colors.brand[1]
            }
        }}
    />
);

NavLink.displayName = 'NavLink';

export default NavLink;
