import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { languages, getAllLanguageModels } from '../../../redux/actions/models';
import * as selectors from '../../../redux/reducers';

import { Box, Text, Grid, Cell, SelectBox } from '../../lavender';
import { styles } from '../SamplesView/selectStyles';

const selectedPill = active =>
    active && {
        cursor: 'pointer',
        backgroundColor: '#000',
        border: '2px solid #11e275',
        color: 'white'
    };

const LanguagePill = ({ lang, ...props }) => (
    <Box
        width="150px"
        py={3}
        px={2}
        my={2}
        css={{
            borderRadius: '40px',
            border: '2px dotted #000',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#000',
                border: '2px solid #11e275',
                color: 'white'
            },
            ...selectedPill(lang.selected)
        }}
        {...props}
    />
);

LanguagePill.propTypes = {
    lang: PropTypes.object
};

const multilang = ({ schema: { train }, model: { model_type } }) => {
    return (
        train &&
        train[model_type] &&
        (train[model_type].hasOwnProperty('word_manifold_list') ||
            train[model_type].hasOwnProperty('language_model_list'))
    );
};

const mapStateToProps = state => ({
    schema: selectors.getSchema(state),
    schemaLoading: selectors.getIsLoadingSchema(state),
    modelLanguages: selectors.getActiveModelLanguages(state),
    languages: selectors.getLanguages(state),
    languageModels: selectors.getLanguageModels(state)
});

const mapDispatchToProps = dispatch => ({
    getAllLanguageModels: id => dispatch(getAllLanguageModels(id)),
    setLanguages: payload => dispatch(languages(payload))
});

class Languages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updated: false
        };
        this.changeLanguage = this.changeLanguage.bind(this);
        this.getDefaultValue = this.getDefaultValue.bind(this);
    }

    componentDidMount() {
        const { domainId } = this.props.match.params;

        this.props.getAllLanguageModels(domainId);
    }

    changeLanguage(value, id) {
        let { modelLanguages } = this.props;

        if (!modelLanguages.find(lang => lang.label === id)) {
            modelLanguages.push({ languageModel: value.label, label: id });
        }

        const newLang = modelLanguages.map(lang => {
            if (lang.label === id) {
                return {
                    ...lang,
                    languageModel: value.label === 'None' ? null : value.label
                };
            } else {
                return lang;
            }
        });

        this.props.setLanguages(
            newLang.filter(lang => lang.languageModel != null)
        );
        this.setState({ updated: true });
    }

    getDefaultValue(lang, languageOptions) {
        const { modelLanguages } = this.props;

        return languageOptions.find(({ label }) => {
            const ml = modelLanguages.find(item => item.label === lang.label);
            if (ml) return label === ml.languageModel;
        }, modelLanguages);
    }

    render() {
        const {
            schema,
            schemaLoading,
            languages,
            modelLanguages,
            languageModels
        } = this.props;
        const { updated } = this.state;
        let languageOptions =
            languageModels &&
            languageModels.map(lang => ({
                label: lang.name,
                value: lang.long_name
            }));
        languageOptions &&
            languageOptions.unshift({ label: 'None', value: null });
        const isMultilang = schema && !schemaLoading && multilang(this.props);

        return (
            <Fragment>
                <Box>
                    {isMultilang &&
                    languages &&
                    modelLanguages &&
                    languageOptions ? (
                        <Box p={1}>
                            <Text my={3} weight="bold" fontSize={4}>
                                Languages
                            </Text>
                            <Text color="text.1" fontSize={2} mb={20}>
                                {
                                    "Select the different languages you'd like to train your model with."
                                }
                            </Text>
                            <Box width={0.7}>
                                <Grid
                                    gap="15px"
                                    columns="repeat(auto-fit, minmax(150px, 1fr))"
                                >
                                    {languages.map((lang, i) => (
                                        <Cell key={i} py={5}>
                                            <Text my={2} weight="bold">
                                                {lang.name}
                                            </Text>
                                            <SelectBox
                                                id={lang.label}
                                                width={160}
                                                styles={styles}
                                                options={languageOptions}
                                                placeholder="None"
                                                defaultValue={this.getDefaultValue(
                                                    lang,
                                                    languageOptions
                                                )}
                                                onChange={value =>
                                                    this.changeLanguage(
                                                        value,
                                                        lang.label
                                                    )
                                                }
                                            />
                                        </Cell>
                                    ))}
                                </Grid>
                            </Box>
                            {updated && (
                                <Box
                                    bg="#f8df4f"
                                    my={5}
                                    p={3}
                                    css={{ borderRadius: '8px' }}
                                >
                                    <Text color="#043f38" weight="medium">
                                        Don&apos;t forget to train your model
                                        with these new language selections!
                                    </Text>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Text my={4} weight="medium" fontSize={3}>
                            This model works for all languages 🤗
                        </Text>
                    )}
                </Box>
            </Fragment>
        );
    }
}

Languages.propTypes = {
    model: PropTypes.shape({
        model_type: PropTypes.string,
        word_manifold_list: PropTypes.array
    }),
    schema: PropTypes.object,
    match: PropTypes.shape({
        params: PropTypes.shape({
            domainId: PropTypes.string.isRequired
        })
    }).isRequired,
    getAllLanguageModels: PropTypes.func,
    modelLanguages: PropTypes.array,
    setLanguages: PropTypes.func,
    schemaLoading: PropTypes.bool,
    languages: PropTypes.array,
    languageModels: PropTypes.array
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Languages)
);
