import React, { Fragment } from 'react';

import LoginForm from './LoginForm';
import { Box, Text, Title, NavLink } from '../lavender';

const Login = () => (
    <Fragment>
        <Box css={{ borderBottom: '1px solid #fafafa' }} p={2}>
            <Text textAlign="center" fontSize={3}>
                {"Don't have an account?"}
            </Text>
            <NavLink to="/register">
                <Text
                    textAlign="center"
                    fontSize={2}
                    weight="bold"
                    variant="primary"
                >
                    Sign up here.
                </Text>
            </NavLink>
        </Box>
        <Box p={4} mb={2}>
            <Title py={2} fontSize={6}>
                Login
            </Title>
            <LoginForm />
        </Box>
    </Fragment>
);

export default Login;
