import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Text, SelectBox, Card, Flex, Box } from '../../../lavender';
import * as selectors from '../../../../redux/reducers';
import { styles } from '../../SamplesView/selectStyles';
import {
    confusionMatrixOptions,
    responsiveHeatMapCanvasConfig
} from '../../../../config';
import { ResponsiveHeatMapCanvas } from '@nivo/heatmap';
import Tooltip from './Tooltip';
import { AxisLabel } from '../TSNE';

const mapStateToProps = (state, prevProps) => ({
    cmArray: prevProps.training
        ? selectors.getActiveModelModifiedTrainingCMMatrix(state)
        : selectors.getActiveModelModifiedTestingCMMatrix(state),
    labels: selectors.getActiveModelCMLabels(state),
    model: selectors.getActiveModel(state)
});

class ConfusionMatrix extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: 'sample_count'
        };
        this.toggleActiveKey = this.toggleActiveKey.bind(this);
        this.getData = this.getData.bind(this);
    }

    toggleActiveKey(value) {
        this.setState({
            activeKey: value.value
        });
    }

    getData() {
        const { cmArray, labels } = this.props;
        return cmArray.map((row, index) => {
            let result = {};
            result['label'] = labels[index];
            labels.map(col => {
                // result[col] = Math.sqrt(row[col][this.state.activeKey]);
                if (row[col][this.state.activeKey] > 0)
                    result[col] = Math.sqrt(row[col][this.state.activeKey]) + 1;
                else result[col] = 0;
            }, this);
            return result;
        }, this);
    }

    render() {
        const { activeKey } = this.state;
        const { labels, training } = this.props;
        const data = this.getData();
        return (
            <Fragment>
                {labels && labels.length && (
                    <Card my={3} p={3}>
                        <Flex mb={2}>
                            <Text py={2} fontSize={5} weight="bold">
                                {`${
                                    training ? 'Training ' : 'Testing'
                                } Confusion Matrix`}
                            </Text>
                            <SelectBox
                                ml="auto"
                                width={125}
                                styles={styles}
                                options={confusionMatrixOptions}
                                defaultValue={confusionMatrixOptions.find(
                                    ({ value }) => value === activeKey
                                )}
                                onChange={this.toggleActiveKey}
                            />
                        </Flex>
                        <Flex>
                            <AxisLabel width={80}>Actual Label</AxisLabel>
                            <Box ml={3} width="100%">
                                <div style={{ height: 900, width: 900 }}>
                                    <ResponsiveHeatMapCanvas
                                        data={data}
                                        keys={labels}
                                        {...responsiveHeatMapCanvasConfig}
                                        tooltip={node => (
                                            <Tooltip
                                                node={node}
                                                training={training}
                                            />
                                        )}
                                    />
                                </div>
                            </Box>
                        </Flex>
                        <AxisLabel>Predicted Label</AxisLabel>
                    </Card>
                )}
            </Fragment>
        );
    }
}

ConfusionMatrix.propTypes = {
    cmArray: PropTypes.array.isRequired,
    training: PropTypes.bool,
    labels: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.object.isRequired
    ])
};

export default connect(mapStateToProps)(ConfusionMatrix);
