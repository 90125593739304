export const caps = props =>
    props.caps && {
        textTransform: 'uppercase'
    };

export const weight = props =>
    props.weight && {
        fontWeight: props.theme.fontWeights[props.weight]
    };

export const italic = props => props.italic && { fontStyle: 'italic' };

export const underline = props =>
    props.underline && { textDecoration: 'underline' };

export const whichVariant = props => {
    const { variant } = props;
    switch (variant) {
        case 'primary':
            return 0;
        case 'secondary':
            return 1;
        case 'tertiary':
            return 2;
        case 'warning':
            return 3;
        case 'plain':
            return 4;
    }
    return -1;
};
