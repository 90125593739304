import React from 'react';
import { Image as Base } from 'rebass';

const Image = props => {
    return <Base {...props} />;
};

Image.displayName = 'Image';

export default Image;
