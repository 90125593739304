import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../../../../lavender';

const AddSampleButton = ({ onClick }) => (
    <Text
        mx={2}
        my={1}
        css={{
            width: 150,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#11e275',
                color: 'white',
                borderRadius: '8px'
            }
        }}
        onClick={onClick}
        p={2}
        color="support.1"
        weight="bold"
        fontWeight="medium"
        fontSize={3}
    >
        Add Sample
    </Text>
);

AddSampleButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default AddSampleButton;
