import React from 'react';
import flatLogo from '../../../images/horizontal_logo_black.png';
import { Flex, NavLink, Icon, Image, Cluster, Box } from '../../lavender';

const LoggedOutHeader = () => {
    return (
        <Flex
            justifyContent="space-between"
            flexDirection="row"
            p={3}
            pl={4}
            css={{
                boxShadow: `rgba(8, 35, 51, 0.05) 0px 3px 4px`
            }}
            bg="white"
        >
            <Box width={1 / 4}>
                <NavLink to="/">
                    <Image src={flatLogo} />
                </NavLink>
            </Box>
            <Cluster gutter="l" alignment="right">
                <NavLink fontSize={4} to="/features">
                    Features
                </NavLink>
                <NavLink fontSize={4} to="/pricing">
                    Pricing
                </NavLink>
                <NavLink fontSize={4} to="/docs">
                    Docs
                </NavLink>
                <NavLink fontSize={4} to="/login">
                    Log in
                </NavLink>
                <NavLink fontSize={4} to="/register">
                    Sign up <Icon size={2} name="arrow" right />
                </NavLink>
            </Cluster>
        </Flex>
    );
};

export default LoggedOutHeader;
